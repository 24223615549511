import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiKey = environment.googleApiKey;
  paginator = new BehaviorSubject(10);
  isSearch = new BehaviorSubject('');
  currentPage = new BehaviorSubject(0);
  apiUrl = environment.apiUrl;
  // currentSubject: BehaviorSubject<any>;
  private currentSubject = new Subject<any>();

  constructor(private http: HttpClient) {}

  getGeoLatLng(address): Observable<any> {
    const params = new HttpParams()
      .append('address', address)
      .append('key', this.apiKey);
    console.log(params);
    // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY
    // https://maps.googleapis.com/maps/api/js?key=<Your API KEY>&libraries=places&language=en
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params,
    });
    // return this.http.get('https://maps.googleapis.com/maps/api/js?key='+this.apiKey+'&libraries='+address);
  }

  getGeoAddress(lat, lng): Observable<any> {
    const coor = lat + ',' + lng;
    const params = new HttpParams()
      .append('latlng', coor)
      .append('key', this.apiKey);
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params,
    });
  }

  // logout user
  logout(): Observable<any> {
    return this.http.post(this.apiUrl + '/users/logout', {});
  }

  sendBreadcrumbDataUrl(data: any) {
    // console.log(data);
    this.currentSubject.next(data);
  }

  clearBreadcrumbData() {
    this.currentSubject.next(null);
  }

  getData(): Observable<any> {
    return this.currentSubject.asObservable();
  }
}
