import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiEndPoints, ApiService } from '../../../core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  apiUrl = environment.apiUrl;
  config = JSON.parse(JSON.stringify(require('../../../config.json')));

  limit: number = this.config.pageLimit;
  constructor(private http: HttpClient, private api: ApiService) {}

  // add country by admin
  addCountry(inputData): Observable<any> {
    return this.api.post(ApiEndPoints.countries, inputData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCountries(page, searchText): Observable<any> {
    const skip = (page - 1) * this.limit;
    const filter =
      '?filter[limit]=' +
      this.limit +
      '&filter[skip]=' +
      skip +
      '&searchTerm=' +
      searchText;
    return this.api.post(ApiEndPoints.adminCountry + filter, {});
  }

  getCountryAreas(countryId, page, searchText): Observable<any> {
    const skip = (page - 1) * this.limit;
    const filter =
      '?filter[limit]=' +
      this.limit +
      '&filter[skip]=' +
      skip +
      '&searchTerm=' +
      searchText;
    return this.api.post(
      ApiEndPoints.adminCountry + `${countryId}/countryAreas` + filter,
      {}
    );
  }

  // add country ares
  addCountryArea(countryId, data): Observable<any> {
    return this.api.post(
      ApiEndPoints.countries + `/${countryId}/countryAreas`,
      data
    );
  }

  getProvinces(countryId, areaId, page, searchText): Observable<any> {
    const skip = (page - 1) * this.limit;
    const filter =
      '?filter[limit]=' +
      this.limit +
      '&filter[skip]=' +
      skip +
      '&searchTerm=' +
      searchText;
    return this.api.post(
      `${ApiEndPoints.admin}countryAreas/${areaId}/provinces` + filter,
      {}
    );
  }

  // add province
  addProvince(id, data): Observable<any> {
    return this.api.post(ApiEndPoints.country_area + `/${id}/provinces`, data);
  }

  getAreaDistricts(data): Observable<any> {
    const skip = (data.page - 1) * this.limit;
    const filter =
      '?filter[limit]=' +
      this.limit +
      '&filter[skip]=' +
      skip +
      '&searchTerm=' +
      data.searchText;
    return this.api.post(
      `${ApiEndPoints.admin}provinces/${data.provinceId}/districts` + filter,
      {}
    );
  }

  // add province
  addAreaDistrict(id, data): Observable<any> {
    return this.api.post(ApiEndPoints.provinces + `/${id}/districts`, data);
  }

  /*getCities(provinceId): Observable<any> {
    return this.http.get(this.apiUrl + `/provinces/${provinceId}/cities`)
  }*/

  // get country details for edit
  getCountryDetail(id): Observable<any> {
    return this.api.get(ApiEndPoints.countries + '/' + id);
  }

  // edit country details
  editCountry(id, data): Observable<any> {
    data.id = id;
    return this.api.patch(ApiEndPoints.countries + '/' + id, data);
  }

  // get country details for edit
  getCountryAreaDetail(countryId, areaId): Observable<any> {
    return this.api.get(
      ApiEndPoints.countries + '/' + countryId + '/countryAreas/' + areaId
    );
  }

  // edit country area details
  editCountryArea(countryId, areaId, data): Observable<any> {
    data.id = areaId;
    return this.api.put(
      ApiEndPoints.countries + '/' + countryId + '/countryAreas/' + areaId,
      data
    );
  }

  // get province details for edit
  getProvinceDetail(data): Observable<any> {
    return this.api.get(
      ApiEndPoints.country_area +
        '/' +
        data.areaId +
        '/provinces/' +
        data.provinceId
    );
  }

  // edit province  details
  editProvince(data): Observable<any> {
    data.id = data.provinceId;
    return this.api.put(
      ApiEndPoints.country_area +
        '/' +
        data.areaId +
        '/provinces/' +
        data.provinceId,
      data.body
    );
  }

  // get province details for edit
  getDistrictDetail(data): Observable<any> {
    return this.api.get(
      ApiEndPoints.provinces +
        '/' +
        data.provinceId +
        '/districts/' +
        data.districtId
    );
  }

  // edit province  details
  editDistrict(data): Observable<any> {
    data.id = data.districtId;
    return this.api.put(
      ApiEndPoints.provinces +
        '/' +
        data.provinceId +
        '/districts/' +
        data.districtId,
      data.body
    );
  }

  // delete countries
  deleteCountry(countryId): Observable<any> {
    return this.api.delete(ApiEndPoints.adminCountry + countryId + '/delete');
  }

  // delete country areas
  deleteCountryArea(data): Observable<any> {
    return this.api.delete(ApiEndPoints.adminArea + data.areaId + '/delete');
  }

  // delete province
  deleteProvince(data): Observable<any> {
    return this.api.delete(
      ApiEndPoints.adminProvince + data.provinceId + '/delete'
    );
  }

  // delete area district
  deleteAreaDistrict(data): Observable<any> {
    return this.api.delete(
      ApiEndPoints.adminDistrict + data.districtId + '/delete'
    );
  }
}
