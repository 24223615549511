import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { environment } from 'projects/admin/src/environments/environment';

import { LocationService } from '../../../../services/location.service';
import { AddCountryAreaComponent } from '../../modals/add-country-area/add-country-area.component';
@Component({
  selector: 'app-country-area',
  templateUrl: './country-area.component.html',
  styleUrls: ['./country-area.component.css'],
})
export class CountryAreaComponent implements OnInit {
  constructor(
    private locationService: LocationService,
    private dialog: DialogService,
    private toast: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private sorting: SortingService
  ) {}
  imageUrl = environment.imageUrl;
  areas;
  countryId;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  items: any;
  values;
  searchText = '';
  sortType = '';
  toSort = false;
  countryName;
  breadCrumbCurrentUrl;
  nameValue;
  breadCrumbArray = [];
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  ngOnInit() {
    this.breadCrumbCurrentUrl = this.router.url;
    this.countryId = parseInt(
      this.route.snapshot.paramMap.get('countryId'),
      10
    );
    this.getCountryName(this.countryId);
    this.getCountryAreas(this.countryId, this.currentPage, this.searchText);
    this.setName();
  }

  openModal(status, areaId) {
    this.dialog.dataModal(AddCountryAreaComponent, {
      isEdit: status,
      id: this.countryId,
      areaId,
    });
    this.dialog.modalReference.componentInstance.countryarea.subscribe(
      (data) => {
        if (data !== null) {
          if (data.error === false) {
            if (data.isEdit === true) {
              this.toast.show(messages.areaUpdated, {
                classname: 'bg-success text-light',
              });
            } else {
              this.toast.show(messages.areaAdded, {
                classname: 'bg-success text-light',
              });
            }
          } else {
            this.toast.show(data.message, {
              classname: 'bg-danger text-light',
            });
          }
          this.currentPage = 0;
          this.getCountryAreas(
            this.countryId,
            this.currentPage,
            this.searchText
          );
        }
      }
    );
  }

  // delete entry from db
  deleteRecord(id) {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to delete this Country Area?',
      id,
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data && data.status) {
        if (data.status === true) {
          this.locationService
            .deleteCountryArea({ countryId: this.countryId, areaId: id })
            .subscribe(
              () => {
                this.getCountryAreas(
                  this.countryId,
                  this.currentPage,
                  this.searchText
                );
                this.toast.show(messages.areaDeleted, {
                  classname: 'bg-success text-light',
                  delay: 5000,
                });
              },
              (error) => {
                this.toast.show(error.error.message, {
                  classname: 'bg-danger text-light',
                  delay: 5000,
                });
              }
            );
        } else {
        }
      }
    });
  }

  /*onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }*/

  searchFilter(params) {
    this.searchText = params;
    this.getCountryAreas(this.countryId, this.currentPage, this.searchText);
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getCountryAreas(this.countryId, this.currentPage, this.searchText);
  }

  /**
   *
   * @param countryId countryId
   */
  getCountryName(countryId) {
    this.locationService.getCountryDetail(countryId).subscribe(
      (data) => {
        this.countryName = data.name;
      },
      (error) => {
        this.toast.show(error.error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  getCountryAreas(countryId, pageNumber, searchText) {
    this.locationService
      .getCountryAreas(countryId, pageNumber, searchText)
      .subscribe(
        (data) => {
          this.setDataResponse(data);
        },
        (error) => {
          this.toast.show(error.error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.items = data.result;
      this.sortType = '';
      this.sorting.setData(this.items);
      this.disableNext = false;
    }
  }

  backClicked() {
    this.router.navigateByUrl(`/admin/locations`);
  }

  setName() {
    const nameArray = JSON.parse(localStorage.getItem('breadcrumb'));
    this.nameValue = nameArray[0].name;
  }

  navigation(id, name) {
    const v = JSON.parse(localStorage.getItem('breadcrumb'));
    const url = this.breadCrumbCurrentUrl + '/' + id + '/provinces';
    v.push({ url, name });
    localStorage.setItem('breadcrumb', JSON.stringify(v));
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.items = this.sorting.normalSort(this.items, field, this.storySort);
  }
}
