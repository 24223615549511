import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndPoints, ApiService } from 'projects/admin/src/app/core/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferralCouponService {
  config = JSON.parse(JSON.stringify(require('../../../config.json')));
  limit: number = this.config.pageLimit;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  createReferralCouponCode(): Observable<any> {
    // const skip = page * this.limit;
    // const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService.post(`${ApiEndPoints.referralCouponsCreate}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getReferralCouponListing(
    page: any,
    searchTerm: any,
    param: any
  ): Observable<any> {
    const skip = (page - 1) * this.limit;
    const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(`${ApiEndPoints.referralCoupons}?skip=${skip}${searchText}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteReferralCoupons(couponId): Observable<any> {
    return this.apiService
      .delete(`${ApiEndPoints.referralCoupons}${couponId}/delete`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
