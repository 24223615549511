import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { RoleService } from 'projects/admin/src/app/core/services/role.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminWithClubManagerGuard implements CanActivate {
  constructor(private roleService: RoleService) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // returns the boolean based on permission (Superadmin && qclubmanager)
    const hasQClubPermission = this.roleService.hasQClubManagerRole();
    const hasSuperAdminPermission = this.roleService.hasSuperAdminRole();
    return hasQClubPermission || hasSuperAdminPermission;
  }
}
