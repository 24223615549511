import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-referral-filters',
  templateUrl: './referral-filters.component.html',
  styleUrls: ['./referral-filters.component.css'],
})
export class ReferralFiltersComponent implements OnInit {
  filterForm: FormGroup;
  @Output() filtered = new EventEmitter();
  @Output() clear = new EventEmitter();
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.filterForm = this.fb.group({
      status: [''],
    });
  }

  searchReferralFilter() {
    if (this.filterForm.valid) {
      this.filtered.emit(this.filterForm.value);
    }
  }

  resetFilter() {
    this.filterForm.reset();
    this.filterForm.get('status').setValue('');
    this.filtered.emit(this.filterForm.value);
  }
}
