// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  imageUrl: './assets/img',
  apiUrl: 'http://localhost:3000/api/' /** dev */,
  noLoaderApis: [
    "http://localhost:3000/api/users/check-email",
    "http://3.23.96.181:3000/api/publics/meta-data",
  ],
  termsAndConditionsUrl: "http://myqclub.ca/terms.html",
  privacyPoliciesUrl: "http://myqclub.ca/privacy-policy.html",
  paypalClientId:
    "AWccq05qaH6lw0Vp4Jye-Ptf-YnRE61nnOtzGN9LAnk1dC8dlzeDxRNG_RV8Ooyb1DSWnusbMFMi8glx",
  currencyType: "CAD",
  awsBucketUrl: "https://myqclubdev.s3.amazonaws.com",
  SANDBOX_TOKEN_KEY: "sandbox_jyssctfz_88zh8mwxnqdfcdcm",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
