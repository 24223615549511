import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { ManagerDetailsComponent } from './components/manager-details/manager-details.component';
import { ManagerComponent } from './components/manager/manager.component';
import { ManagerRoutingModule } from './manager.routing.module';

@NgModule({
  declarations: [ManagerComponent, ManagerDetailsComponent],
  imports: [
    CommonModule,
    ManagerRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAel1czo9RcjpRvbpXITOyP7apAwmc9uxo',
      libraries: ['places'],
    }),
  ],
  exports: [],
  bootstrap: [ManagerComponent],
})
export class ManagerModule {}
