import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/business/src/environments/environment';
@Component({
  selector: 'app-qpons',
  templateUrl: './qpons.component.html',
  styleUrls: ['./qpons.component.css'],
})
export class QponsComponent implements OnInit {
  imageUrl = environment.imageUrl;
  constructor() {}

  ngOnInit() {}
}
