import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';

@Component({
  selector: 'app-qpons-list',
  templateUrl: './qpons-list.component.html',
  styleUrls: ['./qpons-list.component.css'],
})
export class QponsListComponent implements OnInit {
  imageUrl = environment.imageUrl;
  currentTab = 'history';
  qPonsListing = [];
  currentPage = 0;
  totalSize: number;
  searchText = '';
  searchQponsForm: FormGroup;
  sortType = '';
  filterCollapsed = true;
  isPremium = false;
  constructor(private fb: FormBuilder, private router: ActivatedRoute) {}

  ngOnInit() {
    // to check whether business route is for premium or local businesses
    this.isPremium = this.router.snapshot.url[0].path === OTHER_CONST.PREMIUM;
    this.searchQponsForm = this.fb.group({
      searchTerm: [''],
    });
  }
}
