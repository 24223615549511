import { Injectable, PipeTransform } from '@angular/core';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';

export const compare = (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  constructor() {}
  sortingOrder = OTHER_CONST.sortOrder;
  defaultData;
  data;
  setData(items) {
    this.defaultData = items;
    // console.log(this.defaultData);
  }

  sortData(items, column, direction) {
    if (direction === '') {
      this.data = this.defaultData;
    } else if (direction === 'asc') {
      this.data = [...items].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    } else {
      this.data = [...items].sort((b, a) => {
        const res = compare(a[column], b[column]);
        return direction === 'dsc' ? res : -res;
      });
    }
    return this.data;
  }

  /**
   * sort data for business
   */
  sortDataBusiness(items, column, direction) {
    if (direction === '') {
      this.data = this.defaultData;
    } else if (direction === 'asc') {
      this.data = [...items].sort((a, b) => {
        const res = compare(
          a.businessProfile[column],
          b.businessProfile[column]
        );
        return direction === 'asc' ? res : -res;
      });
    } else {
      this.data = [...items].sort((b, a) => {
        const res = compare(
          a.businessProfile[column],
          b.businessProfile[column]
        );
        return direction === 'dsc' ? res : -res;
      });
    }
    return this.data;
  }

  /**
   * @description method to sort business alphabatically
   * @param items array of objects
   * @param column column name
   * @param direction asc or desc
   */
  normalBusinessSort(items = [], column: string, direction = '') {
    this.data = [...items].slice().sort((a, b) => {
      const res = compare(a.businessProfile[column], b.businessProfile[column]);
      return direction === this.sortingOrder.desc ? res : -res;
    });

    return this.data;
  }

  /**
   * @description method to sort alphabatically
   * @param items array of objects
   * @param column column name
   * @param direction asc or desc
   */
  normalSort(items = [], column: string, direction = '') {
    this.data = [...items].slice().sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === this.sortingOrder.desc ? res : -res;
    });

    return this.data;
  }

  nestedSort(items = [], column: any, direction = '') {
    column = column.split('.');
    const len = column.length;
    this.data = [...items].slice().sort((a, b) => {
      let i = 0;
      while (i < len) {
        a = a[column[i]];
        b = b[column[i]];
        i++;
      }
      const res = compare(a, b);
      return direction === this.sortingOrder.desc ? res : -res;
    });
    return this.data;
  }

  /**
   * @description method to sort by date
   * @param items array of objects
   * @param column column name
   * @param direction asc or desc
   */
  sortByDate(items = [], column: string, direction = '') {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.

    const dateCompare = (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
    const data = [...items].slice().sort((a, b): any => {
      const res = dateCompare(
        new Date(b[column]).getTime(),
        new Date(a[column]).getTime()
      );
      return direction === this.sortingOrder.desc ? res : -res;
    });

    return data;
  }
}
