import { Component, OnInit } from '@angular/core';
import { TokenService, User, UserService } from 'projects/admin/src/app/core/index';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  imageUrl = environment.imageUrl;
  username;
  userRole;
  userData: User;
  constructor(
    private userService: UserService,
    private tokenService: TokenService
  ) {
    // subscribe for the current user data
    this.userService.currentUser.subscribe((data) => {
      this.userData = data;
    });
  }

  ngOnInit() {
    if (this.tokenService.getToken()) {
      this.username = this.userData.name;
      this.userRole = this.userData.userRoles[0].role.description;
    }
  }

  /**
   * Logout the current user and distroy the session
   */

  logout() {
    this.userService.logout();
  }
}
