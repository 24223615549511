import { Component } from '@angular/core';

import { User } from './core/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  styles: [
    `
      /deep/ .modal-dialog {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - 60px);
      }
      /deep/ @media (max-width: 767px) {
        .modal-dialog {
          min-height: calc(100vh - 20px);
        }
      }
    `,
  ],
})
export class AppComponent {
  constructor() {}
  currentUser: User;
  title = 'Q-club Admin';
  environmentName = '';
  environmentUrl = 'Qclub api';
}
