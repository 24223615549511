import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ApiEndPoints } from 'projects/admin/src/app/core/constants/api-endpoints.constants';
import { ApiService } from 'projects/admin/src/app/core/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QclubsResolver implements Resolve<any> {
  constructor(private apiService: ApiService) {}
  resolve() {
    return this.apiService.get(`${ApiEndPoints.qClubDetails}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
