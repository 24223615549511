import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import message from '@core-module/constants/successMessages.json';
@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.css'],
})
export class NoPermissionComponent implements OnInit {
  imageUrl = environment.imageUrl;
  noPermission;
  constructor() {}
  ngOnInit() {
    this.noPermission = message.noPermission;
  }
}
