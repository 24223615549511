import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { QuestionModelComponent } from './components/modals/question-model/question-model.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { QuestionComponent } from './components/question/question.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SharedRoutingModule } from './shared-routing.module';

@NgModule({
  declarations: [
    QuestionModelComponent,
    PaginationComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    QuestionComponent,
    ConfirmModalComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    QuestionModelComponent,
    QuestionComponent,
    PaginationComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
  ],
})
export class SharedModule {}
