import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-coupon',
  templateUrl: './referral-coupon.component.html',
  styleUrls: ['./referral-coupon.component.css'],
})
export class ReferralCouponComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
