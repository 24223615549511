import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndPoints, ApiService } from 'projects/admin/src/app/core/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  config = JSON.parse(JSON.stringify(require('../../../config.json')));
  limit: number = this.config.pageLimit;

  constructor(private api: ApiService, private http: HttpClient) {}

  // add new admin user
  addAdminUser(data): Observable<any> {
    return this.api.post(ApiEndPoints.addAdminUser, data);
  }

  // get admin users list as per filter
  getAdminUsers(filters, serachParams, page): Observable<any> {
    const skip = (page - 1) * this.limit;
    const filter =
      '?limit=' + this.limit + '&skip=' + skip + '&searchTerm=' + serachParams;
    return this.api.post(ApiEndPoints.adminUserListing + filter, filters);
  }

  // get admin user details
  getAdminUserDetails(userId): Observable<any> {
    return this.api.get(ApiEndPoints.adminUserDetails + '/' + userId);
  }

  // update admin user details
  editAdminUser(userId, data): Observable<any> {
    return this.api.patch(ApiEndPoints.adminUserDetails + '/' + userId, data);
  }

  // change admin user status
  changeUserStatus(id, data): Observable<any> {
    return this.api.patch(ApiEndPoints.users + '/' + id, {
      status: data.status,
    });
  }

  // delete admin user from the table
  deleteAdminUser(id, status): Observable<any> {
    return this.api.patch(ApiEndPoints.users + '/' + id, { isDeleted: status });
  }

  // get admin roles
  getAdminRoles(): Observable<any> {
    return this.api.get(ApiEndPoints.getRoles);
  }

  // resend email to pending users
  resendMail(userId): Observable<any> {
    return this.api.post(`${ApiEndPoints.sendEmailVerification}${userId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
