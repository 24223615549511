import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { BusinessService } from '../../services/business.service';
@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.css'],
})
export class BusinessesDetailsComponent implements OnInit {
  constructor(
    private locationVar: Location,
    private toast: ToastService,
    private businessService: BusinessService,
    private route: ActivatedRoute
  ) {}
  items = [];
  pageOfItems: Array<any>;
  businessId: number;
  businessDetails: any;
  ngOnInit() {
    this.businessId = parseInt(
      this.route.snapshot.paramMap.get('businessId'),
      10
    );
    this.getBusinessDetails(this.businessId);
  }

  /**
   * Get the business Details
   * @param businessId businessId
   */
  getBusinessDetails(businessId) {
    this.businessService.getBusinessDetails(businessId).subscribe(
      (data) => {
        if (data && data.result) {
          this.businessDetails = data.result;
        }
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * @description back to business listing
   */
  backClicked() {
    this.locationVar.back();
  }
}
