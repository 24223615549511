import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { LocationService } from '../../../../services/location.service';

@Component({
  selector: 'app-add-areadistrict',
  templateUrl: './add-areadistrict.component.html',
  styleUrls: ['./add-areadistrict.component.css'],
})
export class AddAreadistrictComponent implements OnInit {
  addDistrictAreaForm: FormGroup;
  countryId;
  countryAreaId;
  provinceId;
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private location: LocationService,
    private toast: ToastService
  ) {}
  @Output() district: EventEmitter<any> = new EventEmitter();
  messages = JSON.parse(
    JSON.stringify(
      require('../../../../../../core/constants/successMessages.json')
    )
  );

  @Input() data;
  submitValue = 'Add';
  ngOnInit() {
    this.countryId = this.data.countryId;
    this.countryAreaId = this.data.areaId;
    this.provinceId = this.data.provinceId;

    this.addDistrictAreaForm = this.fb.group({
      name: ['', Validators.required],
      countryId: [this.countryId],
      countryAreaId: [this.countryAreaId],
      provinceId: [this.provinceId],
    });
    if (this.data.isEdit) {
      this.submitValue = 'Edit';
      this.getDistrictDetails(this.data.districtId);
    }
  }

  // get details to edit district
  getDistrictDetails(id) {
    this.location
      .getDistrictDetail({
        provinceId: this.data.provinceId,
        districtId: this.data.districtId,
      })
      .subscribe((data) => {
        this.addDistrictAreaForm.get('name').setValue(data.name);
      });
  }

  addDistrictArea() {
    for (const controller in this.addDistrictAreaForm.controls) {
      if (this.addDistrictAreaForm.controls.hasOwnProperty(controller)) {
        this.addDistrictAreaForm.get(controller).markAsTouched();
      }
    }

    if (this.addDistrictAreaForm.valid) {
      if (this.data.isEdit) {
        const obj = {
          districtId: this.data.districtId,
          provinceId: this.data.provinceId,
          body: this.addDistrictAreaForm.value,
        };
        this.location.editDistrict(obj).subscribe(
          (data) => {
            this.dialog.close();
            this.toast.show(this.messages.districtUpdated, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            this.district.emit({
              name: this.addDistrictAreaForm.get('name').value,
              isEdit: true,
            });
          },
          (error) => {
            this.toast.show(error.error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
      } else {
        this.location
          .addAreaDistrict(this.provinceId, this.addDistrictAreaForm.value)
          .subscribe(
            (data) => {
              this.dialog.close();
              this.toast.show(this.messages.districtAdded, {
                classname: 'bg-success text-light',
                delay: 5000,
              });
              this.district.emit({
                name: this.addDistrictAreaForm.get('name').value,
                isEdit: false,
              });
            },
            (error) => {
              this.toast.show(error.error.error.message, {
                classname: 'bg-danger text-light',
                delay: 5000,
              });
            }
          );
      }
    }
  }

  closeModal() {
    this.dialog.close();
  }

  get f() {
    return this.addDistrictAreaForm.controls;
  }
}
