import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-add-adminuser',
  templateUrl: './add-adminuser.component.html',
  styleUrls: ['./add-adminuser.component.css'],
})
export class AddAdminuserComponent implements OnInit {
  addUserForm: FormGroup;
  roles: [];
  constructor(
    private fb: FormBuilder,
    private user: UserService,
    private toast: ToastService,
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.addUserForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.compose([
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9][-a-zA-Z0-9._]+@([- a-z0-9]+[.])+[a-z]{2,5}$'
            ),
          ]),
        ],
      ],
      phoneNo: [
        '',
        [Validators.required, Validators.pattern(/^[+() \d]{10}$/)],
      ],
      roleId: ['', [Validators.required]],
      name: [''],
    });
    this.getRoles();
  }

  // get admin roles
  getRoles() {
    this.user.getAdminRoles().subscribe((data) => {
      this.roles = data.result;
    });
  }

  addUser() {
    for (const controller in this.addUserForm.controls) {
      if (this.addUserForm.controls.hasOwnProperty(controller)) {
        this.addUserForm.get(controller).markAsTouched();
      }
    }

    if (this.addUserForm.valid) {
      this.addUserForm
        .get('name')
        .setValue(this.addUserForm.get('firstName').value);

      this.addUserForm
        .get('roleId')
        .setValue(parseInt(this.addUserForm.get('roleId').value, 10));
      this.user.addAdminUser(this.addUserForm.value).subscribe(
        (data) => {
          this.addUserForm.reset();
          this.addUserForm.get('roleId').setValue('');
          this.toast.show(messages.addAdminUser, {
            classname: 'bg-success text-light',
            delay: 5000,
          });
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
    }
  }

  // throw error
  get f() {
    return this.addUserForm.controls;
  }

  get invalidRole() {
    return (
      this.addUserForm.get('roleId').touched &&
      this.addUserForm.get('roleId').errors &&
      this.addUserForm.get('roleId').errors.required
    );
  }
  //

  onCancel(){
    this.router.navigate(['../'], {
      relativeTo: this.activeRoute,
    });
  }
}
