import { Injectable } from '@angular/core';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';

export const compare = (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  constructor() {}
  sortingOrder = OTHER_CONST.sortOrder;
  defaultData;
  data;
  setData(items) {
    this.defaultData = items;
  }

  sortData(items: any[], column: string, direction: string) {
    if (direction === '') {
      this.data = this.defaultData;
    } else if (direction === 'asc') {
      this.data = [...items].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    } else {
      this.data = [...items].sort((b, a) => {
        const res = compare(a[column], b[column]);
        return direction === 'dsc' ? res : -res;
      });
    }
    return this.data;
  }

  /**
   * sort data for business
   */
  normalBusinessSort(items: any[], column: string, direction = '') {
    if (direction === this.sortingOrder.asc) {
      this.data = [...items].slice().sort((a, b) => {
        const res = compare(
          a.businessProfile[column],
          b.businessProfile[column]
        );
        return direction === this.sortingOrder.asc ? res : -res;
      });
    } else if (direction === this.sortingOrder.desc) {
      this.data = [...items].slice().sort((b, a) => {
        const res = compare(
          a.businessProfile[column],
          b.businessProfile[column]
        );
        return direction === this.sortingOrder.desc ? res : -res;
      });
    } else {
      this.data = items;
    }
    return this.data;
  }

  /**
   * @description method to sort alphabatically
   * @param items array of objects
   * @param column column name
   * @param direction asc or desc
   */
  normalSort(items: any[], column: string, direction = '') {
    if (direction === this.sortingOrder.asc) {
      this.data = [...items].slice().sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === this.sortingOrder.asc ? res : -res;
      });
    } else if (direction === this.sortingOrder.desc) {
      this.data = [...items].slice().sort((b, a) => {
        const res = compare(a[column], b[column]);
        return direction === this.sortingOrder.desc ? res : -res;
      });
    } else {
      this.data = items;
    }
    return this.data;
  }

  /**
   * @description method to sort by date
   * @param items array of objects
   * @param column column name
   * @param direction asc or desc
   */
  sortByDate(items: any[], column: string, direction = '') {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return [...items]
      .slice()
      .sort(
        (a, b): any =>
          new Date(b[column]).getTime() - new Date(a[column]).getTime()
      );
  }
}
