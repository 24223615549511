import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import { QuestionModelComponent } from 'projects/admin/src/app/shared/components/modals/question-model/question-model.component';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { AdminRoutingModule } from './admin-routing.module';
import { BusinessAcquisitionModule } from './business-acquisition/business-acquisition.module';
import { BusinessModule } from './business/business.module';
import { AssignApprovedQclubComponent } from './business/components/new-requests/assign-approved-qclub/assign-approved-qclub.component';
import { PremiumSponsorComponent } from './business/components/new-requests/premium-sponsor/premium-sponsor.component';
import { ComposeComponent } from './components/notification/compose/compose.component';
import { InboxComponent } from './components/notification/inbox/inbox.component';
import { SentComponent } from './components/notification/sent/sent.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { IndexComponent } from './index/index.component';
import { AddAreadistrictComponent } from './location/components/clublocation-comp/modals/add-areadistrict/add-areadistrict.component';
import { AddCountryAreaComponent } from './location/components/clublocation-comp/modals/add-country-area/add-country-area.component';
import { AddCountryComponent } from './location/components/clublocation-comp/modals/add-country/add-country.component';
import { AddProvinceComponent } from './location/components/clublocation-comp/modals/add-province/add-province.component';
import { ChangeQclubManagerComponent } from './location/components/clublocation-comp/qclub-detail/change-qclub-manager/change-qclub-manager.component';
import { LocationModule } from './location/location.module';
import { ManagerModule } from './manager/manager.module';
import { MemberModule } from './member/member.module';
import { NoPermissionModule } from './no-permission/no-permission.module';
import { ViewQPonDetailsComponent } from './q-pons/components/shared/view-qpon-details/view-qpon-details.component';
import { QPonsModule } from './q-pons/q-pons.module';
import { ReferralCouponsModule } from './referral-coupons/referral-coupons.module';
// import { ConfirmModalComponent } from './shared/components/modals/confirm-modal/confirm-modal.component';
import { EditUserComponent } from './users/components/manage/modals/edit-user/edit-user.component';
import { UsersModule } from './users/users.module';

@NgModule({
  declarations: [
    IndexComponent,
    InboxComponent,
    ComposeComponent,
    SentComponent,
    UserInfoComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    LocationModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    BusinessModule,
    MemberModule,
    UsersModule,
    ManagerModule,
    QPonsModule,
    BusinessAcquisitionModule,
    ReferralCouponsModule,
    NoPermissionModule,
  ],
  entryComponents: [
    QuestionModelComponent,
    AssignApprovedQclubComponent,
    AddCountryComponent,
    AddProvinceComponent,
    AddAreadistrictComponent,
    AddCountryAreaComponent,
    EditUserComponent,
    ChangeQclubManagerComponent,
    ViewQPonDetailsComponent,
    ConfirmModalComponent,
    PremiumSponsorComponent,
  ],
  bootstrap: [IndexComponent],
})
export class AdminModule {}
