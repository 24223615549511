import { Injectable } from '@angular/core';
import { ApiEndPoints, ApiService } from 'projects/admin/src/app/core/index';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(private apiService: ApiService) {}
  /**
   * get Pending Business listing
   * @param page page
   * @param param filter object filtered by user
   */
  getMembersListing(params: any): Observable<any> {
    const skip = (params.page - 1) * OTHER_CONST.perPageRecords;
    let path = `admins/members?searchTerm=${params.searchTerm}&skip=${skip}&order=${params.order}`;
    if (params.qClubId) {
      path = path + `&qClubId=${params.qClubId}`;
    }
    return this.apiService
      .post(path, {
        membershipStatus: params.membershipStatus,
        type: params.type,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Active or Deactive Members
   * @param id id
   * @param param param
   */

  activeDeactiveMembers(id: any, param: any): Observable<any> {
    return this.apiService.patch(`users/${id}`, param).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Get Member Details
   * @param memberId member id
   */
  getMemberDetails(memberId: any): Observable<any> {
    return this.apiService.get(`admins/members/${memberId}/details`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * get member answerd questions
   * @param payload data
   */
  getMemberQuestions(payload: any): Observable<any> {
    return this.apiService
      .get(`admins/members/${payload.id}/answers?type=answered`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
