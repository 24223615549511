import { Pipe, PipeTransform } from '@angular/core';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';

@Pipe({
  name: 'timezoneAbbr',
})
export class TimezoneAbbrPipe implements PipeTransform {
  timeZonesVar = OTHER_CONST.timeZones;
  transform(value: any): any {
    if (value) {
      const findTz = this.timeZonesVar.find((item) => item.tz === value);
      return findTz ? findTz.abbr : '';
    }
    return null;
  }
}
