import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TokenService } from '../../../../core';
@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css'],
})
export class ManagerComponent implements OnInit {
  managerId;
  constructor(private router: Router, private tokenService: TokenService) {}

  ngOnInit() {
    this.managerId = this.tokenService.getUserId();
    // this.managerName = this.tokenService.getUserName();
    // this.getManagerQclub(this.managerId);
    // this.router.navigateByUrl('admin/manager/manager-details');
  }
}
