import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'projects/admin/src/app/core/guards/admin.guard';
import { QuestionComponent } from 'projects/admin/src/app/shared/components/question/question.component';
import { QclubsResolver } from 'projects/admin/src/app/shared/resolvers/qclubs.resolver';

import { BusinessesDetailsComponent } from './components/business-details/business-details.component';
import { BusinessComponent } from './components/business/business.component';
import { ExistingBusinessesComponent } from './components/existing-businesses/existing-businesses.component';
import { NewRequestsComponent } from './components/new-requests/new-requests.component';

const routes: Routes = [
  {
    path: '',
    component: BusinessComponent,

    children: [
      { path: '', redirectTo: 'new-requests', pathMatch: 'full' },
      {
        path: 'new-requests',
        component: NewRequestsComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'existing-businesses/local',
        component: ExistingBusinessesComponent,
        canActivate: [AdminGuard],
        resolve: { qclubsData: QclubsResolver },
      },
      {
        path: 'business-questions',
        component: QuestionComponent,
        data: { data: 'BUSINESS' },
        canActivate: [AdminGuard],
      },
      {
        path: 'business-details/:businessId/details',
        component: BusinessesDetailsComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'existing-businesses/premium',
        component: ExistingBusinessesComponent,
        canActivate: [AdminGuard],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessRoutingModule {}
