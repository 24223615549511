import { environment } from "projects/admin/src/environments/environment";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import messages from "@core-module/constants/successMessages.json";
import { CommonService } from "projects/admin/src/app/core/index";
import OTHER_CONST from "projects/admin/src/app/shared/constants/other.const";
import { ApiService } from "projects/admin/src/app/shared/services/api.service";
import { DialogService } from "projects/admin/src/app/shared/services/dialog.service";
import { SortingService } from "projects/admin/src/app/shared/services/sorting.service";
import { ToastService } from "projects/admin/src/app/shared/services/toast.service";

import { QPonService } from "../../../../services/qpon.service";
import { ViewQPonDetailsComponent } from "../../view-qpon-details/view-qpon-details.component";
import * as moment from "moment";

@Component({
  selector: "app-pending-qpon",
  templateUrl: "./pending-qpon.component.html",
  styleUrls: ["./pending-qpon.component.css"],
})
export class PendingQPonComponent implements OnInit {
  imageUrl = environment.imageUrl;
  @Input() businessId = null;
  @Input() viewSearch;
  @Input() businessName;
  @Input() businessUniqueKey;
  @Input() qClubId;
  @Input() premium;
  currentPage = 1;
  isFiltered = false;
  disableNext = false;
  totalRecordsCount = 0;
  pendingQPonArray: any;
  sortType = "";
  filters;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  params = { status: "PENDING", qPonType: "", qClubId: "", qbid: "" };
  constructor(
    private toast: ToastService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private sorting: SortingService,
    private commonService: CommonService,
    public qPonService: QPonService,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    // get the qpons list based on the businessId
    if (this.businessId) {
      this.getPendingBusinessQPon(this.currentPage, this.businessId);
    } else {
      this.getPendingBusinessQPonForAdmin(this.currentPage);
    }
  }

  /**
   * get Pending qpons for business based on business id and status
   * @param page this is using for pagination
   * @param businessId this is current business id
   */
  getPendingBusinessQPon(page, businessId) {
    this.qPonService.getBusinessQPon(page, businessId, this.params).subscribe(
      (data) => {
        this.setDataResponse(data);
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: "bg-danger text-light",
          delay: 5000,
        });
      }
    );
  }

  /**
   * get Pending qpons for admin and other user based on the permission
   * @param page current page for pagination
   */
  getPendingBusinessQPonForAdmin(page) {
    const onSuccess = (data) => {
      this.setDataResponse(data);
    };
    const onError = (error) => {
      this.toast.show(error.error.message, {
        classname: "bg-danger text-light",
        delay: 5000,
      });
    };
    if (this.premium) {
      this.qPonService
        .getPremiumBusinessQPonListing(page, this.params)
        .subscribe(onSuccess, onError);
    } else {
      this.qPonService
        .getBusinessQPonListing(page, this.params)
        .subscribe(onSuccess, onError);
    }
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.pendingQPonArray = data.result;
      this.sortType = "";
      this.sorting.setData(this.pendingQPonArray);
      this.disableNext = false;
    }
  }

  /**
   * qpons details models
   * @param status this containt the qpons status
   * @param tabType boolen value
   * @param qPonDetailsArray contain the qpon array values
   */
  openQPonDetailsModel(status, tabType, qClubId, qPonDetailsArray) {
    this.dialog.dataModal(ViewQPonDetailsComponent, {
      isEdit: status,
      valueArray: qPonDetailsArray,
      tabType,
      qClubId,
      premium: this.premium,
    });
    this.dialog.modalReference.componentInstance.qPonValue.subscribe((data) => {
      if (data !== null) {
        if (this.businessId) {
          this.getPendingBusinessQPon(this.currentPage, this.businessId);
        } else {
          this.getPendingBusinessQPonForAdmin(this.currentPage);
        }

        if (data.status) {
          this.toast.show(messages.qPonsApprove, {
            classname: "bg-success text-light",
          });
        } else {
          this.toast.show(messages.qPonsReject, {
            classname: "bg-success text-light",
          });
        }
      }
    });
  }

  applyFilters(value) {
    this.currentPage = 1;
    this.filterData(value);
  }

  /**
   * Filter record based on the user selected value
   * @param value contain the filter values
   */
  filterData(value) {
    this.isFiltered = false;
    this.filters = {};
    if (value) {
      this.params = {
        status: "PENDING",
        qPonType: value.qPonType,
        qClubId: value.qClubId,
        qbid: value.qbid,
      };

      this.qPonService
        .getFilterDataListing(this.currentPage, this.params, this.premium)
        .subscribe(
          (data) => {
            this.setDataResponse(data);
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: "bg-danger text-light",
              delay: 5000,
            });
          }
        );
    } else {
      this.filters = {};
      this.getPendingBusinessQPonForAdmin(this.currentPage);
    }
  }

  /**
   * Changer the page on click event
   * @param $event page number
   */
  clickedPage($event) {
    this.currentPage = $event;
    if (this.isFiltered) {
      this.filterData(this.filters);
    } else {
      if (this.businessId) {
        this.getPendingBusinessQPon(this.currentPage, this.businessId);
      } else {
        this.getPendingBusinessQPonForAdmin(this.currentPage);
      }
    }
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.pendingQPonArray = this.sorting.normalSort(
      this.pendingQPonArray,
      field,
      this.storySort
    );
  }

  convertDateToUTC(date) {
    return moment(new Date(date)).utc().format("DD-MM-YYYY");
  }
}
