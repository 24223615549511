import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiEndPoints, ApiService } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private api: ApiService) {}

  login(data): Observable<any> {
    return this.http.post(this.apiUrl + '/users/login?include=user', data); // ?include=user
  }
  // verify new admin email
  verifyAdminMail(data): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: data.token,
    });

    return this.http.get(environment.apiUrl + `admins/verify`, {
      headers,
    });
  }
  // set new admin password
  setAdminPassword(params, data): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: params.token,
    });
    return this.http.post(
      environment.apiUrl + `admins/complete-registration`,
      data,
      { headers }
    );
  }
}
