import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { NoPermissionComponent } from './components/no-permission/no-permission.component';
import { NoPermissionRoutingModule } from './no-permission.routing.module';
@NgModule({
  declarations: [NoPermissionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NoPermissionRoutingModule,
    FormsModule,
    SharedModule,
    NgbModule,
  ],
  exports: [],
  bootstrap: [NoPermissionComponent],
})
export class NoPermissionModule {}
