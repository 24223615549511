import { Injectable } from '@angular/core';
import { ApiEndPoints, ApiService, TokenService } from 'projects/admin/src/app/core/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  config = JSON.parse(JSON.stringify(require('../../../config.json')));
  limit: number = this.config.pageLimit;

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService
  ) {}
  /**
   * get Pending Business listing
   * @param page page
   * @param param filter object filtered by user
   * @param isPremium whether to get acoording to premium sponsors
   */
  getBusinessListing(
    page: any,
    searchTerm: any,
    param: any,
    isPremium = false
  ): Observable<any> {
    const skip = (page - 1) * this.limit;
    const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.getBusinessDetails}${
          isPremium ? `${ApiEndPoints.premium}` : ''
        }?skip=${skip}${searchText}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * export  Business listing
   * @param page page
   * @param param filter object filtered by user
   */
  exportBusinessListing(
    page: any,
    searchTerm: any,
    param: any
  ): Observable<any> {
    const skip = (page - 1) * this.limit;
    const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.getBusinessDetails}export?skip=${skip}${searchText}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAquisitionBusinessListing(
    page: any,
    countryId: any,
    searchTerm: any,
    param: any
  ): Observable<any> {
    const skip = page * this.limit;
    const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.adminCountry}${countryId}${ApiEndPoints.acquisitionBusinessListing}?skip=${skip}${searchText}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * approved Business
   * @param id id
   * @param param param
   */
  approvedBusiness(id: any, param: any): Observable<any> {
    return this.apiService
      .patch(`${ApiEndPoints.approvedPedingBusines}${id}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Sctive and Deactive Business
   * @param id id
   * @param param param
   */
  activeDeactiveBusiness(id: any, param: any): Observable<any> {
    return this.apiService
      .patch(`${ApiEndPoints.approvedPedingBusines}${id}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * send Business Email Verification Link
   * @param userId userid
   */
  sendEmailVerificationLink(userId: any): Observable<any> {
    const token = this.tokenService.getToken();
    return this.apiService
      .post(`${ApiEndPoints.sendEmailVerification}${userId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Get Business Details
   * @param businessId business id
   */
  getBusinessDetails(businessId: any): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.getBusinessDetails}${businessId}/details`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * add Questions for business and member
   * @param payload data
   */
  addQuestions(payload: any): Observable<any> {
    const token = this.tokenService.getToken();
    return this.apiService.post(`${ApiEndPoints.questions}`, payload).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Get all Questions List
   * @param type type
   */
  getQuestionsList(page, type: any): Observable<any> {
    const skip = (page - 1) * this.limit;

    const businessFilter =
      '?filter={"where":{"questionType":"' +
      type +
      '"},"limit":"' +
      this.limit +
      '","skip":"' +
      skip +
      '"}';
    return this.apiService
      .get(`${ApiEndPoints.questions + businessFilter}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Get Question details by id
   * @param id id
   */
  getQuestionDetails(id): Observable<any> {
    return this.apiService.get(`${ApiEndPoints.questions}${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   *  edit Question by id
   * @param id id
   * @param params data
   */
  editQuestion(id, params): Observable<any> {
    return this.apiService.patch(`${ApiEndPoints.questions}${id}`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * get qclub listing for business
   * @param params requested param
   * @param page current page number
   * @param searchTerm search text
   */
  getQclubBusinessDetailLists(
    params: any,
    page,
    searchTerm: any
  ): Observable<any> {
    const skip = (page - 1) * this.limit;
    const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    const paramType = params.type ? '&type=' + params.type : '';
    return this.apiService
      .post(
        `${ApiEndPoints.qClubBusinessDetails}${params.qClubId}/joinees?skip=${skip}${paramType}${searchText}`,
        params
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * assign sponsorship
   * @param id id
   * @param param param
   */
  assignSponsorShip(id: any, param: any): Observable<any> {
    return this.apiService
      .patch(`${ApiEndPoints.admin}${ApiEndPoints.premium}/${id}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
