import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit {
  userId: number;
  user;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.userId = data.userId;
      this.getUserDetails();
    });
  }

  getUserDetails() {
    this.userService.getAdminUserDetails(this.userId).subscribe((data) => {
      if (data) {
        this.user = data;
      }
    });
  }

  /**
   * back to business listing
   */
  backClicked() {
    this.location.back();
  }
}
