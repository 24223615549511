import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AdminGuard } from 'projects/admin/src/app/core/guards/admin.guard';
import {
  ApiService,
  CommonService,
  NgbdateParserService,
  StorageService,
  TokenService,
  UserService,
} from 'projects/admin/src/app/core/services';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { BusinessAcquisitionGuard } from './guards/business-acquisition.guard';
import { QclubManagerGuard } from './guards/qclub-manager.guard';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    ApiService,
    TokenService,
    UserService,
    CommonService,
    DatePipe,
    StorageService,
    { provide: NgbDateParserFormatter, useClass: NgbdateParserService },
    AdminGuard,
    QclubManagerGuard,
    BusinessAcquisitionGuard,
  ],
  declarations: [],
  exports: [],
})
export class CoreModule {}
