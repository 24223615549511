import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { timingSafeEqual } from 'crypto';

import { UserService } from '../../../services/user.service';
import { EditUserComponent } from '../modals/edit-user/edit-user.component';
@Component({
  selector: 'app-pending-users',
  templateUrl: './pending-users.component.html',
  styleUrls: ['./pending-users.component.css'],
})
export class PendingUsersComponent implements OnInit {
  imageUrl = environment.imageUrl;
  searchForm: FormGroup;
  filterCollapsed = true;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  users: any;
  sortType = '';
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  @Output() type = 'pending';
  filters = {
    roleId: '',
  };
  constructor(
    private user: UserService,
    private paginator: ApiService,
    private sorting: SortingService,
    private fb: FormBuilder,
    private modal: DialogService,
    private toast: ToastService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.getPendingUsers(this.currentPage, '');
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  /**
   * Get all pending user listing
   * @param pageNumber curremnt page number
   * @param serachParams search text
   */
  getPendingUsers(pageNumber, serachParams) {
    const filter = {
      userType: environment.adminUserType,
      status: environment.pendingAdminStatus,
      roleId: parseInt(this.filters.roleId, 10),
    };
    this.user
      .getAdminUsers(filter, serachParams, pageNumber)
      .subscribe((data) => {
        this.setDataResponse(data);
      });
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.users = data.result;
      this.sortType = '';
      this.sorting.setData(this.users);
      this.disableNext = false;
    }
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getPendingUsers(
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  // search for users
  submit() {
    this.currentPage = 1;
    this.getPendingUsers(
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  // open modal to edit user
  editUser(userId) {
    this.modal.dataModal(EditUserComponent, { userId });
    this.modal.modalReference.componentInstance.updateStatus.subscribe(
      (data) => {
        if (data !== null) {
          if (data.status === true) {
            this.getPendingUsers(
              this.currentPage,
              this.searchForm.get('searchTerm').value
            );
            this.toast.show(messages.userUpdate, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
          } else {
            this.toast.show(data.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        }
      }
    );
  }

  // filter data for list
  filterData(value: any) {
    this.filters = value;
    this.getPendingUsers(
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  // clear filters
  clearFilters(val) {
    if (val === true) {
      this.filterCollapsed = !this.filterCollapsed;
      this.filters = {
        roleId: '',
      };
      this.getPendingUsers(
        this.currentPage,
        this.searchForm.get('searchTerm').value
      );
    }
  }

  resendMail(userId) {
    this.user.resendMail(userId).subscribe(
      (data) => {
        this.toast.show(messages.businessVerificationLink, {
          classname: 'bg-success text-light',
          delay: 5000,
        });
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.users = this.sorting.normalSort(this.users, field, this.storySort);
  }
}
