import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { LocationService } from '../../../../services/location.service';
import { AddAreadistrictComponent } from '../../modals/add-areadistrict/add-areadistrict.component';
@Component({
  selector: 'app-area-districts',
  templateUrl: './area-districts.component.html',
  styleUrls: ['./area-districts.component.css'],
})
export class AreaDistrictsComponent implements OnInit {
  constructor(
    private dialog: DialogService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toast: ToastService,
    private sorting: SortingService,
    private commonService: CommonService
  ) {}
  imageUrl = environment.imageUrl;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  countryId;
  areaId;
  toSort = false;
  breadCrumbCurrentUrl;
  districts: any;
  searchText = '';
  provinceID;
  values;
  sortType = '';
  nameValue;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;

  ngOnInit() {
    this.breadCrumbCurrentUrl = this.router.url;
    this.countryId = parseInt(
      this.route.snapshot.paramMap.get('countryId'),
      10
    );
    this.areaId = parseInt(this.route.snapshot.paramMap.get('areaId'), 10);
    this.provinceID = parseInt(
      this.route.snapshot.paramMap.get('provinceId'),
      10
    );
    this.getAreaDistricts(this.provinceID, this.currentPage, this.searchText);
    this.setName();
  }

  openModal(status, id) {
    this.dialog.dataModal(AddAreadistrictComponent, {
      isEdit: status,
      countryId: this.countryId,
      areaId: this.areaId,
      provinceId: this.provinceID,
      districtId: id,
    });
    this.dialog.modalReference.componentInstance.district.subscribe((data) => {
      if (data !== null) {
        this.currentPage = 1;
        this.getAreaDistricts(
          this.provinceID,
          this.currentPage,
          this.searchText
        );
      }
    });
  }

  searchFilter(params) {
    this.searchText = params;
    this.getAreaDistricts(this.provinceID, this.currentPage, this.searchText);
  }
  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getAreaDistricts(this.provinceID, this.currentPage, this.searchText);
  }

  getAreaDistricts(provinceID, page, searchText) {
    const obj = {
      countryId: this.countryId,
      areaId: this.areaId,
      provinceId: this.provinceID,
      page: this.currentPage,
      searchText,
    };
    this.locationService.getAreaDistricts(obj).subscribe(
      (data) => {
        this.setDataResponse(data);
      },
      (error) => {
        this.toast.show(error.error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.districts = data.result;
      this.sortType = '';
      this.sorting.setData(this.districts);
      this.disableNext = false;
    }
  }

  // delete entry from db
  deleteRecord(id) {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to delete this Province?',
      id,
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data && data.status) {
        if (data.status === true) {
          this.locationService
            .deleteAreaDistrict({ districtId: id, provinceId: this.provinceID })
            .subscribe(
              () => {
                this.getAreaDistricts(
                  this.provinceID,
                  this.currentPage,
                  this.searchText
                );
                this.toast.show(messages.districtDeleted, {
                  classname: 'bg-success text-light',
                  delay: 5000,
                });
              },
              (error) => {
                this.toast.show(error.error.message, {
                  classname: 'bg-danger text-light',
                  delay: 5000,
                });
              }
            );
        } else {
        }
      }
    });
  }

  backClicked() {
    this.router.navigateByUrl(
      `/admin/locations/${this.countryId}/areas/${this.areaId}/provinces`
    );
  }
  setName() {
    const nameArray = JSON.parse(localStorage.getItem('breadcrumb'));
    this.nameValue = nameArray[2].name;
  }

  navigation(id, name) {
    const v = JSON.parse(localStorage.getItem('breadcrumb'));
    const url = this.breadCrumbCurrentUrl + '/' + id + '/q-clubs';
    v.push({ url, name });
    localStorage.setItem('breadcrumb', JSON.stringify(v));
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.districts = this.sorting.normalSort(
      this.districts,
      field,
      this.storySort
    );
  }
}
