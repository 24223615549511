import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { LocationService } from '../../../../location/services/location.service';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() data;
  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    private location: LocationService,
    private dialog: DialogService,
    private toast: ToastService
  ) {}

  ngOnInit() {}

  confirm(status) {
    this.dialog.close();
    this.status.emit({ status });
  }
}
