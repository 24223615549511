import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { MasterData } from "../models";
import { ApiEndPoints } from '../constants';

import { ApiService } from './api.service';

@Injectable()
export class CommonService {
  pageArray = new BehaviorSubject<any>(null);
  constructor(private apiService: ApiService, private http: HttpClient) {}

  /*getUserMasterData(params: any): Observable<MasterData> {
    return this.apiService.get(ApiEndPoints.masterData + params).pipe(
      map(response => {
        return response.result;
      })
    );
  }*/

  /**
   * api call to check existing username
   */
  /*checkExistingUser(username: string): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.checkuser}` + "?username=" + username)
      .pipe(
        map(data => {
          return data.result;
        })
      );
  }

  checkValidToken(token: string): Observable<any> {
    return this.apiService
      .get(
        `${ApiEndPoints.getUser}${
          ApiEndPoints.checkValidToken
        }?access_token=${token}&NOT_VALID_TOKEN`
      )
      .pipe(
        map(response => {
          return response.result;
        })
      );
  }
*/
}
