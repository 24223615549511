import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css'],
})
export class ManageUsersComponent implements OnInit {
  imageUrl = environment.imageUrl;
  users = [];
  filterCollapsed = true;
  items = [];
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  searchForm: FormGroup;
  pageOfItems: Array<any>;
  sortType = '';
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  @Output() type = 'all';
  activePage = 1;
  filters: {} = {
    roleId: '',
    status: ['ACTIVE', 'DEACTIVATED'],
    userType: environment.adminUserType,
    assignTo: '',
  };
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private user: UserService,
    private sorting: SortingService,
    private paginator: ApiService,
    private dialog: DialogService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.getAllUsers(this.filters, this.currentPage, '');
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getAllUsers(
      this.filters,
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  // search for users
  submitForm() {
    this.currentPage = 1;
    this.getAllUsers(
      this.filters,
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  /**
   *
   * @param filter  Get All user Listing
   * @param pageNumber current page
   * @param serachParams search text
   */
  getAllUsers(filter, pageNumber, serachParams) {
    filter.userType = environment.adminUserType;
    this.user
      .getAdminUsers(filter, serachParams, pageNumber)
      .subscribe((data) => {
        this.setDataResponse(data);
      });
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.users = data.result;
      this.sortType = '';
      this.sorting.setData(this.users);
      this.disableNext = false;
    }
  }

  changeStatus(userId, status, count) {
    this.user.changeUserStatus(userId, { status }).subscribe(
      () => {
        this.users[count].status = status === true ? 'ACTIVE' : 'DEACTIVATED';
        this.toast.show(messages.userStatusUpate, {
          classname: 'bg-success text-light',
          delay: 5000,
        });
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  // delete user
  deleteUser(userId) {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to delete this user?',
      id: userId,
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data.status === true) {
        this.user.deleteAdminUser(userId, true).subscribe(
          () => {
            this.toast.show(messages.userDeleted, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            this.getAllUsers(
              this.filters,
              this.currentPage,
              this.searchForm.get('searchTerm').value
            );
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
      }
    });
  }

  // filter data for list
  filterData(value: any) {
    this.currentPage = 1;
    value.status = value.status ? value.status : ['ACTIVE', 'DEACTIVATED'];
    value.roleId = parseInt(value.roleId, 10);
    this.filters = value;
    this.getAllUsers(
      this.filters,
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  // clear filters
  clearFilters(val) {
    if (val === true) {
      this.filterCollapsed = !this.filterCollapsed;
      this.filters = {
        roleId: '',
        status: ['ACTIVE', 'DEACTIVATED'],
        userType: environment.adminUserType,
        assignTo: '',
      };
      this.getAllUsers(
        this.filters,
        this.currentPage,
        this.searchForm.get('searchTerm').value
      );
    }
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.users = this.sorting.normalSort(this.users, field, this.storySort);
  }
}
