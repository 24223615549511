import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import OTHER_CONST from "projects/admin/src/app/shared/constants/other.const";

import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent implements OnInit, OnChanges {
  imageUrl = environment.imageUrl;
  otherConstants = OTHER_CONST;
  hidePagination = false;
  disablePrev = false;
  disableNext = false;
  disableNextCondn = false;

  @Input() page: number;
  @Input() pageRecords: number;
  @Input() totalRecordCount: number;
  @Input() disableNextManual: boolean;
  @Output() emitPageNumber = new EventEmitter();

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (change.pageRecords) {
      this.pageRecords = change.pageRecords.currentValue;
    }
    if (change.disbleNextManual) {
      // disbale next pagination manually when no records in the next pagination
      // this requires because we are not getting total record count from backend services
      this.disableNextManual = change.disableNextManual.currentValue;
    }
    this.checkConditions();
  }

  ngOnInit() {}

  /**
   * @description call back to previous page
   * @param inputPage current page
   *
   */
  switchPrevious(inputPage) {
    const prevPage = inputPage > 1 ? inputPage - 1 : inputPage;
    this.emitPageNumber.emit(prevPage);
  }

  /**
   * @description call to next page
   * @param inputPage current page
   */

  switchNext(inputPage) {
    const nextPage = inputPage + 1;
    this.emitPageNumber.emit(nextPage);
  }

  /**
   * @description check condition for pagination
   */
  checkConditions() {
    // condition to set hide and show the pagination
    this.hidePagination =
      this.page === 1 && this.pageRecords < this.otherConstants.perPageRecords;
    this.disablePrev = this.page === 1;
    this.disableNext =
      this.page > 1 && this.pageRecords < this.otherConstants.perPageRecords;
    // condition to set next button disable
    this.disableNextCondn = this.disableNextManual
      ? true
      : this.disableNext || null;
  }
}
