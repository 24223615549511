import { environment } from 'projects/admin/src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TokenService } from 'projects/admin/src/app/core/index';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { BusinessService } from '../../../../business/services/business.service';
import { QPonService } from '../../../services/qpon.service';
import * as moment from 'moment';

@Component({
  selector: 'app-view-qpon-details',
  templateUrl: './view-qpon-details.component.html',
  styleUrls: ['./view-qpon-details.component.css'],
})
export class ViewQPonDetailsComponent implements OnInit {
  imageUrl = environment.imageUrl;
  qPonImageUrl = environment.S3ImageURL;
  @Output() qPonValue: EventEmitter<any> = new EventEmitter();
  @Input() data;
  submitValue = 'Add';
  qPonDetails;
  tabTypeCheck;
  imagePath;
  qClubId;
  qPonId;
  constants = JSON.parse(
    JSON.stringify(require('../../../../../core/constants/constants.json'))
  );
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private business: BusinessService,
    private tokenService: TokenService,
    public qPonService: QPonService,
    private toast: ToastService
  ) {}
  ngOnInit() {
    if (this.data) {
      if (this.data.qClubId) {
        this.qClubId = this.data.qClubId;
      } else {
        this.qClubId = this.data.valueArray.qClubId;
      }
      this.qPonId = this.data.valueArray.id;
      if (this.data.premium) {
        this.getPremiumQponDetails(this.qPonId);
      } else {
        this.getTheQponDetails(this.qPonId, this.qClubId);
      }

      this.tabTypeCheck = this.data.tabType;
    }
  }

  /**
   * close the model after action
   */
  closeModal() {
    this.dialog.close();
  }

  /**
   * get The Qpon Details
   * @param qPonId requested qpon id
   * @param qClubId  requested qclub id
   */
  getTheQponDetails(qPonId, qClubId) {
    this.qPonService.getQponDetails(qPonId, qClubId).subscribe(
      (data) => {
        this.qPonDetails = data.result[0];
        if (this.qPonDetails.assets) {
          this.imagePath = this.qPonDetails.assets
            ? this.qPonImageUrl +
              this.qPonDetails.assets.path +
              this.qPonDetails.assets.name +
              '.' +
              this.qPonDetails.assets.extension
            : '';
        }
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * approve and reject qpon Reject
   * @param eventValue action status
   */
  approveReject(eventValue) {
    const param = { approve: eventValue };
    this.qPonService
      .approveRejectQpon(this.data.valueArray.id, param)
      .subscribe(
        (data) => {
          this.dialog.close();
          this.qPonValue.emit({ status: eventValue });
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   * get The Qpon Details
   * @param qPonId requested qpon id
   * @param qClubId  requested qclub id
   */
  getPremiumQponDetails(qPonId) {
    this.qPonService.getPremiumQponDetails(qPonId).subscribe(
      (data) => {
        this.qPonDetails = data.result[0];
        if (this.qPonDetails.assets) {
          this.imagePath = this.qPonDetails.assets
            ? this.qPonImageUrl +
              this.qPonDetails.assets.path +
              this.qPonDetails.assets.name +
              '.' +
              this.qPonDetails.assets.extension
            : '';
        }
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }
  convertDateToUTC(date) {
    return moment(new Date(date)).utc().format('DD-MM-YYYY');
  }
}
