import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService, UserService } from 'projects/admin/src/app/core/services';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private tokenService: TokenService,
    private toast: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    LoaderService.showLoader();

    const isAccessTokenPresent = this.tokenService.getToken();
    if (isAccessTokenPresent) {
      req = req.clone({
        headers: req.headers.set('Authorization', isAccessTokenPresent),
      });
    }

    const urlArray = req.url.split('&');

    const urlString = urlArray[1] ? urlArray[1] : '';
    return next.handle(req).pipe(
      map((res) => {
        if (res.type === 0) {
          return res;
        }
        LoaderService.hideLoader();
        return res;
      }),
      catchError((httpErrorResponse) => {
        if (urlString === 'NOT_VALID_TOKEN') {
          this.userService.unAuthorizedWithoutMessage();
        } else {
          this.handleError(httpErrorResponse);
        }
        LoaderService.hideLoader();
        return throwError(httpErrorResponse);
      })
    );
  }

  /**
   * @description handle error with message
   * @param error error object
   */
  handleError(error: any) {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      console.log('Client Side-------------------', error);
      errorMsg = `Error: ${error.error.message}`;
    } else {
      // server-side error
      const err = this.getErrorMsgAndCode(error);
      console.log('Server Side-------------------', err);
      if (error.status === 0) {
        errorMsg = `Server Issue! Server is not responding, Please try again later.`;
      } else if (
        err.code === 'AUTHORIZATION_REQUIRED' ||
        err.code === 'INVALID_TOKEN' ||
        err.code === 401 ||
        err.code === 403
      ) {
        errorMsg = err.message || `Authorization Required !`;
        this.userService.unAuthorizedUser();
      } else {
        errorMsg = err.message;
      }
    }
    this.toast.showError(errorMsg);
  }

  /**
   * @description get error code
   * @param error  error object
   */
  getErrorMsgAndCode(error) {
    try {
      return {
        code: error.error.error.statusCode,
        message: error.error.error.message,
      };
    } catch (error) {
      const errMsg = `<p>Server is not reachable! Please Try again later.</p>`;
      this.toast.showError(errMsg);
    }
  }
}
