import { Pipe, PipeTransform } from '@angular/core';
import QINGO_PATTERN from 'projects/admin/src/app/core/models/qingo_pattern.enum';

@Pipe({
  name: 'qingoPattern',
})
export class QingoPatternPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const matched = Object.entries(QINGO_PATTERN).find(
      (item) => item[0] === value
    );
    return matched ? matched[1] : '';
  }
}
