import ICONS_CONST from "./icons";

const OTHER_CONST = {
  perPageRecords: 10,
  localeUsed: "en-CA",
  loaderOffTime: 60000,
  userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  imageAcceptFormats: [".png", ".jpeg", ".jpg"],
  videoAcceptFormats: [".mp4", ".webm", ".ogg"],
  sortOrder: {
    asc: "asc",
    desc: "desc",
  },

  /**
   * @description qnews image validations params
   */
  qnewsImageValidation: {
    dimenions: { width: 1200, height: 400 },
    size: { min: 10, max: 5000 },
    type: ["png", "jpeg", "jpg"],
  },

  /**
   * qnews status
   */
  qnewsStatus: {
    pending: {
      status: "PENDING",
      class: "text-danger",
      text: "pending",
      apiStatus: "pending",
    },
    approved: {
      status: "ACTIVE",
      class: "text-success",
      text: "yes",
      apiStatus: "active",
    },
  },

  /**
   * qnews sort fields
   */

  qNewsSortFields: {
    newsCreatedAt: "newsCreatedAt",
    newsPublishedAt: "newsPublishedAt",
    headline: "headline",
  },

  /**
   * instant draws status
   */
  drawStatus: {
    pending: {
      status: "PENDING",
      class: "text-danger",
      text: "pending",
      apiStatus: "pending",
    },
    approved: {
      status: "DRAWN",
      class: "text-success",
      text: "yes",
      apiStatus: "ACTIVE",
    },
  },

  instantDrawHours: 1, // draw hours add to closing time
  /**
   * instant draws sort fields
   */

  instantDrawSortFields: {
    drawClosingDate: "drawClosingDate",
    title: "title",
  },
  drawSortFields: {
    createdAt: "createdAt",
    name: "name",
  },

  /**
   * Qdreams status
   */
  qDreamStatus: {
    pending: {
      status: "PENDING",
      class: "text-danger",
      text: "pending",
      apiStatus: "pending",
    },
    approved: {
      status: "ACTIVE",
      class: "text-success",
      text: "yes",
      apiStatus: "active",
    },
  },

  qDreamDrawHours: 1, // Qdream hours add to closing time

  qDreamsSortFields: {
    closingDate: "closingDate",
    title: "title",
    drawDate: "drawDate",
  },

  /**
   * @description Qdreams file validations params
   */
  qDreamsFileValidation: {
    size: { min: 200, max: 100000 },
    type: ["mp4", "webm", "ogg"],
  },

  /**
   * @description Qdreams file validations params
   */
  qTvFileValidation: {
    size: { min: 5, max: 100000 },
    type: ["mp4", "webm", "ogg", "jpg", "jpeg", "png"],
  },
  /**
   * NFC status
   */
  nfcStatus: {
    activated: {
      status: "active",
      class: "text-danger",
      apiStatus: "active",
    },
    deactivated: {
      status: "deactive",
      class: "text-success",
      apiStatus: "deactivated",
    },
  },

  /**
   * NFC Sort field
   */
  nfcSortFields: {
    business: "business",
  },

  /**
   * sort fields for qtv products
   */
  qtvSortFields: {
    title: "title",
    totalItem: "totalItem",
    cost: "cost",
  },

  /**
   * order status
   */
  orderStatus: {
    ORDERED: {
      type: "pending",
      value: "ORDERED",
      text: "Ordered",
    },
    SHIPPED: {
      type: "pending",
      value: "SHIPPED",
      text: "Shipped",
    },
    COMPLETED: {
      type: null,
      value: "COMPLETED",
      text: "Completed",
    },
    CANCELED: {
      type: null,
      value: "CANCELED",
      text: "Canceled",
    },
  },

  qtvOrderSortFields: {
    title: "title",
    totalItems: "totalItems",
    cost: "cost",
    shippedAt: "shippedAt",
    firstName: "firstName",
    businessName: "businessName",
  },

  /**
   * confiduration for sweet alert modal when user marks a qtv as unavailable
   */
  qtvMarkUnavailableModalConfig: {
    containerClass: "swal-confirm-container",
    content: "",
    confirmBtnText: `${ICONS_CONST.thumbsUp} Yes`,
    confirmButtonAriaLabel: "Yes",
    cancelBtnText: `${ICONS_CONST.thumbsDown} Cancel`,
    cancelButtonAriaLabel: "Cancel",
    confirmBtnColor: "#28a745",
    cancelBtnColor: "#dc3545",
    confirmBtnValue: true,
    cancelBtnValue: false,
    showCancel: true,
  },

  /**
   * qtv statuses - ACTIVE or DEACTIVATED
   */
  qtvStatus: {
    ACTIVE: "ACTIVE",
    DEACTIVATED: "DEACTIVATED",
  },

  /**
   * time zones of canada
   */
  timeZones: [
    {
      name: "Pacific Time",
      tz: "Canada/Pacific",
      abbr: "PT",
    },
    {
      name: "Mountain Time",
      tz: "Canada/Mountain",
      abbr: "MT",
    },
    {
      name: "	Central Time",
      tz: "Canada/Central",
      abbr: "CT",
    },
    {
      name: "Eastern Time",
      tz: "Canada/Eastern",
      abbr: "ET",
    },
    {
      name: "Atlantic Time",
      tz: "Canada/Atlantic",
      abbr: "AT",
    },
    {
      name: "Newfoundland Time",
      tz: "Canada/Newfoundland",
      abbr: "NT",
    },
  ],

  /**
   * QTV Supplier status
   */
  qtvSupplierStatus: {
    pending: {
      status: "PENDING",
      class: "text-danger",
      text: "pending",
      apiStatus: "pending",
    },
    approved: {
      status: "ACTIVE",
      class: "text-success",
      text: "ACTIVE",
      apiStatus: "active",
    },
  },

  /** common statuses */
  commonStatus: {
    inactive: {
      status: "DEACTIVATED",
      class: "text-danger",
      btnClass: "btn-danger",
      text: "Inactive",
    },
    active: {
      status: "ACTIVE",
      class: "text-success",
      btnClass: "btn-success",
      text: "Active",
    },
  },

  /** Membership status */
  membershipStatus: {
    CONDITIONAL: {
      text: "Conditional",
      status: "CONDITIONAL",
    },
    PERMANENT: {
      text: "Permanent",
      status: "PERMANENT",
    },
    WAITING: {
      text: "Waiting",
      status: "WAITING",
    },
    CONDITIONAL_REMOTE: {
      text: "Conditional Remote",
      status: "CONDITIONAL_REMOTE",
      type: "remote",
    },
    PREMIUM_REMOTE: {
      text: "Premium Remote",
      status: "PREMIUM_REMOTE",
      type: "remote",
    },
  },
  DRAW_DETAILS_PLACEHOLDER: {
    QBUCK: "Q Bucks Enter Amount",
    PRIZE: "Enter Prize",
    CASH: "Cash Value  Enter Amount",
  },
  DRAW_TYPE_KEYS: {
    QBUCK: "QBUCK",
    PRIZE: "PRIZE",
    CASH: "CASH",
  },
  GAME_ROUND: "ROUND_1",
  GAME_LEVELS: {
    LEVEL_4: "4",
    LEVEL_7: "7",
  },
  PREMIUM_SPONSOR_TYPES: ["PROVINCIAL", "NATIONAL"],
  PREMIUM: "premium",
  QPON_TYPES: {
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
    STICKER: "STICKER",
  },
  SPIN_WHEELS_SPOKES: {
    QBUCK: [1, 5],
    QDREAM: [3],
    TRY_AGAIN: [7],
  },
};

export default OTHER_CONST;
