import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ApiEndPoints } from "projects/admin/src/app/core/constants";
import { ApiService } from "projects/admin/src/app/core/services/api.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { NgbTime } from "@ng-bootstrap/ng-bootstrap/timepicker/ngb-time";
import OTHER_CONST from "projects/admin/src/app/shared/constants/other.const";
import { TimezonePipe } from "projects/admin/src/app/shared/pipes/timezone.pipe";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  otherConstants = OTHER_CONST;
  // plain subject to hide and show footer
  hideFooter = new Subject<boolean>();
  constructor(
    private apiService: ApiService,
    private timeZonePipe: TimezonePipe
  ) {}

  /**
   * @description format any date in yyyy-mm-dd format
   * @param date any date
   */
  formatDateYmd(inpuDate?) {
    const date = inpuDate ? new Date(inpuDate) : new Date();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }

  /**
   * @description format ngb time to string format
   * @param time ngbtime format
   */
  ngbToHHMMSS(time: NgbTime) {
    if (time) {
      const hr = time.hour < 10 ? `0${time.hour}` : time.hour;
      const min = time.minute < 10 ? `0${time.minute}` : time.minute;
      const sec = time.second
        ? time.second < 10
          ? `0${time.second}`
          : time.second
        : `00`;

      return `${hr}:${min}:${sec}`;
    }
    return `00:00:00`;
  }

  /**
   * @description upload multiple files
   * @param fileArray array of files
   * @param edit param to know that if the api method is put
   */
  uploadFiles(path: string, fileArray: File[], edit?) {
    const formData = new FormData();
    fileArray.forEach((file, i) => {
      formData.append(`file${i + 1}`, file);
    });

    const imgUpload$ = edit
      ? this.apiService.putFormData(path, formData)
      : this.apiService.postFormData(path, formData);

    return imgUpload$.pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description set time to a given date and return a new date
   * @param date any data
   * @param timeObject object in hour min sec
   */
  getDateByTime(timeObject, date?) {
    const inputDate: Date = date ? new Date(date) : new Date();
    const inputTime: { hour; minute; sec? } = timeObject;
    inputDate.setHours(inputTime.hour, inputTime.minute, inputTime.sec || 0);
    return inputDate;
  }

  /**
   * @description return date object in ngb date format
   * @param date date
   */
  getNgbDateFormat(date) {
    const newDate = new Date(date);
    return {
      year: newDate.getFullYear(),
      month: newDate.getMonth() + 1,
      day: newDate.getDate(),
    };
  }

  /**
   * @description return minimum date for the ngb date picker
   * @param dateToConvert date to convert
   * @param daysToAdd days to add
   */
  pickerMinimumDate(dateToConvert, daysToAdd?) {
    const date = new Date(dateToConvert);
    if (daysToAdd) {
      const addedDate = date.setDate(date.getDate() + daysToAdd);
      return this.getNgbDateFormat(addedDate);
    } else {
      return this.getNgbDateFormat(new Date());
    }
  }

  /**
   * @description return date string from ngb date picker
   * @param ngbDate ngbdate
   */
  getDateStringFromNgb(ngbDate: NgbDate) {
    const year = ngbDate.year;
    const month = ngbDate.month < 10 ? `0${ngbDate.month}` : ngbDate.month;
    const date = ngbDate.day < 10 ? `0${ngbDate.day}` : ngbDate.day;
    return `${year}-${month}-${date}`;
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Marks all controls in a form group as dirty
   * @param formGroup - The form group to dirty
   */
  markFormGroupDirty(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  /**
   * @description convert date to time zone
   * @param date date to convert
   * @param timeZone timezone in convert
   * @param format time format
   */
  convertToTimeZone(date: Date, timeZone?, format?) {
    return this.timeZonePipe.transform(date, timeZone, format);
  }

  /**
   * @description convert string HH:MM:SS to NgbTime format
   * @param timeString HH:MM:SS
   */
  stringToNgbTime(timeString: string) {
    const splitStr = timeString.split(":");
    let hr = parseInt(splitStr[0], 10);
    hr = hr === 24 ? 0 : hr;
    const min = parseInt(splitStr[1], 10);
    const sec = splitStr[2] ? parseInt(splitStr[2], 10) : 0;
    return { hour: hr, minute: min, second: sec };
  }

  /**
   * get draws listing
   */
  getDrawsListing(): Observable<any> {
    return this.apiService.get(`${ApiEndPoints.gameDraw}/list`);
  }

  /**
   * @description construct a Date field for UI
   * @param month new Date.getMonth()
   * @param year new Date.getFullYear()
   */
  getMinDate(monthsToAdd = 0) {
    const date =
      new Date().getFullYear().toString() +
      "-" +
      (new Date().getMonth() + 1 + monthsToAdd < 10 ? "0" : "") +
      (new Date().getMonth() + 1 + monthsToAdd).toString().slice(-2);
    return date;
  }

  /**
   * compare 2 dates by months and return and check if date1 is ahead of date2
   * @param date1 date 1
   * @param date2 date 2
   */
  compareForMonths(date1, date2) {
    return new Date(date1).getMonth() > date2.getMonth();
  }

  /**
   * @description construct a Date field for UI
   * @param month new Date.getMonth()
   * @param year new Date.getFullYear()
   */
  getMinNextMonthDate() {
    const date =
      new Date().getFullYear().toString() +
      "-" +
      (new Date().getMonth() + 2 < 10 ? "0" : "") +
      (new Date().getMonth() + 2).toString().slice(-2);
    return date;
  }

  /**
   * @description format a Date field for UI
   * @param month new Date.getMonth()
   * @param year new Date.getFullYear()
   */
  formatToMinDate(dateToFormat) {
    const date =
      new Date(dateToFormat).getFullYear().toString() +
      "-" +
      (new Date(dateToFormat).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(dateToFormat).getMonth() + 1).toString().slice(-2);
    return date;
  }
}
