import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { environment } from 'projects/admin/src/environments/environment';

import { UserService } from '../../../core';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  imageUrl = environment.imageUrl;
  token = '';
  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.forgotPassForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9][-a-zA-Z0-9._]+@([- a-z0-9]+[.])+[a-z]{2,5}$'
          ),
        ],
      ],
    });
  }

  /**
   * Sent reset password link to requested email id
   */
  forgotPasswordSubmit() {
    for (const controller in this.forgotPassForm.controls) {
      if (this.forgotPassForm.controls.hasOwnProperty(controller)) {
        this.forgotPassForm.get(controller).markAsTouched();
      }
    }

    if (this.forgotPassForm.valid) {
      this.userService
        .forgotPassword({
          email: this.forgotPassForm.value.email,
          platform: 'ADMIN',
        })
        .subscribe(
          (data) => {
            this.forgotPassForm.reset();
            this.forgotPassForm.get('email').markAsUntouched();
            this.toast.show(data.message, {
              classname: 'bg-success text-light',
            });
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
            });
          }
        );
    }
  }

  get f() {
    return this.forgotPassForm.controls;
  }
}
