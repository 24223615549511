import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';

import { BusinessAcquisitionRoutingModule } from './business-acquisition-routing.module';
import { AcquisitionPendingComponent } from './components/acquisition-component/acquisition-division/acquisition-pending/acquisition-pending.component';
import { AcquisitionRegisterdComponent } from './components/acquisition-component/acquisition-division/acquisition-registered/acquisition-registered.component';
import { AcquisitionListComponent } from './components/acquisition-component/acquisition-list/acquisition-list.component';
import { AcquisitionSearchfilterComponent } from './components/acquisition-component/shared/acquisition-searchfilter/acquisition-searchfilter.component';
import { BusinessAcquisitionComponent } from './components/business-acquisition/business-acquisition.component';

@NgModule({
  declarations: [
    BusinessAcquisitionComponent,
    AcquisitionListComponent,
    AcquisitionRegisterdComponent,
    AcquisitionPendingComponent,
    AcquisitionSearchfilterComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BusinessAcquisitionRoutingModule,
    SharedModule,
    NgbModule,
  ],
  exports: [],
  bootstrap: [BusinessAcquisitionComponent],
})
export class BusinessAcquisitionModule {}
