import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
// import "rxjs/add/operator/finally";

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Access-Control-Allow-Origin: *',
      Accept: 'application/json',
    }),
  };

  private formatErrors(error: any) {
    // return new ErrorObservable(error.error || "Server Error");
    return throwError(error.error || 'Server error');
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    // let randomNumber = Math.floor(Date.now());
    return this.http
      .get(`${environment.apiUrl}${path}`, {
        params,
        headers: this.httpOptions.headers,
      })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}${path}`, body)
      .pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: object = {}): Observable<any> {
    return this.http
      .patch(`${environment.apiUrl}${path}`, body)
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}${path}`, body, this.httpOptions)
      .pipe(catchError(this.formatErrors));
  }

  delete(path, body: object = {}): Observable<any> {
    return this.http
      .request('delete', `${environment.apiUrl}${path}`, { body })
      .pipe(catchError(this.formatErrors));
  }

  postFormData(path: string, body: object = {}): Observable<any> {
    let customHeader: HttpHeaders = null;
    customHeader = new HttpHeaders({ Accept: '*/*' });
    return this.http
      .post(`${environment.apiUrl}${path}`, body, { headers: customHeader })
      .pipe(catchError(this.formatErrors));
  }

  putFormData(path: string, body: object = {}): Observable<any> {
    let customHeader: HttpHeaders = null;
    customHeader = new HttpHeaders({ Accept: '*/*' });
    return this.http
      .put(`${environment.apiUrl}${path}`, body, { headers: customHeader })
      .pipe(catchError(this.formatErrors));
  }

  setHttpHeaders(headerToken) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: headerToken,
        Accept: 'application/json',
      }),
    };
  }

  // reset http headers
  resetHttpHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Access-Control-Allow-Origin: *',
        Accept: 'application/json',
      }),
    };
  }
}
