import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'projects/admin/src/app/core/guards/admin.guard';

import { AdminUserComponent } from './components/admin-user/admin-user.component';
import { AddAdminuserComponent } from './components/manage/add-adminuser/add-adminuser.component';
import { ManageUsersComponent } from './components/manage/manage-users/manage-users.component';
import { PendingUsersComponent } from './components/manage/pending-users/pending-users.component';
import { UserDetailsComponent } from './components/manage/user-details/user-details.component';

const routes: Routes = [
  {
    path: '',
    component: AdminUserComponent,
    children: [
      {
        path: 'manage-users',
        component: ManageUsersComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'manage-users/add-admin-user',
        component: AddAdminuserComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'pending-users',
        component: PendingUsersComponent,
        canActivate: [AdminGuard],
      },
      {
        path: ':userId/user-details',
        component: UserDetailsComponent,
        canActivate: [AdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
