import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessAcquisitionGuard } from 'projects/admin/src/app/core/guards/business-acquisition.guard';

import { AcquisitionListComponent } from './components/acquisition-component/acquisition-list/acquisition-list.component';
import { BusinessAcquisitionComponent } from './components/business-acquisition/business-acquisition.component';
const routes: Routes = [
  {
    path: '',
    component: BusinessAcquisitionComponent,
    children: [
      {
        path: 'acquisition-list',
        component: AcquisitionListComponent,
        canActivate: [BusinessAcquisitionGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessAcquisitionRoutingModule {}
