import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { environment } from 'projects/admin/src/environments/environment';

import { MustMatch, UserService } from '../../../core';
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css'],
})
export class NewPasswordComponent implements OnInit {
  changePassForm: FormGroup;
  params;
  imageUrl = environment.imageUrl;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.changePassForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        cnfrmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'cnfrmPassword'),
      }
    );
    this.getParams();
  }

  /**
   * Get param from url like token and check if it is valid or not
   *
   */
  getParams() {
    this.route.queryParams.subscribe((data) => {
      if (data !== null) {
        if (data.token) {
          this.params = data;
        } else {
          this.loginRedirect();
        }
      } else {
        this.loginRedirect();
      }
    });
  }

  /**
   * Redirect user to login screen
   */
  loginRedirect() {
    this.router.navigateByUrl('login', { queryParams: null, replaceUrl: true });
  }

  // return for controls
  get f() {
    return this.changePassForm.controls;
  }

  /**
   * reset new password
   */
  resetPassword() {
    for (const controller in this.changePassForm.controls) {
      if (this.changePassForm.controls.hasOwnProperty(controller)) {
        this.changePassForm.get(controller).markAsTouched();
      }
    }
    if (this.changePassForm.valid) {
      this.userService
        .resetPassword(
          { newPassword: this.changePassForm.get('password').value },
          this.params.token
        )
        .subscribe(
          (data) => {
            this.userService.resetHttpHeaders();
            this.toast.show(data.message, {
              classname: 'bg-success text-light',
            });
            setTimeout(() => {
              this.loginRedirect();
            });
          },
          (error) => {
            this.userService.resetHttpHeaders();
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
            });
          }
        );
    }
  }
}
