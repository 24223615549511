import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import messages from '@core-module/constants/successMessages.json';
import { TokenService } from 'projects/admin/src/app/core/index';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { environment } from 'projects/admin/src/environments/environment';
import { of } from 'rxjs';

import { ManagerService } from '../../services/manager.service';
@Component({
  selector: 'app-manager-details',
  templateUrl: './manager-details.component.html',
  styleUrls: ['./manager-details.component.css'],
})
export class ManagerDetailsComponent implements OnInit {
  qmembers = false;
  imageUrl = environment.imageUrl;
  pendingBusinessListing = [];
  currentPage = 0;
  totalSize: number;
  values;
  pageSize = 10;
  searchText = '';
  searchForm: FormGroup;
  managerId;
  qClubDetails;
  managerName;
  mapRadius = 0;
  zoom = 10;
  latitude: number;
  longitude: number;
  geoCoder;
  navigatorId;
  radius;
  searchExistingBusinessForm: FormGroup;
  clubId;
  qName;
  sortType = '';
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private apiService: ApiService,
    private managerService: ManagerService,
    private tokenService: TokenService,
    private sorting: SortingService
  ) {}

  ngOnInit() {
    this.managerId = this.tokenService.getUserId();
    this.managerName = this.tokenService.getUserName();
    this.getManagerQclub(this.managerId);
    this.searchExistingBusinessForm = this.fb.group({
      searchTerm: [''],
    });
  }

  getManagerQclub(managerId) {
    this.managerService.getManagerQclubDetails(managerId).subscribe(
      (data) => {
        if (data && data.result) {
          this.qClubDetails = data.result;
          this.sortType = '';
          this.sorting.setData(this.qClubDetails);
          this.apiService.currentPage.next(this.currentPage);
        } else {
          this.qClubDetails = data.result;
          this.currentPage = this.currentPage - 1;
          this.apiService.currentPage.next(this.currentPage);
        }
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  setRadius(radius) {
    this.mapRadius = parseFloat(radius) * 1000;
  }

  searchExistingForm() {
    /*
    this.currentPage = 0;
    this.getExistingBusiness(
      this.currentPage,
      this.countryId,
      this.searchExistingBusinessForm.get('searchTerm').value
    );*/
  }

  // sort the business name
  onSort(type, name) {
    this.sortType = type === '' ? 'asc' : type;
    this.sortType = type === 'asc' ? 'dsc' : this.sortType;
    this.sortType = type === 'dsc' ? '' : this.sortType;
    const direction = this.sortType;
    this.qClubDetails = this.sorting.sortDataBusiness(
      this.qClubDetails,
      name,
      direction
    );
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    // console.log('qClubDetails', this.qClubDetails);
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.qClubDetails = this.sorting.normalSort(
      this.qClubDetails,
      field,
      this.storySort
    );
  }
}
