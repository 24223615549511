import { Injectable, Pipe, PipeTransform } from '@angular/core';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';

@Pipe({
  name: 'dateTz',
})
@Injectable()
export class TimezonePipe implements PipeTransform {
  localeFormat = OTHER_CONST.localeUsed;
  currentZone = OTHER_CONST.userTimeZone;
  zones = OTHER_CONST.timeZones;
  transform(inputDate: any, zone: string, format?): any {
    const dateFormed: Date = inputDate ? new Date(inputDate) : new Date();

    // find the matched timezone
    const record = this.zones.find((item) => item.tz === zone);
    const dateTimeOptions = this.getTimeZoneOptions(record, format);

    // formate date
    const formatter = new Intl.DateTimeFormat(
      this.localeFormat,
      dateTimeOptions
    );
    const dateInNewTimezone = formatter.format(dateFormed);
    // return here
    return dateInNewTimezone;
  }

  /**
   * @description return date time formatting options
   */
  getTimeZoneOptions(record, format) {
    let options: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: record ? record.tz : this.currentZone,
    };
    if (format === 'dtz') {
      // when timezone need to show
      options.timeZoneName = 'short';
    } else if (format === 'dt') {
      // when date and time need to show
      options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        ...options,
      };
    } else if (format === 'tm') {
      // when only time need to show
      options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: record ? record.tz : this.currentZone,
      };
    } else if (format === '24hr') {
      options = {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: record ? record.tz : this.currentZone,
      };
    }

    return options;
  }
}
