import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-acquisition-searchfilter',
  templateUrl: './acquisition-searchfilter.component.html',
  styleUrls: ['./acquisition-searchfilter.component.css'],
})
export class AcquisitionSearchfilterComponent implements OnInit {
  searchForm: FormGroup;
  imageUrl = environment.imageUrl;
  @Output() modalEvent = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() sortEvent = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  searchExistingForm() {
    this.search.emit(this.searchForm.get('name').value);
  }
  // openModal() {
  //   this.modalEvent.emit();
  // }

  // sort() {
  //   this.sortEvent.emit(true);
  // }
}
