import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InitializeService } from 'projects/admin/src/app/core/services/initialize.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsContainerComponent } from 'projects/admin/src/app/shared/components/toast/toasts-container.component';
import { NgMonthPickerModule } from 'ng-month-picker';

import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core';
import { AuthGuard } from './shared/guard/auth.guard';
import { InterceptorService } from './shared/interceptor/interceptor.service';
import { LoaderComponent } from './shared/loader/loader.component';

/**
 * @description method returning the loggedin user details by api call
 * this is injected in app module so, the user data will be available on
 * app initialize
 * @param initializeService service
 */
export function app_init(initializeService: InitializeService) {
  return () => initializeService.fetchLoggedInUserData();
}
@NgModule({
  declarations: [AppComponent, ToastsContainerComponent, LoaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    ReactiveFormsModule,
    FormsModule,
    AdminModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    CoreModule,
    NgMonthPickerModule,
  ],
  providers: [
    InitializeService,
    {
      provide: APP_INITIALIZER,
      useFactory: app_init,
      deps: [InitializeService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
