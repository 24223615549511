import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilter',
})
export class CustomFilterPipe implements PipeTransform {
  transform(items: any[], field: string, param: string, matched?): any {
    if (items && field && param) {
      const newItems = items.filter((data) =>
        matched
          ? data[field].toString() === param.toString()
          : data[field].toString() !== param.toString()
      );
      return newItems;
    }
    return [];
  }
}
