import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {
  @Input() data;
  editForm: FormGroup;
  roles: [];
  userDetails;
  @Output() updateStatus: EventEmitter<any> = new EventEmitter();

  constructor(
    private user: UserService,
    private fb: FormBuilder,
    private modal: DialogService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.getUserDetails();
    this.createForm();
  }

  // init form
  createForm() {
    this.editForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.compose([
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9][-a-zA-Z0-9._]+@([- a-z0-9]+[.])+[a-z]{2,5}$'
            ),
          ]),
        ],
      ],
      phoneNo: [
        '',
        [Validators.required, Validators.pattern(/^[+() \d]{10}$/)],
      ],
      role: ['', [Validators.required]],
      name: [''],
      userContacts: [''],
      userRoles: [''],
    });
    this.getRoles();
  }

  getUserDetails() {
    this.user.getAdminUserDetails(this.data.userId).subscribe((data) => {
      this.fillForm(data);
      this.userDetails = data;
    });
  }

  // get admin roles
  getRoles() {
    this.user.getAdminRoles().subscribe((data) => {
      this.roles = data.result;
    });
  }

  fillForm(data) {
    this.editForm.get('firstName').patchValue(data.name);
    this.editForm.get('lastName').patchValue(data.lastName);
    this.editForm.get('email').patchValue(data.email);
    const phoneNumber = data.userContacts.length
      ? data.userContacts[0].phoneNo
      : '';
    this.editForm.get('phoneNo').patchValue(phoneNumber);
    const roleId = data.userRoles.length ? data.userRoles[0].roleId : '';
    this.editForm.get('role').patchValue(roleId);
  }

  // close Modal
  closeModal() {
    this.modal.close();
  }

  // edit user
  submitForm() {
    for (const controller in this.editForm.controls) {
      if (this.editForm.controls.hasOwnProperty(controller)) {
        this.editForm.get(controller).markAsTouched();
      }
    }

    if (this.editForm.valid) {
      this.customForm();
      this.user.editAdminUser(this.data.userId, this.editForm.value).subscribe(
        (data) => {
          this.modal.close();
          this.updateStatus.emit({ status: true });
        },
        (error) => {
          this.updateStatus.emit({ status: false, error: error.error });
        }
      );
    }
  }

  // create custom form
  customForm() {
    this.editForm.get('name').setValue(this.editForm.get('firstName').value);

    const contactId = this.userDetails.userContacts.length
      ? this.userDetails.userContacts[0].id
      : null;

    this.editForm.get('userContacts').setValue({
      id: contactId,
      phoneNo: this.editForm.get('phoneNo').value,
    });
    const roleUpdateID = this.userDetails.userRoles.length
      ? this.userDetails.userRoles[0].id
      : null;
    this.editForm.get('userRoles').setValue({
      id: roleUpdateID,
      roleId: parseInt(this.editForm.get('role').value, 10),
    });
  }

  // return form control
  get f() {
    return this.editForm.controls;
  }
}
