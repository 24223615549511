import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { TokenService } from 'projects/admin/src/app/core/services/token.service';
import { SwalAlertService } from 'projects/admin/src/app/shared/services/swal-alert.service';
import { Observable } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';

@Injectable()
export class GuestUserGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private router: Router,
    private swalAlert: SwalAlertService
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.tokenService.isUserLoggedIn()) {
      const params = {
        icon: `warning`,
        title: `Sorry!`,
        html: `<p>You are already logged in.</p>`,
      };
      // if user is logged in navigate to admin
      this.swalAlert
        .warningAlert(params)
        .then((value: SweetAlertResult) => {})
        .catch((err) => {
          console.error(err);
        });

      this.router.navigate(['/admin']);
    }
    return !this.tokenService.isUserLoggedIn();
  }
}
