import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiEndPoints, ApiService, TokenService } from "../../../core";

@Injectable({
  providedIn: "root",
})
export class QPonService {
  config = JSON.parse(JSON.stringify(require("../../../config.json")));
  limit: number = this.config.pageLimit;

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService
  ) {}

  /**
   * get Pending Business listing
   * @param page requested page
   * @param param requested param
   */
  getBusinessQPon(page: any, businessId: any, param: any): Observable<any> {
    const skip = (page - 1) * this.limit;
    // const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.getBusinessDetails}${businessId}/qpons?skip=${skip}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * get Business QPon Listing
   * @param page current page
   * @param param requested param
   */
  getBusinessQPonListing(page: any, param: any): Observable<any> {
    const skip = (page - 1) * this.limit;
    // const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(`${ApiEndPoints.adminQponsListing}?skip=${skip}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * approved and rejet the qpons
   * @param qPonId qpons id
   * @param param requested param
   */
  approveRejectQpon(qPonId: any, param: any): Observable<any> {
    return this.apiService
      .post(
        `${ApiEndPoints.adminQpons}${qPonId}${ApiEndPoints.adminQponsApproveReject}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Get the qclub listing
   */
  getQclub(): Observable<any> {
    return this.apiService.get(`${ApiEndPoints.getQclubList}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * date is returning normal date value and not in utc format
   * functionality was changed later for qpons and function is used now for just format change
   * @param date
   */
  convertDateToUTC(date) {
    let localDate = new Date(date);
    localDate = new Date(localDate.setDate(localDate.getDate()));
    localDate = new Date(localDate.setMonth(localDate.getMonth()));
    return localDate;
  }

  /**
   * filter datat based on the filter
   * @param page current page
   * @param param requested param
   */
  getFilterDataListing(
    page: any,
    param: any,
    premium = false
  ): Observable<any> {
    const skip = (page - 1) * this.limit;
    // const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.adminQponsListing}${premium ? "/premium" : ""}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   *  get the qpons list based on the qclub and qpon id
   * @param qPonId  qpon id
   * @param qClubId  qclub id
   */
  getQponDetails(qPonId, qClubId): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.qClubDetails}${qClubId}/qpons/${qPonId}/details`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   *  get the premium qpons list based on the qclub and qpon id
   * @param qPonId  qpon id
   */
  getPremiumQponDetails(qPonId): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.qClubDetails}premium/${qPonId}/details`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * get premium Business QPon Listing
   * @param page current page
   * @param param requested param
   */
  getPremiumBusinessQPonListing(page: any, param: any): Observable<any> {
    const skip = (page - 1) * this.limit;
    // const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.adminQponsListing}/${ApiEndPoints.premium}?skip=${skip}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
