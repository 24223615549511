import { Injectable } from '@angular/core';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private static loaderEnabled: boolean;
  constructor() {}

  get loaderEnabled() {
    return LoaderService.loaderEnabled;
  }

  public static showLoader() {
    LoaderService.loaderEnabled = true;
    setTimeout(() => {
      this.hideLoader();
    }, OTHER_CONST.loaderOffTime);
  }

  public static hideLoader() {
    LoaderService.loaderEnabled = false;
  }
}

export function LoaderEnabled(): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;
    descriptor.value = function () {
      LoaderService.showLoader();

      return original.apply(this, arguments).pipe(
        map((res) => {
          LoaderService.hideLoader();
          return res;
        }),
        catchError((err) => {
          LoaderService.hideLoader();
          throw err;
        })
      );
    };

    return descriptor;
  };
}
