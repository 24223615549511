import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiEndPoints, ApiService } from '../../../core';

@Injectable({
  providedIn: 'root',
})
export class QclubService {
  apiUrl = environment.apiUrl;
  config = JSON.parse(JSON.stringify(require('../../../config.json')));

  limit: number = this.config.pageLimit;
  constructor(private http: HttpClient, private apiService: ApiService) {}

  /**
   *
   * @param districtId districtId
   * @param qclubName qClubName
   */
  checkQclubName(districtId: number, qclubName: string): Observable<any> {
    return this.apiService
      .post(
        `${ApiEndPoints.adminCountry}${districtId}${ApiEndPoints.checkQclubName}${qclubName}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Add Qclub Details
   * @param districtId districtId
   * @param formData formData
   */
  addQclubDetails(districtId: any, formData: any): Observable<any> {
    return this.apiService
      .post(
        `${ApiEndPoints.adminDistrict}${districtId}${ApiEndPoints.addQclub}`,
        formData
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  editQclubDetails(
    districtId: any,
    qclubId: any,
    formData: any
  ): Observable<any> {
    return this.apiService
      .patch(
        `${ApiEndPoints.adminDistrict}${districtId}${ApiEndPoints.qclubs}${qclubId}${ApiEndPoints.updateQclub}`,
        formData
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**
   * Get Qclub Listing
   * @param districtId districtId
   * @param page page
   * @param searchTerm searchTerm
   */
  getQclubList(districtId, page, searchTerm): Observable<any> {
    const skip = (page - 1) * this.limit;
    const searchText = searchTerm ? '&searchTerm=' + searchTerm : '';
    return this.apiService
      .post(
        `${ApiEndPoints.adminDistrict}${districtId}${ApiEndPoints.qclubListing}?skip=${skip}${searchText}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getQclubManagerList(countryID: any, roleID: any): Observable<any> {
    // ?roleId=
    const role = roleID ? '?roleId=' + roleID : '';
    return this.apiService
      .get(
        `${ApiEndPoints.adminCountries}${countryID}${ApiEndPoints.qclubManagersListing}${role}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getMeta(): Observable<any> {
    return this.apiService.get(`${ApiEndPoints.meta}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getQclubDetails(id: number): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.qClubDetails}${id}/details`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getQclubListingByProvinceId(provinceId: number): Observable<any> {
    return this.apiService
      .post(
        `${ApiEndPoints.adminProvince}${provinceId}${ApiEndPoints.qclubListing}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
