import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css'],
})
export class InboxComponent implements OnInit {
  imageUrl = environment.imageUrl;
  constructor() {}

  ngOnInit() {}
}
