import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/admin/src/environments/environment';

import { MustMatch } from '../../../admin/shared/utils/must-match.validator';
import { ToastService } from '../../../shared/services/toast.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-verify-admin-email',
  templateUrl: './verify-admin-email.component.html',
  styleUrls: ['./verify-admin-email.component.css'],
})
export class VerifyAdminEmailComponent implements OnInit {
  changePassForm: FormGroup;
  params;
  imageUrl = environment.imageUrl;
  isAllowed = true;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService
  ) {}
  messages = JSON.parse(
    JSON.stringify(require('../../../core/constants/successMessages.json'))
  );

  ngOnInit() {
    this.changePassForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        cnfrmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'cnfrmPassword'),
      }
    );
    this.getParams();
  }

  getParams() {
    this.route.queryParams.subscribe((data) => {
      if (data !== null) {
        if (data.uid && data.token) {
          this.params = data;
          // console.log(this.params);
          this.verifyAdminMail(data.uid, data.token);
        } else {
          this.router.navigateByUrl('', {
            queryParams: null,
            replaceUrl: true,
          });
        }
      } else {
        this.router.navigateByUrl('', { queryParams: null, replaceUrl: true });
      }
    });
  }

  verifyAdminMail(uid, token) {
    this.auth.verifyAdminMail({ uid, token }).subscribe(
      (data) => {
        if (data.result.valid) {
          this.isAllowed = true;
        } else {
          this.router.navigateByUrl('');
        }
      },
      (error) => {
        this.router.navigateByUrl('');
      }
    );
  }

  // return form control
  get f() {
    return this.changePassForm.controls;
  }

  submit() {
    // for (const controller in this.changePassForm.controls) {
    //   this.changePassForm.get(controller).markAsTouched();
    // }

    for (const controller in this.changePassForm.controls) {
      if (this.changePassForm.controls.hasOwnProperty(controller)) {
        this.changePassForm.get(controller).markAsTouched();
      }
    }

    if (this.changePassForm.valid) {
      this.auth
        .setAdminPassword(this.params, {
          password: this.changePassForm.get('password').value,
        })
        .subscribe(
          (data) => {
            this.toast.show(this.messages.passwordChanged, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/login');
            }, 2000);
          },
          (error) => {
            this.toast.show(error.error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
    }
  }
}
