import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.css'],
})
export class UserFiltersComponent implements OnInit {
  filterForm: FormGroup;
  roles: [];
  @Output() filtered = new EventEmitter();
  @Input() type: string;
  @Output() closeFilter = new EventEmitter();
  constructor(private fb: FormBuilder, private user: UserService) {}

  ngOnInit() {
    this.initForm();
    this.getRoles();
  }

  initForm() {
    this.filterForm = this.fb.group({
      roleId: [''],
      assign: [''],
      status: [''],
    });
  }

  submit() {
    if (this.filterForm.valid) {
      this.filtered.emit(this.filterForm.value);
    }
  }

  // get admin roles
  getRoles() {
    this.user.getAdminRoles().subscribe((data) => {
      this.roles = data.result;
    });
  }

  // clear filters
  clearFilter() {
    this.resetForm();
    this.closeFilter.emit(true);
  }

  resetForm() {
    this.filterForm.get('roleId').setValue('');
    this.filterForm.get('assign').setValue('');
    this.filterForm.get('status').setValue('');
  }
}
