import { Injectable } from '@angular/core';

import { User } from '../models';

@Injectable()
export class TokenService {
  constructor() {}

  /**
   * @description to know if the user id loggedin or not
   */
  isUserLoggedIn() {
    const hasToken = this.getToken();
    const hasUserId = this.getUserId();
    return hasToken && hasUserId ? true : false;
  }

  saveToken(token: string) {
    window.localStorage.setItem('token', token);
  }

  getToken(): string {
    return window.localStorage.getItem('token');
  }

  // Will remove
  setUserType(userType: string) {
    window.localStorage.setItem('userType', userType);
  }

  // Will remove
  getUserType(): string {
    return window.localStorage.getItem('userType');
  }

  // Will remove
  setUserRole(userRole: string) {
    window.localStorage.setItem('userRole', userRole);
  }

  // Will remove
  getUserRole(): string {
    return window.localStorage.getItem('userRole');
  }

  setUserUniqueKey(uniqueKey: string) {
    window.localStorage.setItem('uniqueKey', uniqueKey);
  }

  getUserUniqueKey(): string {
    return window.localStorage.getItem('uniqueKey');
  }
  // Will remove
  setUserCountry(userCounryId: string) {
    window.localStorage.setItem('countryId', userCounryId);
  }

  // Will remove
  getUserCountry(): string {
    return window.localStorage.getItem('countryId');
  }

  // Will remove
  setUserRoleId(userRoleId: string) {
    window.localStorage.setItem('userRoleId', userRoleId);
  }

  // Will remove
  getUserRoleId(): string {
    return window.localStorage.getItem('userRoleId');
  }

  destroyToken() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('currentUser');
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('userType'); // Will remove
    window.localStorage.removeItem('userRoleId'); // Will remove
    window.localStorage.removeItem('userRole'); // Will remove
    window.localStorage.removeItem('countryId'); // Will remove
    window.localStorage.removeItem('breadcrumb');
    window.localStorage.removeItem('uniqueKey'); // Will remove
  }

  saveUserId(id: string) {
    window.localStorage.setItem('userId', id);
  }

  getUserId(): string {
    return window.localStorage.getItem('userId');
  }

  getEphId(): string {
    const user = window.localStorage.getItem('user');
    if (user) {
      return JSON.parse(user).userId;
    }
    return null;
  }

  // will remove
  saveUser(user: User) {
    window.localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUser() {
    const user = window.localStorage.getItem('currentUser');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  setUserName(username) {
    window.localStorage.setItem('username', username);
  }

  getUserName() {
    return window.localStorage.getItem('username');
  }
}
