import { Component, OnInit } from '@angular/core';
import {
  RoleService,
  TokenService,
  User,
  UserService,
} from 'projects/admin/src/app/core/index';
import { environment } from 'projects/admin/src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  imageUrl = environment.imageUrl;
  userData: User;
  isAdminUserCollapsed = true;
  isBusinessMenuCollapsed = true;
  isNotificationMenuCollapsed = true;
  isSpinMenuCollapsed = true;
  isMemberMenuCollapsed = true;
  isManagerMenuCollapsed = true;
  isQponsMenuCollapsed = true;
  isQnewsCollapsed = true;
  isInstantDrawsCollapsed = true;
  isQdreamsCollapsed = true;
  isSwCollapsed = true;
  isQtvCollapsed = true;
  isNfcCollapsed = true;
  isAuthorized = false;
  isSuperAdmin = false;
  isQclubManager = false;
  isBusinessAcquisition = false;
  isDrawsCollapsed = true;
  isQingoCollapsed = true;
  hideMenu = false;
  isWacAMole2Collapsed = true;
  isWacAMoleRankingCollapsed = true;
  wpUrl;
  qclubManagerRedirectUrl;
  isSponsorCollapsed = true;
  constructor(
    private tokenService: TokenService,
    private roleService: RoleService,
    private userService: UserService
  ) {
    // subscribe for the current user data
    this.userService.currentUser.subscribe((data) => {
      this.userData = data;
    });
  }
  ngOnInit() {
    this.wpUrl = environment.qclubWPUrl;
    this.qclubManagerRedirectUrl = this.roleService.areaRedirect();
    if (this.tokenService.getToken()) {
      if (this.roleService.hasSuperAdminRole()) {
        this.isSuperAdmin = true;
      } else if (this.roleService.hasQClubManagerRole()) {
        this.isQclubManager = true;
      } else if (this.roleService.hasBusinessAcquisitionRole()) {
        this.isBusinessAcquisition = true;
      }
    }
  }
}
