import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { QclubService } from '../../../../location/services/qclub.service';
import { BusinessService } from '../../../services/business.service';
@Component({
  selector: 'app-assign-approved-qclub-details',
  templateUrl: './assign-approved-qclub.component.html',
  styleUrls: ['./assign-approved-qclub.component.css'],
})
export class AssignApprovedQclubComponent implements OnInit {
  @Output() approvedAssign: EventEmitter<any> = new EventEmitter();
  @Input() data;
  countryId: number;
  businessDetails;
  qclubForm: FormGroup;
  qclubListing = [];
  constructor(
    private toast: ToastService,
    private qclubService: QclubService,
    private fb: FormBuilder,
    private dialog: DialogService,
    private businessService: BusinessService
  ) {}
  ngOnInit() {
    this.qclubForm = this.fb.group({
      qClubId: ['', Validators.required],
    });
    if (this.data.business) {
      this.getQclubForBusiness(this.data.business.businessProfile.provinceId);
    }
  }

  /**
   * In this function we are getting all the q club listing which belongs to requested province id
   * @param provinceId province id
   */
  getQclubForBusiness(provinceId: number) {
    this.qclubService.getQclubListingByProvinceId(provinceId).subscribe(
      (data) => {
        this.qclubListing = data.result;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * Submit the qclub Form value
   */
  submitForm() {
    for (const controller in this.qclubForm.controls) {
      if (this.qclubForm.controls.hasOwnProperty(controller)) {
        this.qclubForm.get(controller).markAsTouched();
      }
    }

    if (this.qclubForm.valid) {
      const param = {
        status: 'ACTIVE',
        qClubId: this.qclubForm.value.qClubId,
      };
      this.businessService
        .approvedBusiness(this.data.id, param)
        .subscribe((data) => {
          this.closeModal();
          this.approvedAssign.emit({ data: this.data, error: false });
        });
    }
  }

  /**
   * Close the approved model box
   */
  closeModal() {
    this.dialog.close();
  }

  get f() {
    return this.qclubForm.controls;
  }

  /**
   * Checking the qClub dropdow is empty
   */
  get invalidqClubId() {
    return (
      this.qclubForm.get('qClubId').touched &&
      this.qclubForm.get('qClubId').errors &&
      this.qclubForm.get('qClubId').errors.required
    );
  }
}
