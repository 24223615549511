import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiKey = environment.googleApiKey;
  paginator = new BehaviorSubject(10);
  isSearch = new BehaviorSubject('');
  currentPage = new BehaviorSubject(0);
  apiUrl = environment.apiUrl;
  // currentSubject: BehaviorSubject<any>;
  private currentSubject = new Subject<any>();

  constructor(private http: HttpClient) {}

  // logout user
  logout(): Observable<any> {
    return this.http.post(this.apiUrl + '/users/logout', {});
  }

  sendBreadcrumbDataUrl(data: any) {
    // console.log(data);
    this.currentSubject.next(data);
  }

  clearBreadcrumbData() {
    this.currentSubject.next(null);
  }

  getData(): Observable<any> {
    return this.currentSubject.asObservable();
  }
}
