import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { SortingService } from 'projects/admin/src/app/admin/shared/services/sorting.service';
import { environment } from 'projects/admin/src/environments/environment';

import { TokenService } from '../../../../../../core';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { BusinessService } from '../../../../../business/services/business.service';
import { ApiService } from '../../../../../shared/services/api.service';
@Component({
  selector: 'app-acquisition-registered',
  templateUrl: './acquisition-registered.component.html',
  styleUrls: ['./acquisition-registered.component.css'],
})
export class AcquisitionRegisterdComponent implements OnInit {
  constructor(
    private toast: ToastService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private sorting: SortingService,
    private tokenService: TokenService,
    private businessService: BusinessService
  ) {}
  imageUrl = environment.imageUrl;
  @Input() countryId;
  currentPage = 0;
  totalSize: number;
  pageOfItems: Array<any>;
  searchText = '';
  sortType = '';
  searchExistingBusinessForm: FormGroup;
  aquisitionRegidtered;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  ngOnInit() {
    console.log(this.countryId);
    this.getAquisitionRegidteredBusiness(
      this.currentPage,
      this.countryId,
      this.searchText
    );
    this.searchExistingBusinessForm = this.fb.group({
      searchTerm: [''],
    });
  }
  getAquisitionRegidteredBusiness(page, countryId, searchText) {
    const param = { status: ['ACTIVE', 'DEACTIVATED'] };
    this.businessService
      .getAquisitionBusinessListing(page, countryId, searchText, param)
      .subscribe(
        (data) => {
          // console.log(data);
          if (data && data.result) {
            // console.log(data.result);
            this.aquisitionRegidtered = data.result;
            this.sortType = '';
            this.sorting.setData(this.aquisitionRegidtered);
            this.apiService.currentPage.next(this.currentPage);
          } else {
            this.aquisitionRegidtered = [];
            // this.currentPage = 0;
            this.currentPage = this.currentPage - 1;
            this.apiService.currentPage.next(this.currentPage);
            // this.pendingBusinessListing = [];
            // this.currentPage = 0;
            // // this.currentPage = this.currentPage - 1;
            // this.apiService.currentPage.next(this.currentPage);
          }
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  searchExistingForm() {
    this.currentPage = 0;
    this.getAquisitionRegidteredBusiness(
      this.currentPage,
      this.countryId,
      this.searchExistingBusinessForm.get('searchTerm').value
    );
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getAquisitionRegidteredBusiness(
      this.currentPage,
      this.countryId,
      this.searchExistingBusinessForm.get('searchTerm').value
    );
  }

  searchFilter(params) {
    // this.searchText = params;
    // this.getCountryAreas(this.countryId, this.currentPage, this.searchText);
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.aquisitionRegidtered = this.sorting.normalSort(
      this.aquisitionRegidtered,
      field,
      this.storySort
    );
  }
}
