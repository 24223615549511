import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminWithClubManagerGuard } from 'projects/admin/src/app/shared/guard/admin-clubmanager.guard';

import { AddQclubComponent } from './components/clublocation-comp/add-qclub/add-qclub.component';
import { BusinessQPonsListComponent } from './components/clublocation-comp/business-qpon-list/business-qpon-list.component';
import { EditQclubComponent } from './components/clublocation-comp/edit-qclub/edit-qclub.component';
import { AreaDistrictsComponent } from './components/clublocation-comp/list-comp/area-districts/area-districts.component';
import { CountryAreaComponent } from './components/clublocation-comp/list-comp/country-area/country-area.component';
import { ProvinceComponent } from './components/clublocation-comp/list-comp/province/province.component';
import { QClubsComponent } from './components/clublocation-comp/list-comp/q-clubs/q-clubs.component';
import { QclubLocationComponent } from './components/clublocation-comp/list-comp/qclub-location/qclub-location.component';
import { QclubDetailComponent } from './components/clublocation-comp/qclub-detail/qclub-detail.component';
import { LocationComponent } from './components/location/location.component';

const routes: Routes = [
  {
    path: '',
    component: LocationComponent,
    children: [
      {
        path: '',
        component: QclubLocationComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path: ':countryId/areas',
        component: CountryAreaComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path: ':countryId/areas/:areaId/provinces',
        component: ProvinceComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path: ':countryId/areas/:areaId/provinces/:provinceId/area-districts',
        component: AreaDistrictsComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path:
          ':countryId/areas/:areaId/provinces/:provinceId/area-districts/:districtId/q-clubs',
        component: QClubsComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path:
          ':countryId/areas/:areaId/provinces/:provinceId/area-districts/:districtId/q-clubs/add-new-club',
        component: AddQclubComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path:
          ':countryId/areas/:areaId/provinces/:provinceId/area-districts/:districtId/q-clubs/:qclubId/edit-qclub',
        component: EditQclubComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      // { path: 'districts/:districtId/q-clubs/add-new-club', component: AddQclubComponent },
      {
        path: 'qclubs/:clubId/qclub-detail',
        component: QclubDetailComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path: 'business-qpons/:businessId/business-qpon-list',
        component: BusinessQPonsListComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LocationRoutingModule {}
