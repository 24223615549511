// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// "apiAddress": "http://localhost:3000",
// "apiAddress": "http://3.23.96.181:3000",

// declare function require(url: string);
// const config = JSON.parse(JSON.stringify(require('../../src/app/config.json')));
export const environment = {
  production: false,
  imageUrl: "./assets/img",
  googleApiKey: "AIzaSyAel1czo9RcjpRvbpXITOyP7apAwmc9uxo",
  apiUrl: "https://dev-api.myqclub.ca/api/" /** local */,
  // apiUrl: "http://3.23.207.175:3000/api/" /** dev */,
  // apiUrl: 'https://qa.api.myqclub.ca/api/', /** production */
  adminUserType: "ADMIN",
  pendingAdminStatus: "PENDING",
  environmentName: "DEV",
  qclubWPUrl: "http://qa.myqclub.ca/",
  qPonImageURL: "https://dev-api.myqclub.ca/api/business_uploads/",
  S3ImageURL: "https://qclub-bucket.s3.amazonaws.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
