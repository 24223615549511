import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'app-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.css'],
})
export class ControlErrorComponent implements OnInit {
  constructor() {}

  private static readonly errorMessages = {
    required: () => 'This field is required',
    min: (params) => `Minimum value can be ${params.min}`,
    minlength: (params) =>
      'The min number of characters is ' + params.requiredLength,
    maxlength: (params) =>
      'The max allowed number of characters is ' + params.requiredLength,
    pattern: (params) => 'The required pattern is: ' + params.requiredPattern,
    years: (params) => params.message,
    countryCity: (params) => params.message,
    uniqueName: (params) => params.message,
    telephoneNumbers: (params) => params.message,
    telephoneNumber: (params) => params.message,
    validEmail: (params) => params.message,
    validPhone: (params) => params.message,
    passwordNotMatch: (params) => params.message,
    validNumbers: (params) => params.message,
    validatePhoneNumber: (params) =>
      !params.valid ? 'Invalid Phone No, Please match the format!' : '',
    ngbDate: (params) => (params.isInvalid ? 'please select a valid Date' : ''),
  };
  @Input()
  private control: AbstractControlDirective | AbstractControl;

  ngOnInit(): void {}

  /**
   * @description check should show the errors
   */
  shouldShowErrors(): boolean {
    return (
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched)
    );
  }

  /**
   * @description return error message
   * @param type type of error
   * @param params error param
   */
  private getMessage(type: string, params: any) {
    return ControlErrorComponent.errorMessages[type](params);
  }

  /**
   * @description get list of erorrs of the current control
   */

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map((field) =>
      this.getMessage(field, this.control.errors[field])
    );
  }
}
