/// <reference types="@types/googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'projects/admin/src/app/core/index';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { of } from 'rxjs';

import { QclubService } from '../../../services/qclub.service';

import { ChangeQclubManagerComponent } from './change-qclub-manager/change-qclub-manager.component';
@Component({
  selector: 'app-qclub-detail',
  templateUrl: './qclub-detail.component.html',
  styleUrls: ['./qclub-detail.component.css'],
})
export class QclubDetailComponent implements OnInit {
  qmembers = false;
  constructor(
    private location: Location,
    private dialog: DialogService,
    private toast: ToastService,
    private tokenService: TokenService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private qclubService: QclubService,
    private mapsAPILoader: MapsAPILoader
  ) {}
  clubId;
  qClubDetails: any;
  locationData;
  mapRadius = 0;
  zoom = 10;
  lat;
  lng;
  geoCoder;
  navigatorId;
  radius;
  address;
  clubManagerDetails;
  qName;
  clubId1;
  userRole;
  userRoleActivate = false;
  ngOnInit() {
    this.userRole = this.tokenService.getUserRole();
    if (this.userRole) {
      if (this.userRole === 'SUPER_USER') {
        this.userRoleActivate = true;
      }
    }
    this.route.params.subscribe((data) => {
      if (data !== null) {
        this.clubId = data.clubId;
        this.locationData = data;
      }
    });

    if (this.clubId) {
      this.getQclubDetails(this.clubId);
    }
  }

  getQclubDetails(id) {
    this.qclubService.getQclubDetails(id).subscribe(
      (data) => {
        this.qClubDetails = data.result;
        // console.log(data.result);
        this.clubManagerDetails = data.result.clubManager[0].name;
        this.setRadius(data.result.radius);
        this.qName = data.result.name;
        this.clubId1 = this.clubId;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  changeQclubManagerModel(countryId, Qclub) {
    this.dialog.dataModal(ChangeQclubManagerComponent, {
      countryId,
      Qclub,
    });
    this.dialog.modalReference.componentInstance.qclubManagerChange.subscribe(
      (data) => {
        if (data !== null) {
          this.getQclubDetails(Qclub.id);
        }
      }
    );
  }

  setRadius(radius) {
    this.mapRadius = parseFloat(radius) * 1000;
  }

  backClicked() {
    this.location.back();
  }
}
