import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiEndPoints,
  ApiService,
  StorageService,
  TokenService,
  User,
  UserService,
} from 'projects/admin/src/app/core/index';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, finalize, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  limit = 10;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  getPendingBusiness(page: any, param: any): Observable<any> {
    const skip = page * this.limit;
    // const businessFilter = "?filter[include]=businessProfile&filter[where][userType]=BUSINESS&filter[where][status]=PENDING&filter[where][paymentDone]=true&filter[limit]="+this.limit+"&filter[skip]="+skip;
    return this.apiService
      .post(`${ApiEndPoints.businessProfilesListing}?skip=${skip}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getExistingBusiness(page: any, param: any): Observable<any> {
    const skip = page * this.limit;
    // const businessFilter = "?filter[include]=businessProfile&filter[where][userType]=BUSINESS&filter[where][or][0][status]=ACTIVE&filter[where][or][1][status]=DEACTIVATED&filter[where][paymentDone]=true&filter[limit]="+this.limit+"&filter[skip]="+skip;
    return this.apiService
      .post(`${ApiEndPoints.businessProfilesListing}?skip=${skip}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getBusinessSearch(searchText: any, param: any): Observable<any> {
    const token = this.tokenService.getToken();
    return this.apiService
      .post(
        `${ApiEndPoints.businessProfilesListing}?searchTerm=${searchText}`,
        param
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  approvedBusiness(id: any, param: any): Observable<any> {
    return this.apiService
      .patch(`${ApiEndPoints.approvedPedingBusines}${id}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  activeDeactiveBusiness(id: any, param: any): Observable<any> {
    return this.apiService
      .patch(`${ApiEndPoints.approvedPedingBusines}${id}`, param)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  sendEmailVerificationLink(userId: any): Observable<any> {
    const token = this.tokenService.getToken();
    return this.apiService
      .post(`${ApiEndPoints.sendEmailVerification}${userId}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getBusinessDetails(businessId: any): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.getBusinessDetails}${businessId}/details`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getPendingBusinessSearch(text): Observable<any> {
    return;
  }
}
