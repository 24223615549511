import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { BusinessService } from '../../../services/business.service';
@Component({
  selector: 'app-premium-sponsor',
  templateUrl: './premium-sponsor.component.html',
  styleUrls: ['./premium-sponsor.component.css'],
})
export class PremiumSponsorComponent implements OnInit {
  @Output() sponsorAssigned: EventEmitter<any> = new EventEmitter();
  @Input() data;
  sponsorForm: FormGroup;
  premiumSponsorTypes = OTHER_CONST.PREMIUM_SPONSOR_TYPES;
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private businessService: BusinessService
  ) {}
  ngOnInit() {
    this.sponsorForm = this.fb.group({
      sponsorType: ['', Validators.required],
    });
  }

  /**
   * Submit the sponsor Form value
   */
  submitForm() {
    for (const controller in this.sponsorForm.controls) {
      if (this.sponsorForm.controls.hasOwnProperty(controller)) {
        this.sponsorForm.get(controller).markAsTouched();
      }
    }

    if (this.sponsorForm.valid && this.data.businessProfileId) {
      const param = {
        sponsorType: this.sponsorForm.value.sponsorType,
      };
      this.businessService
        .assignSponsorShip(this.data.businessProfileId, param)
        .subscribe((data) => {
          this.closeModal();
          this.sponsorAssigned.emit({ data: this.data, error: false });
        });
    }
  }

  /**
   * Close the approved model box
   */
  closeModal() {
    this.dialog.close();
  }

  get f() {
    return this.sponsorForm.controls;
  }

  /**
   * Checking the sponsor dropdown is empty
   */
  get invalidSponsorType() {
    return (
      this.sponsorForm.get('sponsorType').touched &&
      this.sponsorForm.get('sponsorType').errors &&
      this.sponsorForm.get('sponsorType').errors.required
    );
  }
}
