import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { NavigationService } from 'projects/admin/src/app/shared/services/navigation.service';
import { forkJoin } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MemberService } from '../../services/member.service';

@Component({
  selector: 'app-qmember-detail',
  templateUrl: './qmember-detail.component.html',
  styleUrls: ['./qmember-detail.component.css'],
})
export class QmemberDetailComponent implements OnInit {
  memberInfo: any = {};
  memberQuestions = [];
  membershipStatus = OTHER_CONST.membershipStatus;

  constructor(
    private activeRoute: ActivatedRoute,
    private memberService: MemberService,
    private location: Location
  ) {}

  ngOnInit() {
    // subscribe router params
    this.activeRoute.params.subscribe((res) => {
      this.getMemberDetails(res);
    });
  }

  /**
   * @description get member details
   * @param params params
   */
  getMemberDetails(params) {
    if (params.hasOwnProperty('id') && !isNaN(parseInt(params.id, 10))) {
      forkJoin([
        this.memberService.getMemberDetails(params.id),
        this.memberService.getMemberQuestions({ id: params.id }),
      ])
        .pipe(
          catchError((err) => {
            return throwError(err);
          })
        )
        .subscribe(
          (res) => {
            (this.memberInfo = res[0].result),
              (this.memberQuestions = res[1].result);
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.memberInfo = {};
    }
  }

  /**
   * go back
   */
  backClicked() {
    this.location.back();
  }
}
