import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { BusinessRoutingModule } from './business.routing.module';
import { BusinessesDetailsComponent } from './components/business-details/business-details.component';
import { BusinessComponent } from './components/business/business.component';
import { ExistingBusinessesComponent } from './components/existing-businesses/existing-businesses.component';
import { AssignApprovedQclubComponent } from './components/new-requests/assign-approved-qclub/assign-approved-qclub.component';
import { NewRequestsComponent } from './components/new-requests/new-requests.component';
import { PremiumSponsorComponent } from './components/new-requests/premium-sponsor/premium-sponsor.component';
import { BusinessFiltersComponent } from './components/shared/business-filters/business-filters.component';
@NgModule({
  declarations: [
    BusinessComponent,
    AssignApprovedQclubComponent,
    NewRequestsComponent,
    ExistingBusinessesComponent,
    BusinessesDetailsComponent,
    BusinessFiltersComponent,
    PremiumSponsorComponent,
  ],
  imports: [
    CommonModule,
    BusinessRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCDhjF3LNn2qqYUivCkiyYD8lQMAzihz7I',
    }),
  ],
  exports: [AssignApprovedQclubComponent],
  bootstrap: [BusinessComponent],
})
export class BusinessModule {}
