import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { USERTYPE } from '../core';
import { AuthGuard } from '../shared/guard/auth.guard';

import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { GuestUserGuard } from './guards/guest-user.guard';
import { VerifyAdminEmailComponent } from './verifications/verify-admin-email/verify-admin-email.component';

const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [GuestUserGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'create-new-password', component: NewPasswordComponent },
  {
    path: 'user/email-verification',
    component: VerifyAdminEmailComponent,
    canActivate: [GuestUserGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('../admin/admin.module').then((x) => x.AdminModule),
    canActivate: [AuthGuard],
    data: { basic_role: [USERTYPE.ADMIN] },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
