import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/admin/src/environments/environment';

// import { StorageService, TokenService, UserService } from '../../../../core';
import {
  Roles,
  RoleService,
  StorageService,
  TokenService,
  UserService,
} from '../../../../core';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  imageUrl = environment.imageUrl;
  isAdminUserCollapsed = true;
  isBusinessMenuCollapsed = true;
  isNotificationMenuCollapsed = true;
  isMemberMenuCollapsed = true;
  isManagerMenuCollapsed = true;
  isQponsMenuCollapsed = true;
  isQnewsCollapsed = true;

  username = '';
  userRole = '';
  userType = '';
  isAuthorized = false;
  isSuperAdmin = false;
  isQclubManager = false;
  isBusinessAcquisition = false; //
  hideMenu = false;
  wpUrl;
  constructor(
    private router: Router,
    private api: ApiService,
    private userService: UserService,
    private tokenService: TokenService,
    private roleService: RoleService,
    private storageService: StorageService
  ) {}
  ngOnInit() {
    this.wpUrl = environment.qclubWPUrl;
    if (this.tokenService.getUserName() && this.tokenService.getUserType) {
      this.username = this.tokenService.getUserName();
      this.userType = this.tokenService.getUserType(); // will remove this
      this.userRole = this.tokenService.getUserRole();
    }
    if (this.userRole) {
      if (this.userRole === Roles.SUPER_USER) {
        this.isSuperAdmin = true;
      } else if (this.userRole === Roles.Q_CLUB_MANAGER) {
        this.isQclubManager = true;
      } else if (this.userRole === Roles.BUSINESS_ACQUISITION) {
        this.isBusinessAcquisition = true;
      }
    }
    /*if (this.userRole) {
      if (this.userRole === 'SUPER_USER') {
        this.isAuthorized = true;
      } else {
        this.isAuthorized = false;
      }
    } else {
      this.isAuthorized = false;
    }*/
  }
}
