import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  imageUrl = environment.imageUrl;
  constructor() {}

  ngOnInit() {}
}
