import { environment } from 'projects/admin/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { BusinessService } from '../../../../../business/services/business.service';
@Component({
  selector: 'app-q-business',
  templateUrl: './q-business.component.html',
  styleUrls: ['./q-business.component.css'],
})
export class QBusinessComponent implements OnInit {
  imageUrl = environment.imageUrl;
  @Input() qClubId;
  @Input() qClubName;
  searchForm: FormGroup;
  qClubBusinessDetailsArray = [];
  searchText = '';
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  sortType = '';
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  constructor(
    private toast: ToastService,
    private businessService: BusinessService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private sorting: SortingService
  ) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });

    if (this.qClubId) {
      this.getAllQclubBusiness(this.qClubId, this.currentPage, this.searchText);
    }
  }

  searchFilter() {
    this.currentPage = 1;
    this.getAllQclubBusiness(
      this.qClubId,
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  getAllQclubBusiness(id: number, page, searchText) {
    const qclubParam = { qClubId: id, type: 'businesses' };
    this.businessService
      .getQclubBusinessDetailLists(qclubParam, page, searchText)
      .subscribe(
        (data) => {
          this.setDataResponse(data);
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.qClubBusinessDetailsArray = data.result;
      this.sortType = '';
      this.sorting.setData(this.qClubBusinessDetailsArray);
      this.disableNext = false;
    }
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getAllQclubBusiness(
      this.qClubId,
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.qClubBusinessDetailsArray = this.sorting.normalSort(
      this.qClubBusinessDetailsArray,
      field,
      this.storySort
    );
  }
}
