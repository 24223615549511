import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { LocationService } from '../../../../services/location.service';
import { AddCountryComponent } from '../../modals/add-country/add-country.component';
@Component({
  selector: 'app-qclub-location',
  templateUrl: './qclub-location.component.html',
  styleUrls: ['./qclub-location.component.css'],
})
export class QclubLocationComponent implements OnInit {
  constructor(
    private sorting: SortingService,
    private dialog: DialogService,
    private toast: ToastService,
    private location: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private commonService: CommonService
  ) {}

  imageUrl = environment.imageUrl;
  locations;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  defaultItems: [];
  items: any;
  toSort = false;
  searchText = '';
  values;
  sortType = '';
  breadcrumbArray = [];
  breadCrumbCurrentUrl;
  navigationValue = [];
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  ngOnInit() {
    // remove navigation value
    this.breadCrumbCurrentUrl = this.router.url;
    window.localStorage.removeItem('breadcrumb');
    this.getCountry(this.currentPage, this.searchText);
  }

  /**
   * open the add country modal
   * @param status location status
   * @param id location id
   */
  openModal(status, id) {
    this.dialog.dataModal(AddCountryComponent, { isEdit: status, id });
    this.dialog.modalReference.componentInstance.country.subscribe((data) => {
      if (data !== null) {
        if (data.isEdit === true) {
          this.toast.show(messages.countryUpdated, {
            classname: 'bg-success text-light',
          });
        } else {
          this.toast.show(messages.countryAdded, {
            classname: 'bg-success text-light',
          });
        }
        this.currentPage = 1;
        this.getCountry(this.currentPage, this.searchText);
      }
    });
  }

  // delete entry from db
  deleteRecord(id) {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to delete this country?',
      id,
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data && data.status) {
        if (data.status === true) {
          this.location.deleteCountry(id).subscribe(
            () => {
              // this.currentPage = 1;
              this.getCountry(this.currentPage, this.searchText);
              this.toast.show(messages.countryDeleted, {
                classname: 'bg-success text-light',
                delay: 5000,
              });
            },
            (error) => {
              this.toast.show(error.error.message, {
                classname: 'bg-danger text-light',
                delay: 5000,
              });
            }
          );
        } else {
        }
      }
    });
  }

  searchFilter(params) {
    this.searchText = params;
    this.getCountry(this.currentPage, this.searchText);
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getCountry(this.currentPage, null);
  }

  getCountry(pageNumber, params) {
    this.location.getCountries(pageNumber, params).subscribe(
      (data) => {
        this.setDataResponse(data);
      },
      (error) => {
        this.toast.show(error.error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.items = data.result;
      this.sortType = '';
      this.sorting.setData(this.items);
      this.disableNext = false;
    }
  }

  goTo(id) {
    this.router.navigateByUrl(`admin/locations/${id}/areas`);
  }

  /**
   * navigation for breadcrum
   * @param id url id
   * @param name name of the url
   */
  navigation(id, name) {
    const url = this.breadCrumbCurrentUrl + '/' + id + '/areas';
    const nav = { url, name };

    this.navigationValue.push(nav);
    window.localStorage.setItem(
      'breadcrumb',
      JSON.stringify(this.navigationValue)
    );
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.items = this.sorting.normalSort(this.items, field, this.storySort);
  }
}
