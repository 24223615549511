import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import { PaginationComponent } from 'projects/admin/src/app/shared/components/pagination/pagination.component';
import { AdminWithClubManagerGuard } from 'projects/admin/src/app/shared/guard/admin-clubmanager.guard';

import { ControlErrorComponent } from './components/control-error/control-error.component';
import { FooterSaveButtonComponent } from './components/footer-save-button/footer-save-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { QuestionModelComponent } from './components/modals/question-model/question-model.component';
import { ViewFeatureProductComponent } from './components/modals/view-feature-product/view-feature-product.component';
import { QuestionComponent } from './components/question/question.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';
import { CustomFilterPipe } from './pipes/custom-filter.pipe';
import { DateTimeZoneHour } from './pipes/dateTzHour.pipe';
import { QingoPatternPipe } from './pipes/qingo-pattern.pipe';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { TimezoneAbbrPipe } from './pipes/timezone-abbr.pipe';
import { TimezonePipe } from './pipes/timezone.pipe';
import { GameMetadataService } from './resolvers/game-metadata.service';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    FooterSaveButtonComponent,
    QuestionModelComponent,
    QuestionComponent,
    PaginationComponent,
    ControlErrorComponent,
    TimezonePipe,
    DateTimeZoneHour,
    CustomFilterPipe,
    TimezoneAbbrPipe,
    ConfirmModalComponent,
    TwoDigitDecimalNumberDirective,
    ViewFeatureProductComponent,
    OnlyNumberDirective,
    QingoPatternPipe,
    RemoveUnderscorePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    FooterSaveButtonComponent,
    QuestionModelComponent,
    QuestionComponent,
    PaginationComponent,
    ControlErrorComponent,
    TimezonePipe,
    DateTimeZoneHour,
    CustomFilterPipe,
    TimezoneAbbrPipe,
    ConfirmModalComponent,
    TwoDigitDecimalNumberDirective,
    OnlyNumberDirective,
    QingoPatternPipe,
    RemoveUnderscorePipe,
  ],
  entryComponents: [ViewFeatureProductComponent],
  providers: [
    AdminWithClubManagerGuard,
    TimezonePipe,
    DateTimeZoneHour,
    GameMetadataService,
    RemoveUnderscorePipe,
  ],
})
export class SharedModule {}
