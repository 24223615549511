/// <reference types="@types/googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { environment } from 'projects/admin/src/environments/environment';
import { of } from 'rxjs';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';

import { ToastService } from '../../../../../shared/services/toast.service';
import { QclubService } from '../../../services/qclub.service';
@Component({
  selector: 'app-edit-qclub',
  templateUrl: './edit-qclub.component.html',
  styleUrls: ['./edit-qclub.component.css'],
})
export class EditQclubComponent implements OnInit {
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private qclubService: QclubService
  ) {}

  /**
   * check the from
   */
  get f() {
    return this.editClubForm.controls;
  }

  /**
   * if any error on radius field
   */
  get invalidRadius() {
    return (
      this.editClubForm.get('radius').touched &&
      this.editClubForm.get('radius').errors &&
      this.editClubForm.get('radius').errors.required
    );
  }

  /**
   * if any error on qclub Manager
   */
  get invalidQclubManager() {
    return (
      this.editClubForm.get('managerId').touched &&
      this.editClubForm.get('managerId').errors &&
      this.editClubForm.get('managerId').errors.required
    );
  }

  imageUrl = environment.imageUrl;
  editClubForm: FormGroup;
  mapRadius = 0;
  @ViewChild('qclubSearchInputName', { static: true })
  qclubSearchInputName: ElementRef;
  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;
  formData: any;
  apiResponse: any;
  isSearching = false;
  isNameError = false;
  districtId: number;
  locationIdArray;
  errorMessage;
  zoom = 10;
  lat;
  lng;
  geoCoder;
  navigatorId;
  radius;
  roleId;
  manager = [];
  qclubId;
  nameValue;

  // fillForm details
  radiusSelected;

  ngOnInit() {
    // this.defaultResetLatLagSet();
    this.getRadius();
    this.editClubForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      radius: ['', Validators.required],
      managerId: ['', Validators.required],
      launchDate: ['', Validators.required],
      maxSlots: ['', Validators.required],
      maxMember: ['', [Validators.required, Validators.max(70000)]],
    });

    /**
     * Get the data from url
     */
    this.route.params.subscribe((data) => {
      if (data !== null) {
        this.districtId = data.districtId;
        this.qclubId = data.qclubId;
        this.locationIdArray = data;
      }
    });
    this.getQclubManagerList(this.locationIdArray.countryId, this.roleId);
    this.getQclubDetails(this.qclubId);
    // get the q club manager for currect loation

    /**
     * Check Qclub name on keyup
     */
    fromEvent(this.qclubSearchInputName.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          if (event.target.value === null) {
            this.isNameError = false;
          }
          return event.target.value;
        }),
        // if character length greater then 3
        filter((res) => res.length > 3),
        // Time in milliseconds between key events
        debounceTime(600),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((qclubName: string) => {
        if (qclubName) {
          this.isSearching = true;
          this.qclubService
            .checkQclubName(this.districtId, qclubName)
            .subscribe(
              (data) => {
                if (data.result) {
                  this.isNameError = false;
                } else {
                  if (this.nameValue === qclubName) {
                    this.isNameError = false;
                  } else {
                    this.errorMessage = messages.qclubNameCheckError;
                    this.isNameError = true;
                  }
                }
              },
              (error) => {
                this.toast.show(messages.errorMessage, {
                  classname: 'bg-danger text-light',
                  delay: 5000,
                });
              }
            );
        } else {
          return of([]);
        }
      });

    /**
     * load Places Autocomplet
     */
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.setComponentRestrictions({ country: ['CA'] });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom
          this.lat = parseFloat(place.geometry.location.lat().toFixed(6));
          this.lng = parseFloat(place.geometry.location.lng().toFixed(6));
          this.zoom = 12;
        });
      });
    });
  }

  getQclubDetails(id) {
    this.qclubService.getQclubDetails(id).subscribe(
      (data) => {
        this.fillForm(data.result);
        // this.toast.show(this.messages.qclubAdd, { classname: 'bg-success text-light', delay: 5000 });
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }
  fillForm(data) {
    const selectedManager = {
      id: data.clubManager[0].id,
      name: data.clubManager[0].name,
      email: data.clubManager[0].email,
    };
    const selectedlaunchDate = this.getDateDetails(data.launchDate);
    this.manager.push(selectedManager);
    this.lat = data.latitude;
    this.lng = data.longitude;
    this.editClubForm.get('name').setValue(data.name);
    this.editClubForm.get('address').setValue(data.address);
    this.editClubForm.get('radius').setValue(data.radius);
    this.editClubForm.get('managerId').setValue(data.clubManager[0].id);
    this.radiusSelected = data.radius;
    this.editClubForm.get('launchDate').setValue(selectedlaunchDate);
    this.editClubForm.get('maxSlots').setValue(data.maxSlots);
    this.nameValue = data.name;
    this.setRadius(data.radius);
    this.editClubForm.get('maxMember').setValue(data.maxMember);
  }

  getDateDetails(targetDate) {
    const dateObj = new Date(targetDate);
    return {
      year: dateObj.getFullYear(),
      month: dateObj.getMonth() + 1,
      day: dateObj.getDate(),
    };
  }
  /**
   * Get and set Current Location Coordinates from navigator on load
   */
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 10;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  /**
   * Get the addredd based on lat long
   * @param latitude latitude
   * @param longitude longitude
   */
  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 10;
            const address = results[0].formatted_address;
            this.editClubForm.get('address').setValue(address);
          } else {
            this.toast.show(messages.noResult, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        } else {
          this.toast.show(messages.geofailed + status, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      }
    );
  }

  /**
   *
   * @param event event
   */
  placeMarker(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lat;
  }

  /**
   *
   * @param radius radius
   */
  setRadius(radius) {
    this.mapRadius = parseFloat(radius) * 1000;
  }

  /**
   * Get All Radius List Form DB
   */
  getRadius() {
    this.qclubService.getMeta().subscribe(
      (data) => {
        this.radius = data.result.radius;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * Radius sort pipe
   */
  returnZero() {
    return 0;
  }

  /**
   *
   * @param lat lat
   * @param lng lng
   */
  markerDragEnd(lat, lng) {
    this.lat = parseFloat(lat.toFixed(6));
    this.lng = parseFloat(lng.toFixed(6));
    this.getAddress(this.lat, this.lng);
  }

  /**
   *
   */
  submitQclubForm() {
    for (const controller in this.editClubForm.controls) {
      if (this.editClubForm.controls.hasOwnProperty(controller)) {
        this.editClubForm.get(controller).markAsTouched();
      }
    }

    if (this.nameValue === this.editClubForm.value.name) {
      this.isNameError = false;
    }
    // check if qclub name already exist the prevent from submit
    if (this.isNameError) {
      return;
    }

    if (this.editClubForm.valid) {
      const date = this.dateFormate(this.editClubForm.value.launchDate);
      const arrayValue = {
        launchDate: date,
        managerId: this.editClubForm.value.managerId,
        maxSlots: this.editClubForm.value.maxSlots,
        radius: this.editClubForm.value.radius,
        maxMember: this.editClubForm.value.maxMember,
      };

      this.qclubService
        .editQclubDetails(this.districtId, this.qclubId, arrayValue)
        .subscribe(
          (data) => {
            this.toast.show(messages.qclubUpdate, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
    }
  }

  dateFormate(date) {
    return `${date.month < 10 ? '0' : ''}${date.month}/${
      date.day < 10 ? '0' : ''
    }${date.day}/${date.year}`;
  }

  /**
   *
   * @param countryId countryId
   * @param roleId roleId
   */
  getQclubManagerList(countryId, roleId) {
    this.qclubService.getQclubManagerList(countryId, roleId).subscribe(
      (data) => {
        this.manager = data.result;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * default Reset Lat Lag after qclub save
   */
  defaultResetLatLagSet() {
    if ('geolocation' in navigator) {
      this.navigatorId = navigator.geolocation.watchPosition((position) => {});
      navigator.geolocation.clearWatch(this.navigatorId);
      this.lat = 49.2888248;
      this.lng = -123.1111209;
      this.zoom = 10;
      this.getAddress(this.lat, this.lng);
    }
  }

  /**
   * Redirect the user to qclub listing after successfull adding the qclub
   */
  redirectToQclub() {
    this.router.navigateByUrl(
      `admin/locations/${this.locationIdArray.countryId}/areas/${this.locationIdArray.areaId}/provinces/${this.locationIdArray.provinceId}/area-districts/${this.locationIdArray.districtId}/q-clubs`
    );
  }

  backClicked() {
    this.router.navigateByUrl(
      `admin/locations/${this.locationIdArray.countryId}/areas/${this.locationIdArray.areaId}/provinces/${this.locationIdArray.provinceId}/area-districts/${this.locationIdArray.districtId}/q-clubs`
    );
  }
}
