import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from 'projects/admin/src/app/shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit, OnDestroy {
  hideMainFooter: boolean;
  subscriptions = new Subscription();
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.listenRouterEvents();
  }

  ngOnInit() {
    this.isShowFooter();
  }

  /**
   * @description listen router events
   */
  listenRouterEvents() {
    this.subscriptions.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.hideMainFooter = false;
        }
      })
    );
  }

  /**
   * @description subscribe the behaviour to show and hide the footer
   */
  isShowFooter() {
    this.subscriptions.add(
      this.sharedService.hideFooter.subscribe((hide) => {
        this.hideMainFooter = hide;
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
