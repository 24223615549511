import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import messages from '@core-module/constants/successMessages.json';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { LocationService } from '../../../../services/location.service';
@Component({
  selector: 'app-add-province',
  templateUrl: './add-province.component.html',
  styleUrls: ['./add-province.component.css'],
})
export class AddProvinceComponent implements OnInit {
  addStateForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private location: LocationService,
    private toast: ToastService
  ) {}
  @Output() stateevent: EventEmitter<any> = new EventEmitter();
  @Input() data;
  areaId: string;
  countryId;
  submitValue = 'Add';
  ngOnInit() {
    this.areaId = this.data.areaId;
    this.countryId = this.data.countryId;
    this.addStateForm = this.fb.group({
      name: ['', Validators.required],
      countryId: [this.countryId],
      countryAreaId: [this.areaId],
    });
    if (this.data.isEdit) {
      this.submitValue = 'Edit';
      this.getProvinceDetail();
    }
  }

  // get details to edit
  getProvinceDetail() {
    this.location
      .getProvinceDetail({
        areaId: this.data.areaId,
        provinceId: this.data.provinceId,
      })
      .subscribe((data) => {
        this.addStateForm.get('name').setValue(data.name);
      });
  }
  addState() {
    for (const controller in this.addStateForm.controls) {
      if (this.addStateForm.controls.hasOwnProperty(controller)) {
        this.addStateForm.get(controller).markAsTouched();
      }
    }

    if (this.addStateForm.valid) {
      if (this.data.isEdit) {
        const obj = {
          areaId: this.areaId,
          provinceId: this.data.provinceId,
          body: this.addStateForm.value,
        };
        this.location.editProvince(obj).subscribe(
          (data) => {
            this.dialog.close();
            this.toast.show(messages.provinceUpdated, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            this.stateevent.emit({
              name: this.addStateForm.get('name').value,
              isEdit: true,
            });
          },
          (error) => {
            this.toast.show(error.error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
      } else {
        this.location
          .addProvince(this.areaId, this.addStateForm.value)
          .subscribe(
            (data) => {
              this.dialog.close();
              this.stateevent.emit({
                name: this.addStateForm.get('name').value,
                isEdit: false,
              });
              this.toast.show(messages.provinceAdded, {
                classname: 'bg-success text-light',
                delay: 5000,
              });
            },
            (error) => {
              this.toast.show(error.error.error.message, {
                classname: 'bg-danger text-light',
                delay: 5000,
              });
            }
          );
      }
    }
  }

  closeModal() {
    this.dialog.close();
  }
  get f() {
    return this.addStateForm.controls;
  }
}
