import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';

import { BusinessService } from '../../../../admin/business/services/business.service';
import { USERTYPE } from '../../../constants/usertype.enum';
@Component({
  selector: 'app-question-model',
  templateUrl: './question-model.component.html',
  styleUrls: ['./question-model.component.css'],
})
export class QuestionModelComponent implements OnInit {
  @Output() question: EventEmitter<any> = new EventEmitter();
  @Input() data;
  submitValue = 'Add';
  userType;
  addQuestionForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private business: BusinessService
  ) {}
  ngOnInit() {
    this.addQuestionForm = this.fb.group({
      question: ['', Validators.required],
    });

    if (this.data.isEdit) {
      this.getQuestionDetails(this.data.id);
      this.submitValue = 'Edit';
    }
  }

  // get details to edit
  getQuestionDetails(id) {
    this.business.getQuestionDetails(id).subscribe((data) => {
      this.fillQuestionFormData(data);
    });
  }

  // fillForm details
  fillQuestionFormData(data) {
    if (this.data.userType === USERTYPE.BUSINESS) {
      this.addQuestionForm.get('question').setValue(data.businessQuestion);
    } else if (this.data.userType === USERTYPE.MEMBER) {
      this.addQuestionForm.get('question').setValue(data.memberQuestion);
    }
  }

  addQuestion() {
    for (const controller in this.addQuestionForm.controls) {
      if (this.addQuestionForm.controls.hasOwnProperty(controller)) {
        this.addQuestionForm.get(controller).markAsTouched();
      }
    }
    if (this.addQuestionForm.valid) {
      if (this.data.isEdit) {
        const formData = this.setFormDataToEdit();
        this.business.editQuestion(this.data.id, formData).subscribe(
          (data) => {
            this.closeModal();
            this.question.emit({ question: data, isEdit: true, error: false });
          },
          (error) => {
            this.question.emit({ question: '', isEdit: true, error });
          }
        );
      } else {
        const formData = this.setFormDataToAdd();
        this.business.addQuestions(formData).subscribe(
          (data) => {
            this.closeModal();
            this.question.emit({ question: data, isEdit: false, error: false });
          },
          (error) => {
            this.question.emit({ question: '', isEdit: true, error });
          }
        );
      }
    }
  }

  /**
   * set form data accordingly to edit a question
   */
  setFormDataToEdit() {
    let formData = {};
    if (this.data.userType === USERTYPE.BUSINESS) {
      formData = {
        businessQuestion: this.addQuestionForm.value.question,
      };
    } else if (this.data.userType === USERTYPE.MEMBER) {
      formData = {
        memberQuestion: this.addQuestionForm.value.question,
      };
    }
    return formData;
  }

  /**
   * set form data to add a new question
   */
  setFormDataToAdd() {
    let formData = {};
    if (this.data.userType === USERTYPE.BUSINESS) {
      formData = {
        businessQuestion: this.addQuestionForm.value.question,
        questionType: this.data.userType,
      };
    } else if (this.data.userType === USERTYPE.MEMBER) {
      formData = {
        memberQuestion: this.addQuestionForm.value.question,
        questionType: this.data.userType,
      };
    }
    return formData;
  }

  closeModal() {
    this.dialog.close();
  }

  get f() {
    return this.addQuestionForm.controls;
  }
}
