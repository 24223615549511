const DISPLAY_MESSAGES = {
  unablePreview: 'Unable to preview the file',
  approveNews: `<p class="fw-500">Do you really want to approve this story ?<p>`,
  approveDraw: `<p class="fw-500">Do you really want to approve this draw ?<p>`,
  approveQDream: `<p class="fw-500">Do you really want to approve this Q Dream ?<p>`,
  storyCreated: `Congratulations! New story created successfully.`,
  storyEdited: `Story updated successfully!`,
  drawCreated: `Congratulations! New draw created successfully.`,
  drawEdited: `Draw updated successfully!`,
  qdreamCreated: `Congratulations! New Q Dream created successfully.`,
  qdreamEdited: `Q Dream updated successfully.`,
  newsFileSizeError: `File memory size should be min. 10KB and max. 5MB`,
  newsFileDimensionError: `File dimensions should be upto 1200 X 400`,
  qDreamsFileSizeError: `File memory size should be min. 500KB and max. 100MB`,
  qNewsApproved: `Qnews Story approved Successfully !`,
  drawApproved: `Draw approved Successfully !`,
  qDreamApproved: `Q Dream approved Successfully !`,
  nfcAssigned: `NFC Assigned successfully !`,
  nfcUpdates: `NFC Assignment updated successfully !`,
  nfcDelete: `De-Activate NFC, Do you want to proceed`,
  nfcActivate: `Activate NFC, Do you want to proceed `,
  fileUploading: `Please Wait! File is uploading...`,
  imgDeleteWarning: `This will delete the image permanently.`,
  videoDeleteWarning: `This will delete the video permanently.`,
  areYouSure: `Are you Sure ? `,
  fileDeleted: `File Deleted Successfully`,
  fileUploadError: `Oops! Record Saved, File uploading Failed `,
  fileTypeError: `Only these file formats are allowed -`,
  qtvSupplierCreated: `New supplier created successfully`,
  qtvSupplierUpdated: `Supplier details updated successfully`,
  qtvCreated: `Congratulations! New QTV created successfully.`,
  qtvEdited: `QTV updated successfully.`,
  qtvSatusChanges: 'QTV status updated successfully',
  spinWheelCreated: 'Spin Wheel Spoke Created SuccessFully',
  statusChange: 'Do you really want to change the status ?',
  deleteConfirmation: 'Are you sure you want to delete this Record?',
  QingoCreated: 'Qingo Assigned Successfully !',
  QingoFailed: 'Oops! Qingo Assignment Failed, Try Again!',
  wacAMoleLevelCreated: 'New Wac A Mole  level draw created',
  monthlyAwardDelete: 'Monthly Award deleted Successfully !',
  wacAMolePositionPrizeCreated: 'New Wac A Mole monthly prize created',
  recordDeleted: 'Record successfully deleted',
  sponsorCreated: 'Sponsor created Successfully!',
  sponsorDeleted: 'Sponsor deleted Successfully!',
};

export default DISPLAY_MESSAGES;
