import { environment } from 'projects/admin/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenService } from 'projects/admin/src/app/core/index';
import { ViewQPonDetailsComponent } from '../../view-qpon-details/view-qpon-details.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import * as moment from 'moment';

import { BusinessService } from '../../../../../business/services/business.service';
import { QPonService } from '../../../../services/qpon.service';
@Component({
  selector: 'app-qpon-history',
  templateUrl: './qpon-history.component.html',
  styleUrls: ['./qpon-history.component.css'],
})
export class QPonHistoryComponent implements OnInit {
  imageUrl = environment.imageUrl;
  @Input()
  businessId = null;
  @Input() viewSearch: boolean;
  @Input() businessName;
  @Input() businessUniqueKey;
  @Input() qClubId;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  activeQPonArray: any;
  sortType = '';
  searchQPonByQBID: FormGroup;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  @Input() premium;
  params = { expired: true, qPonType: '', qClubId: '', qbid: '' };
  constructor(
    private toast: ToastService,
    private sorting: SortingService,
    public qPonService: QPonService,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    if (this.businessId) {
      this.getActiveBusinessQPonHistory(this.currentPage, this.businessId);
    } else {
      this.getActiveBusinessQPonHistoryForAdmin(this.currentPage);
    }
  }

  /**
   * get active qpons history for business based on business id and status
   * @param page this is using for pagination
   * @param businessId this is current business id
   */
   getActiveBusinessQPonHistory(page, businessId) {
    this.qPonService.getBusinessQPon(page, businessId, this.params).subscribe(
      (data) => {
        this.setDataResponse(data);
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * get Pending qpons for admin and other user based on the permission
   * @param page this is using for pagination
   */
  getActiveBusinessQPonHistoryForAdmin(page) {
    const onSuccess = (data) => {
      this.setDataResponse(data);
    };
    const onError = (error) => {
      this.toast.show(error.error.message, {
        classname: 'bg-danger text-light',
        delay: 5000,
      });
    };
    if (this.premium) {
      this.qPonService
        .getPremiumBusinessQPonListing(page, this.params)
        .subscribe(onSuccess, onError);
    } else {
      this.qPonService
        .getBusinessQPonListing(page, this.params)
        .subscribe(onSuccess, onError);
    }
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.activeQPonArray = data.result;
      this.sortType = '';
      this.sorting.setData(this.activeQPonArray);
      this.disableNext = false;
    }
  }

  /**
   * qpons details models
   * @param status this containt the qpons status
   * @param tabType boolen value
   * @param qPonDetailsArray contain the qpon array values
   */
  openQPonDetailsModel(status, tabType, qClubId, qPonDetailsArray) {
    this.dialog.dataModal(ViewQPonDetailsComponent, {
      isEdit: status,
      valueArray: qPonDetailsArray,
      tabType,
      qClubId,
      premium: this.premium,
    });
    this.dialog.modalReference.componentInstance.qPonValue.subscribe((data) => {
      if (data !== null) {
        // console.log(data);
      }
    });
  }

  /**
   * Filter record based on the user selected value
   * @param value contain the filter values
   */
  filterData(value) {
    if (value) {
      this.params = {
        expired: true,
        qPonType: value.qPonType,
        qClubId: value.qClubId,
        qbid: value.qbid,
      };

      this.qPonService
        .getFilterDataListing(this.currentPage, this.params, this.premium)
        .subscribe(
          (data) => {
            this.setDataResponse(data);
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
    } else {
      this.getActiveBusinessQPonHistoryForAdmin(this.currentPage);
    }
  }

  /**
   * Changer the page on click event
   * @param $event page number
   */
  clickedPage($event) {
    this.currentPage = $event;
    if (this.businessId) {
      this.getActiveBusinessQPonHistory(this.currentPage, this.businessId);
    } else {
      this.getActiveBusinessQPonHistoryForAdmin(this.currentPage);
    }
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.activeQPonArray = this.sorting.normalSort(
      this.activeQPonArray,
      field,
      this.storySort
    );
  }
  convertDateToUTC(date) {
    return moment(new Date(date)).utc().format('DD-MM-YYYY');
  }
}
