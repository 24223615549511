import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.css'],
})
export class ComposeComponent implements OnInit {
  imageUrl = environment.imageUrl;
  constructor() {}

  ngOnInit() {}
}
