/// <reference types="@types/googlemaps" />

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'projects/admin/src/environments/environment';

import { TokenService } from '../../../../../core';

@Component({
  selector: 'app-acquisition-list',
  templateUrl: './acquisition-list.component.html',
  styleUrls: ['./acquisition-list.component.css'],
})
export class AcquisitionListComponent implements OnInit {
  registered = false;
  imageUrl = environment.imageUrl;
  @Output() search = new EventEmitter();
  constructor(private tokenService: TokenService, private fb: FormBuilder) {}
  countryId;
  uniqueKey;
  searchText = '';
  searchExistingBusinessForm: FormGroup;
  searchTextValue;
  ngOnInit() {
    this.countryId = this.tokenService.getUserCountry();
    this.uniqueKey = this.tokenService.getUserUniqueKey();
    this.searchExistingBusinessForm = this.fb.group({
      searchTerm: [''],
    });
  }

  searchExistingForm() {
    this.search.emit(this.searchExistingBusinessForm.get('searchTerm').value);
  }
}
