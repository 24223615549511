import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ComposeComponent } from './components/notification/compose/compose.component';
import { InboxComponent } from './components/notification/inbox/inbox.component';
import { SentComponent } from './components/notification/sent/sent.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      { path: '', redirectTo: 'business', pathMatch: 'full' },
      { path: 'inbox', component: InboxComponent },
      { path: 'compose', component: ComposeComponent },
      { path: 'sent', component: SentComponent },
      {
        path: 'locations',
        loadChildren: () =>
          import('./location/location.module').then(
            (x) => x.LocationModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((x) => x.UsersModule),
      },
      {
        path: 'business',
        loadChildren: () =>
          import('./business/business.module').then(
            (x) => x.BusinessModule
          ),
      },
      {
        path: 'members',
        loadChildren: () =>
          import('./member/member.module').then((x) => x.MemberModule),
      },
      {
        path: 'manager',
        loadChildren: () =>
          import('./manager/manager.module').then(
            (x) => x.ManagerModule
          ),
      },
      {
        path: 'q-pons',
        loadChildren: () =>
          import('./q-pons/q-pons.module').then((x) => x.QPonsModule),
      },
      {
        path: 'business-acquisition',
        loadChildren: () =>
          import(
            './business-acquisition/business-acquisition.module'
          ).then((x) => x.BusinessAcquisitionModule),
      },
      {
        path: 'news',
        loadChildren: () =>
          import('../feature-modules/qnews/qnews.module').then(
            (x) => x.QnewsModule
          ),
      },
      {
        path: 'draws',
        loadChildren: () =>
          import('../feature-modules/instant-draws/instant-draws.module').then(
            (x) => x.InstantDrawsModule
          ),
      },
      {
        path: 'dreams',
        loadChildren: () =>
          import('../feature-modules/qdreams/qdreams.module').then(
            (x) => x.QdreamsModule
          ),
      },

      {
        path: 'spin-wheel',
        loadChildren: () =>
          import('../feature-modules/spin-wheel/spin-wheel.module').then(
            (x) => x.SpinWheelModule
          ),
      },

      {
        path: 'nfc',
        loadChildren: () =>
          import('../feature-modules/nfc/nfc.module').then((x) => x.NFCModule),
      },
      {
        path: 'referral-coupons',
        loadChildren: () =>
          import('./referral-coupons/referral-coupons.module').then(
            (x) => x.ReferralCouponsModule
          ),
      },
      {
        path: 'no-permission',
        loadChildren: () =>
          import('./no-permission/no-permission.module').then(
            (x) => x.NoPermissionModule
          ),
      },
      {
        path: 'qtv',
        loadChildren: () =>
          import('../feature-modules/qtv/qtv.module').then((x) => x.QtvModule),
      },
      {
        path: 'reward-draws',
        loadChildren: () =>
          import('../feature-modules/game-draws/game-draws.module').then(
            (x) => x.GameDrawsModule
          ),
      },
      {
        path: 'qingo',
        loadChildren: () =>
          import('../feature-modules/qingo/qingo.module').then(
            (x) => x.QingoModule
          ),
      },
      {
        path: 'wac-a-mole-level',
        loadChildren: () =>
          import(
            '../feature-modules/wac-a-moles/wac-a-mole-level/wac-a-mole-level.module'
          ).then((x) => x.WacAMoleLevelModule),
      },
      {
        path: 'wac-a-mole-ranking',
        loadChildren: () =>
          import(
            '../feature-modules/wac-a-moles/wac-a-mole-ranking/wac-a-mole-ranking.module'
          ).then((x) => x.WacAMoleRankingModule),
      },
      {
        path: 'sponsor',
        loadChildren: () =>
          import('../feature-modules/sponsor/sponsor.module').then(
            (x) => x.SponsorModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
