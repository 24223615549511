import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { GuestUserGuard } from './guards/guest-user.guard';
import { VerifyAdminEmailComponent } from './verifications/verify-admin-email/verify-admin-email.component';

@NgModule({
  declarations: [
    LoginComponent,
    VerifyAdminEmailComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, ReactiveFormsModule, FormsModule],
  providers: [GuestUserGuard],
})
export class AuthModule {}
