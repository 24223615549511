import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminWithClubManagerGuard } from 'projects/admin/src/app/shared/guard/admin-clubmanager.guard';

import { ManagerDetailsComponent } from './components/manager-details/manager-details.component';
import { ManagerComponent } from './components/manager/manager.component';
const routes: Routes = [
  {
    path: '',
    component: ManagerComponent,
    children: [
      {
        path: 'manager-details',
        component: ManagerDetailsComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagerRoutingModule {}
