import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-business-filters',
  templateUrl: './business-filters.component.html',
  styleUrls: ['./business-filters.component.css'],
})
export class BusinessFiltersComponent implements OnInit {
  filterForm: FormGroup;
  @Output() filtered = new EventEmitter();
  qClubsList = [];
  isPremium = false;
  constructor(private fb: FormBuilder, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.isPremium = this.activeRoute.snapshot.url[1].path === 'premium';
    this.initForm();
    if (!this.isPremium) {
      this.getResolverData();
    }
  }

  initForm() {
    this.filterForm = this.fb.group({
      status: [''],
      qClubId: [''],
    });
  }

  /**
   * get data from resolver - qclub list data
   */
  getResolverData() {
    this.qClubsList = this.activeRoute.snapshot.data.qclubsData.result;
  }

  /**
   * emit event when user filters data
   */
  searchBusinessFilter() {
    if (
      this.filterForm.get('status').value ||
      this.filterForm.get('qClubId').value
    ) {
      this.filtered.emit(this.filterForm.value);
    }
  }

  resetFilter() {
    this.filterForm.reset();
    this.filterForm.get('status').setValue('');
    this.filtered.emit({ clear: true });
    // this.clear.emit();
    //  console.log('AA');
  }
}
