import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'projects/admin/src/app/core';
import { environment } from 'projects/admin/src/environments/environment';

import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnInit {
  imageUrl = environment.imageUrl;
  @Input() totalRecords: number;
  @Output() clickedPage = new EventEmitter();
  @Output() pageSize = new EventEmitter();

  pageNumbers: any;
  activePage = 0;
  pages: number;
  recordsPerPage = 10;
  checkLength;
  constructor(private api: ApiService, private commonService: CommonService) {}

  ngOnInit() {
    this.commonService.pageArray.subscribe((data) => {
      this.checkLength = data ? data.length : 0;
    });
    this.api.currentPage.subscribe((page) => {
      this.activePage = page;
    });
    this.api.isSearch.subscribe((data) => {
      if (data === 'searched') {
        this.api.paginator.subscribe((item) => {
          this.pages = Math.ceil(item / this.recordsPerPage);
          const prev = {
            linkTo: this.activePage - 1,
            text: '',
          };
          const next = {
            linkTo: this.activePage + 1,
            text: '',
          };
          let allPageNumbers = [];
          if (this.pages <= 5) {
            for (let i = 1; i <= this.pages; i++) {
              allPageNumbers.push({
                linkTo: i,
                text: i,
              });
            }
            this.pageNumbers = [{}, ...allPageNumbers, {}];
          } else {
            for (let i = this.activePage; i <= this.activePage + 4; i++) {
              if (this.activePage <= this.pages - 5 && this.activePage === 1) {
                allPageNumbers.push({
                  linkTo: i,
                  text: i,
                });
              } else if (
                this.activePage <= this.pages - 5 &&
                this.activePage === 2
              ) {
                allPageNumbers.push({
                  linkTo: i - 1,
                  text: i - 1,
                });
              } else if (
                this.activePage <= this.pages - 5 &&
                this.activePage > 2
              ) {
                allPageNumbers.push({
                  linkTo: i - 2,
                  text: i - 2,
                });
              } else {
                allPageNumbers = [];

                for (let iVal = this.pages - 4; iVal <= this.pages; iVal++) {
                  allPageNumbers.push({
                    linkTo: iVal,
                    text: iVal,
                  });
                }
                break;
              }
            }
            this.pageNumbers = [{}, ...allPageNumbers, {}];
          }
        });
      } else {
        this.api.paginator.subscribe((item) => {
          if (item === 0) {
            item = 10;
          }
          this.pages = Math.ceil(item / this.recordsPerPage);
          const prev = {
            linkTo: this.activePage - 1,
            text: '',
          };
          const next = {
            linkTo: this.activePage + 1,
            text: '',
          };
          const allPageNumbers = [];
          // if (this.pages <= 5) {
          //   for (let i = 1; i <= this.pages; i++) {
          //     allPageNumbers.push({
          //       linkTo: i,
          //       text: i
          //     });
          //   }
          //   this.pageNumbers = [{}, ...allPageNumbers, {}];

          // }
          // else {
          //   for (let i = this.activePage; i <= this.activePage + 4; i++) {
          //     if (this.activePage <= this.pages - 5 && this.activePage == 1) {
          //       allPageNumbers.push({
          //         linkTo: i,
          //         text: i
          //       });
          //     }
          //     else if (this.activePage <= this.pages - 5 && this.activePage == 2) {
          //       allPageNumbers.push({
          //         linkTo: i - 1,
          //         text: i - 1
          //       });

          //     } else if (this.activePage <= this.pages - 5 && this.activePage > 2) {
          //       allPageNumbers.push({
          //         linkTo: i - 2,
          //         text: i - 2
          //       });
          //     }
          //     else {
          //       allPageNumbers = [];

          //       for (let i = this.pages - 4; i <= this.pages; i++) {
          //         if (this.activePage === this.pages - 4) {
          //           allPageNumbers.push({
          //             linkTo: i - 2,
          //             text: i - 2
          //           });
          //         } else if (this.activePage === this.pages - 3) {
          //           console.log(this.pages)
          //           allPageNumbers.push({
          //             linkTo: i - 1,
          //             text: i - 1
          //           });
          //         }
          //         else {
          //           allPageNumbers.push({
          //             linkTo: i,
          //             text: i
          //           });
          //         }
          //       } break;
          //     }
          //   }
          //   this.pageNumbers = [{}, ...allPageNumbers, {}];
          // }
        });
      }
    });
  }

  setRows(rows) {
    this.recordsPerPage = rows;
    this.pageSize.emit(this.recordsPerPage);
  }

  goTo(page) {
    this.activePage = page.linkTo < 0 ? 0 : page.linkTo;
    // this.activePage = page.linkTo > this.pages ? this.pages : this.activePage;
    this.clickedPage.emit(this.activePage);
  }
}
