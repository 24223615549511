import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { Roles, RoleService, TokenService, UserService } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  userType;
  userToken;
  constructor(
    private router: Router,
    private userService: UserService,
    private tokenService: TokenService,
    private roleService: RoleService
  ) {}

  // Check if user authorised for this route
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userType = this.roleService.getUserType();
    this.userToken = this.tokenService.getToken();
    if (this.userToken) {
      if (this.userType !== Roles.ADMIN) {
        // role not authorised so delete session and  redirect to home page
        this.userService.unAuthorizedUser();
        this.router.navigate(['/']);
        return false;
      }
      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

  canLoad(route: Route): boolean {
    return true;
  }
}
