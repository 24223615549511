import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { BusinessService } from '../../../admin/business/services/business.service';
import { QuestionModelComponent } from '../modals/question-model/question-model.component';
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
})
export class QuestionComponent implements OnInit, OnDestroy {
  questionsListing;
  userType;
  public USERTYPE = require('../../constants/usertype.enum').USERTYPE;
  arrayIndex;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  userTypeValue;
  subfunction;
  constructor(
    private route: ActivatedRoute,
    private dialog: DialogService,
    private toast: ToastService,
    private businessService: BusinessService
  ) {}

  ngOnInit() {
    this.subfunction = this.route.data.subscribe(
      (v) => (this.userTypeValue = v.data)
    );
    this.userType = this.userTypeValue;
    this.getQuestions(this.currentPage, this.userType);
  }

  ngOnDestroy() {
    this.subfunction.unsubscribe();
  }

  /**
   * open a modal to add/edit question
   * @param status status to check if to add or edit a question
   * @param id unique id of question
   */
  openQuestionModel(status, id) {
    this.dialog.dataModal(QuestionModelComponent, {
      isEdit: status,
      id,
      userType: this.userType,
    });
    this.dialog.modalReference.componentInstance.question.subscribe((data) => {
      if (data !== null) {
        if (data.isEdit === true) {
          if (!data.error) {
            this.getQuestions(this.currentPage, this.userType);
            this.toast.show(messages.questionUpdate, {
              classname: 'bg-success text-light',
            });
          } else {
            this.toast.show(messages.errorMessage, {
              classname: 'bg-danger text-light',
            });
          }
        } else {
          if (!data.error) {
            this.getQuestions(this.currentPage, this.userType);
            this.toast.show(messages.questionAdd, {
              classname: 'bg-success text-light',
            });
          } else {
            this.toast.show(messages.errorMessage, {
              classname: 'bg-danger text-light',
            });
          }
        }
      }
    });
  }

  /**
   * to get questions list of admin
   * @param page current page number
   * @param userType type of user
   */
  getQuestions(page, userType) {
    this.businessService.getQuestionsList(page, userType).subscribe(
      (data) => {
        this.setDataResponse(data);
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.questionsListing = data;
      this.disableNext = false;
    }
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getQuestions(this.currentPage, this.userType);
  }
}
