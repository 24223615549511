import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { QponsListComponent } from './components/manage-qpons/qpons-list/qpons-list.component';
import { QponsComponent } from './components/qpons/qpons.component';
import { ActiveQPonComponent } from './components/shared/qpon-division/active-qpon/active-qpon.component';
import { PendingQPonComponent } from './components/shared/qpon-division/pending-qpon/pending-qpon.component';
import { QPonHistoryComponent } from './components/shared/qpon-division/qpon-history/qpon-history.component';
import { QPonsFiltersComponent } from './components/shared/qpons-filters/qpons-filters.component';
import { QPonsRoutingModule } from './q-pons-routing.module';

@NgModule({
  declarations: [
    QponsComponent,
    QponsListComponent,
    ActiveQPonComponent,
    PendingQPonComponent,
    QPonHistoryComponent,
    QPonsFiltersComponent,
  ],
  imports: [
    CommonModule,
    QPonsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModule,
  ],
  exports: [
    ActiveQPonComponent,
    PendingQPonComponent,
    QPonHistoryComponent,
    QPonsFiltersComponent,
  ],
  bootstrap: [QponsComponent],
})
export class QPonsModule {}
