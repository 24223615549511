import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class WindowScrollService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  scrollEnable() {
    this.document.body.classList.add('bodyScroll');
  }

  scrollDisable() {
    this.document.body.classList.remove('bodyScroll');
  }
}
