import { Injectable, Injector } from '@angular/core';
import { TokenService } from 'projects/admin/src/app/core/services/token.service';
import { UserService } from 'projects/admin/src/app/core/services/user.service';
import { take } from 'rxjs/operators';

@Injectable()
export class InitializeService {
  constructor(private tokenService: TokenService, private injector: Injector) {}

  /**
   * @description method is used to get loggedin user data on page reload when the app is initialized
   */
  async fetchLoggedInUserData() {
    if (!this.tokenService.isUserLoggedIn()) {
      // if user is not loggedin the return false
      return false;
    } else {
      const userData = await this.injector
        .get(UserService)
        .currentUser.pipe(take(1))
        .toPromise();
      if (!Object.keys(userData).length) {
        const userId = this.tokenService.getUserId();
        return this.injector
          .get(UserService)
          .getLoggedInUserDetails(Number(userId))
          .toPromise();
      }
    }
  }
}
