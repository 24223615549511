import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { CommonService, TokenService, RoleService } from 'projects/admin/src/app/core/index';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { BusinessService } from '../../services/business.service';
@Component({
  selector: 'app-existing-businesses',
  templateUrl: './existing-businesses.component.html',
  styleUrls: ['./existing-businesses.component.css'],
})
export class ExistingBusinessesComponent implements OnInit {
  imageUrl = environment.imageUrl;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  sortType = '';
  searchText = '';
  searchExistingBusinessForm: FormGroup;
  existingBusinessListing = [];
  filterCollapsed = true;
  countryId;
  qClubsList = [];
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  isPremium = false;
  filters: any;
  isSuperAdmin = false;
  constructor(
    private tokenService: TokenService,
    private fb: FormBuilder,
    private toast: ToastService,
    private businessService: BusinessService,
    private apiService: ApiService,
    private sorting: SortingService,
    private activeRoute: ActivatedRoute,
    private roleService: RoleService
  ) {}
  ngOnInit() {
    // to check whether business route is for premium or local businesses
    this.isPremium =
      this.activeRoute.snapshot.url[1].path === OTHER_CONST.PREMIUM;
    // will remove after implementing the subject
    this.filterData({});
    this.searchExistingBusinessForm = this.fb.group({
      searchTerm: [''],
    });
    if (this.tokenService.getToken()) {
      if (this.roleService.hasSuperAdminRole()) {
        this.isSuperAdmin = true;
      }
    }
  }

  /**
   * get Existing Business List
   * @param page page number
   * @param searchText text for search
   */
  getExistingBusiness(page, searchText, filters?) {
    if (!filters) {
      filters = { status: ['ACTIVE', 'DEACTIVATED'] };
    }
    this.businessService
      .getBusinessListing(page, searchText, filters, this.isPremium)
      .subscribe(
        (data) => {
          this.setDataResponse(data);
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.existingBusinessListing = data.result;
      this.sortType = '';
      this.sorting.setData(this.existingBusinessListing);
      this.disableNext = false;
    }
  }

  /**
   * Mark Business Active Inactive
   * @param event event type
   * @param business business
   */
  markBusinessActiveInactive(event, business) {
    let status;
    if (event.target.checked) {
      status = 'ACTIVE';
    } else {
      status = 'DEACTIVATED';
    }
    const params = { status };
    this.businessService.activeDeactiveBusiness(business.id, params).subscribe(
      (data) => {
        this.toast.show(messages.activeDeactiveBusiness, {
          classname: 'bg-success text-light',
          delay: 5000,
        });
      },
      (error) => {
        business.status = status;
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  /**
   * search Business details by name/email
   */
  searchExistingForm() {
    this.currentPage = 1;
    this.getExistingBusiness(
      this.currentPage,
      this.searchExistingBusinessForm.get('searchTerm').value
    );
  }

  /**
   * change the page on click event
   * @param $event event
   */
  clickedPage($event) {
    this.currentPage = $event;
    this.getExistingBusiness(
      this.currentPage,
      this.searchExistingBusinessForm.get('searchTerm').value,
      this.filters
    );
  }

  /**
   * Filter record based on the user selected value
   * @param value value
   */
  filterData(value) {
    value.status =
      !value.status || value.status === ''
        ? ['ACTIVE', 'DEACTIVATED']
        : value.status;
    this.filters = value;
    if (value.clear === true) {
      this.filterCollapsed = true;
      value = { status: ['ACTIVE', 'DEACTIVATED'] };
      this.filters = value;
      this.currentPage = 1;
    }
    this.getExistingBusiness(this.currentPage, this.searchText, value);
  }

  /**
   * @description sort records
   * @param field sort by field
   */
  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.existingBusinessListing = this.sorting.normalBusinessSort(
      this.existingBusinessListing,
      field,
      this.storySort
    );
  }

  exportToCsv() {
    if (!this.filters) {
      this.filters = { status: ['ACTIVE', 'DEACTIVATED'] };
    }
    this.businessService
      .exportBusinessListing(
        1,
        this.searchExistingBusinessForm.value.searchTerm,
        this.filters
      )
      .subscribe(
        (data) => {
          const uri = 'data:text/csv;charset=utf-8,' + data.result;
          const downloadLink = document.createElement('a');
          downloadLink.href = uri;
          downloadLink.download = 'records.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }
}
