import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { LocationService } from '../../../../services/location.service';
import { AddProvinceComponent } from '../../modals/add-province/add-province.component';
@Component({
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.css'],
})
export class ProvinceComponent implements OnInit {
  constructor(
    private dialog: DialogService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toast: ToastService,
    private sorting: SortingService,
    private commonService: CommonService
  ) {}
  imageUrl = environment.imageUrl;
  provinces;
  areaId;
  values;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  items: any;
  countryId;
  searchText = '';
  sortType = '';
  toSort = false;
  countryName;
  areaName;
  breadCrumbCurrentUrl;
  nameValue;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  ngOnInit() {
    this.breadCrumbCurrentUrl = this.router.url;
    this.countryId = parseInt(
      this.route.snapshot.paramMap.get('countryId'),
      10
    );
    this.areaId = parseInt(this.route.snapshot.paramMap.get('areaId'), 10);
    this.getProvinces(this.areaId, this.currentPage, this.searchText);
    this.setName();
  }

  /*onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }*/
  openModal(status, id) {
    this.dialog.dataModal(AddProvinceComponent, {
      isEdit: status,
      countryId: this.countryId,
      areaId: this.areaId,
      provinceId: id,
    });
    this.dialog.modalReference.componentInstance.stateevent.subscribe(
      (data) => {
        if (data !== null) {
          this.currentPage = 1;
          this.getProvinces(this.areaId, this.currentPage, this.searchText);
        }
      }
    );
  }

  // delete entry from db
  deleteRecord(id) {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to delete this Province?',
      id,
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data && data.status) {
        if (data.status === true) {
          this.locationService
            .deleteProvince({ areaId: this.areaId, provinceId: id })
            .subscribe(
              () => {
                this.getProvinces(
                  this.areaId,
                  this.currentPage,
                  this.searchText
                );
                this.toast.show(messages.provinceDeleted, {
                  classname: 'bg-success text-light',
                  delay: 5000,
                });
              },
              (error) => {
                this.toast.show(error.error.message, {
                  classname: 'bg-danger text-light',
                  delay: 5000,
                });
              }
            );
        } else {
        }
      }
    });
  }

  searchFilter(params) {
    this.searchText = params;
    this.getProvinces(this.areaId, this.currentPage, this.searchText);
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getProvinces(this.areaId, this.currentPage, this.searchText);
  }

  getProvinces(areaId, page, searchText) {
    this.locationService
      .getProvinces(this.countryId, areaId, page, searchText)
      .subscribe(
        (data) => {
          this.setDataResponse(data);
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.items = data.result;
      this.sortType = '';
      this.sorting.setData(this.items);
      this.disableNext = false;
    }
  }

  backClicked() {
    this.router.navigateByUrl(`/admin/locations/${this.countryId}/areas`);
  }

  setName() {
    const nameArray = JSON.parse(localStorage.getItem('breadcrumb'));
    this.nameValue = nameArray[1].name;
  }

  navigation(id, name) {
    const v = JSON.parse(localStorage.getItem('breadcrumb'));
    const url = this.breadCrumbCurrentUrl + '/' + id + '/area-districts';
    v.push({ url, name });
    localStorage.setItem('breadcrumb', JSON.stringify(v));
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.items = this.sorting.normalSort(this.items, field, this.storySort);
  }
}
