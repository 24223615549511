import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';

import { Roles, TokenService, UserService } from '../../../../core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  imageUrl = environment.imageUrl;
  username = '';
  userType = '';
  userRole = '';
  isAuthorized = false;
  userDetails;
  constructor(
    private userService: UserService,
    private tokenService: TokenService
  ) {}

  ngOnInit() {
    if (this.tokenService.getUserName() && this.tokenService.getUserType) {
      this.username = this.tokenService.getUserName();
      this.userType = this.tokenService.getUserType();
      this.userDetails = this.tokenService.getUser();
      if (this.userDetails.userRoles) {
        this.userRole = this.userDetails.userRoles[0].role.description;
      }
    }

    if (this.userRole) {
      if (this.userRole === Roles.SUPER_USER) {
        this.isAuthorized = true;
      } else {
        this.isAuthorized = false;
      }
    } else {
      this.isAuthorized = false;
    }
  }

  logout() {
    this.userService.logout();
  }
}
