import { environment } from 'projects/admin/src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { QPonService } from '../../../services/qpon.service';
@Component({
  selector: 'app-qpons-filters',
  templateUrl: './qpons-filters.component.html',
  styleUrls: ['./qpons-filters.component.css'],
})
export class QPonsFiltersComponent implements OnInit {
  imageUrl = environment.imageUrl;
  filterForm: FormGroup;
  qClubListing;
  @Output() filtered = new EventEmitter();
  @Output() clear = new EventEmitter();
  premium = false;
  constructor(
    private fb: FormBuilder,
    private qPonService: QPonService,
    private toast: ToastService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.premium = this.router.snapshot.url[0].path === 'premium';
    this.initForm();
    this.getAllQClub();
  }

  initForm() {
    this.filterForm = this.fb.group({
      qClubId: [''],
      qPonType: [''],
      qbid: [''],
      premium: this.premium,
    });
  }

  qPonsFilter() {
    if (this.filterForm.valid) {
      this.filtered.emit(this.filterForm.value);
    }
  }

  resetFilter() {
    this.filterForm.reset();
    this.filterForm.get('qClubId').setValue('');
    this.filterForm.get('qPonType').setValue('');
    this.filterForm.get('qbid').setValue('');
    this.filtered.emit(this.filterForm.value);
  }

  getAllQClub() {
    this.qPonService.getQclub().subscribe(
      (data) => {
        this.qClubListing = data.result;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }
}
