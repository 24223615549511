import { environment } from "projects/admin/src/environments/environment";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import messages from "@core-module/constants/successMessages.json";
import { CommonService, TokenService, RoleService } from "projects/admin/src/app/core/index";
import OTHER_CONST from "projects/admin/src/app/shared/constants/other.const";
import { ApiService } from "projects/admin/src/app/shared/services/api.service";
import { DialogService } from "projects/admin/src/app/shared/services/dialog.service";
import { SortingService } from "projects/admin/src/app/shared/services/sorting.service";
import { ToastService } from "projects/admin/src/app/shared/services/toast.service";

import { BusinessService } from "../../services/business.service";

import { AssignApprovedQclubComponent } from "./assign-approved-qclub/assign-approved-qclub.component";

import { PremiumSponsorComponent } from "./premium-sponsor/premium-sponsor.component";
@Component({
  selector: "app-new-requests",
  templateUrl: "./new-requests.component.html",
  styleUrls: ["./new-requests.component.css"],
})
export class NewRequestsComponent implements OnInit {
  imageUrl = environment.imageUrl;
  pendingBusinessListing = [];
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  searchText = "";
  searchForm: FormGroup;
  sortType = "";
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  isSuperAdmin = false;
  constructor(
    private tokenService: TokenService,
    private sorting: SortingService,
    private fb: FormBuilder,
    private toast: ToastService,
    private businessService: BusinessService,
    private apiService: ApiService,
    private dialog: DialogService,
    private commonService: CommonService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.getPendingBusiness(this.currentPage, this.searchText);
    this.searchForm = this.fb.group({
      searchTerm: [""],
    });

    if (this.tokenService.getToken()) {
      if (this.roleService.hasSuperAdminRole()) {
        this.isSuperAdmin = true;
      }
    }
  }

  /**
   * Get the pending business listing
   * @param page page
   * @param status status
   */
  getPendingBusiness(page, searchText) {
    const param = { status: "PENDING" };
    this.businessService.getBusinessListing(page, searchText, param).subscribe(
      (data) => {
        this.setDataResponse(data);
        /*if (data && data.result.length) {
          this.pendingBusinessListing = data.result;
          this.sortType = '';
          this.sorting.setData(this.pendingBusinessListing);
          this.commonService.pageArray.next(this.pendingBusinessListing);
          this.apiService.currentPage.next(this.currentPage);
        } else {
          if (this.currentPage === 0) {
            if (this.searchForm.value) {
              this.pendingBusinessListing = [];
            }
          } else {
            this.currentPage = this.currentPage - 1;
            this.apiService.currentPage.next(this.currentPage);
          }
          this.commonService.pageArray.next(this.pendingBusinessListing);
        }*/
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: "bg-danger text-light",
          delay: 5000,
        });
      }
    );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.pendingBusinessListing = data.result;
      this.sortType = "";
      this.sorting.setData(this.pendingBusinessListing);
      this.disableNext = false;
    }
  }

  /**
   * search Business details by name/email
   */
  searchBusiness() {
    this.currentPage = 1;
    this.getPendingBusiness(
      this.currentPage,
      this.searchForm.get("searchTerm").value
    );
  }

  /**
   * Approved and assign qclun to business
   * @param id id
   * @param businessIndexArray business index array
   */
  assignApprovedQclubModel(id, businessIndexArray) {
    this.dialog.dataModal(AssignApprovedQclubComponent, {
      id,
      business: businessIndexArray,
    });
    this.dialog.modalReference.componentInstance.approvedAssign.subscribe(
      (data) => {
        if (data !== null) {
          this.getPendingBusiness(this.currentPage, this.searchText);
          this.toast.show(messages.approvedBusiness, {
            classname: "bg-success text-light",
            delay: 5000,
          });
        }
      }
    );
  }

  /**
   * Changer the page on click event
   * @param $event event
   */
  clickedPage($event) {
    this.currentPage = $event;
    this.getPendingBusiness(
      this.currentPage,
      this.searchForm.get("searchTerm").value
    );
  }

  /**
   * resend the email verification mail to user
   * @param userId userId
   */
  sendEmailVerificationLink(userId: number) {
    this.businessService.sendEmailVerificationLink(userId).subscribe(
      (data) => {
        // wil remove this code
        this.getPendingBusiness(this.currentPage, this.searchText);
        this.toast.show(messages.businessVerificationLink, {
          classname: "bg-success text-light",
          delay: 5000,
        });
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: "bg-danger text-light",
          delay: 5000,
        });
      }
    );
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.pendingBusinessListing = this.sorting.normalBusinessSort(
      this.pendingBusinessListing,
      field,
      this.storySort
    );
  }

  /**
   * Set premium sponsorship to business
   * @param businessProfileId id
   */
  assignSponsorShip(businessProfileId) {
    this.dialog.dataModal(PremiumSponsorComponent, {
      businessProfileId,
    });
    this.dialog.modalReference.componentInstance.sponsorAssigned.subscribe(
      (data) => {
        if (data !== null) {
          this.getPendingBusiness(this.currentPage, this.searchText);
          this.toast.show(messages.approvedSponsorShip, {
            classname: "bg-success text-light",
            delay: 5000,
          });
        }
      }
    );
  }
  exportToCsv() {
    const filters = { status: ["PENDING"] };

    this.businessService
      .exportBusinessListing(1, this.searchForm.value.searchTerm, filters)
      .subscribe(
        (data) => {
          const uri = "data:text/csv;charset=utf-8," + data.result;
          const downloadLink = document.createElement("a");
          downloadLink.href = uri;
          downloadLink.download = "records.csv";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: "bg-danger text-light",
            delay: 5000,
          });
        }
      );
  }
}
