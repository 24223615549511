import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, RoleService } from 'projects/admin/src/app/core/index';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { QclubService } from '../../../../services/qclub.service';
@Component({
  selector: 'app-q-clubs',
  templateUrl: './q-clubs.component.html',
  styleUrls: ['./q-clubs.component.css'],
})
export class QClubsComponent implements OnInit {
  constructor(
    private sorting: SortingService,
    private apiService: ApiService,
    private toast: ToastService,
    private qclubService: QclubService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private roleService: RoleService
  ) {}
  imageUrl = environment.imageUrl;
  districtId: number;
  areaId;
  countryId;
  provinceID;
  qclubs;
  locationData;
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  values;
  searchText = '';
  sortType = '';
  toSort = false;
  qclubListing = [];
  searchForm: FormGroup;
  nameValue;
  adminRole;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  ngOnInit() {
    this.adminRole = this.roleService.hasSuperAdminRole();
    this.route.params.subscribe((data) => {
      if (data !== null) {
        this.districtId = data.districtId;
        this.areaId = data.areaId;
        this.countryId = data.countryId;
        this.provinceID = data.provinceId;
        this.locationData = data;
      }
    });

    this.getQclubListing(this.districtId, this.currentPage, this.searchText);
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  searchFilter() {
    this.currentPage = 1;
    this.getQclubListing(
      this.districtId,
      this.currentPage,
      this.searchForm.get('searchTerm').value
    );
  }

  getQclubListing(districtId, pageNumber, searchText) {
    this.qclubService
      .getQclubList(districtId, pageNumber, searchText)
      .subscribe(
        (data) => {
          this.setDataResponse(data);
        },
        (error) => {
          this.toast.show(error.error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.qclubListing = data.result;
      this.sortType = '';
      this.sorting.setData(this.qclubListing);
      this.disableNext = false;
    }
  }

  // pagination
  clickedPage($event) {
    this.currentPage = $event;
    this.getQclubListing(this.districtId, this.currentPage, this.searchText);
  }

  goToAddQClubLink() {
    this.router.navigateByUrl(
      `admin/locations/${this.locationData.countryId}/areas/${this.locationData.areaId}/provinces/${this.locationData.provinceId}/area-districts/${this.locationData.districtId}/q-clubs/add-new-club`
    );
  }

  gotoEditDetails(qclubId) {
    // console.log(qclubId);
    this.router.navigateByUrl(
      `admin/locations/${this.locationData.countryId}/areas/${this.locationData.areaId}/provinces/${this.locationData.provinceId}/area-districts/${this.locationData.districtId}/q-clubs/${qclubId}/edit-qclub`
    );
  }

  backClicked() {
    this.router.navigateByUrl(
      `/admin/locations/${this.locationData.countryId}/areas/${this.locationData.areaId}/provinces/${this.locationData.provinceId}/area-districts`
    );
  }

  setName() {
    const nameArray = JSON.parse(localStorage.getItem('breadcrumb'));
    this.nameValue = nameArray[3].name;
  }

  navigation(id, name) {
    const v = JSON.parse(localStorage.getItem('breadcrumb'));
    const url = '/admin/locations/qclubs/' + id + '/qclub-detail'; // 'qclubs/' + id + '/qclub-detail';
    v.push({ url, name });
    localStorage.setItem('breadcrumb', JSON.stringify(v));
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.qclubListing = this.sorting.normalSort(
      this.qclubListing,
      field,
      this.storySort
    );
  }
}
