import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenService } from 'projects/admin/src/app/core/index';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';

import { BusinessService } from '../../../../business/services/business.service';
@Component({
  selector: 'app-question-model',
  templateUrl: './question-model.component.html',
  styleUrls: ['./question-model.component.css'],
})
export class QuestionModelComponent implements OnInit {
  @Output() question: EventEmitter<any> = new EventEmitter();
  @Input() data;
  submitValue = 'Add';
  userType;
  addQuestionForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private business: BusinessService,
    private tokenService: TokenService
  ) {}
  ngOnInit() {
    // this.userType = 'MEMBER';//this.tokenService.getUserType();
    this.addQuestionForm = this.fb.group({
      question: ['', Validators.required],
    });

    if (this.data.isEdit) {
      this.getQuestionDetails(this.data.id);
      this.submitValue = 'Edit';
    }
  }

  // get details to edit
  getQuestionDetails(id) {
    this.business.getQuestionDetails(id).subscribe((data) => {
      this.fillQuestionFormData(data);
    });
  }

  // fillForm details
  fillQuestionFormData(data) {
    this.addQuestionForm.get('question').setValue(data.questionText);
  }

  addQuestion() {
    for (const controller in this.addQuestionForm.controls) {
      if (this.addQuestionForm.controls.hasOwnProperty(controller)) {
        this.addQuestionForm.get(controller).markAsTouched();
      }
    }
    if (this.addQuestionForm.valid) {
      if (this.data.isEdit) {
        const formData = { questionText: this.addQuestionForm.value.question };
        this.business.editQuestion(this.data.id, formData).subscribe(
          (data) => {
            this.closeModal();
            this.question.emit({ question: data, isEdit: true, error: false });
          },
          (error) => {
            this.question.emit({ question: '', isEdit: true, error });
          }
        );
      } else {
        const formData = {
          questionText: this.addQuestionForm.value.question,
          questionType: this.data.userType,
        };
        this.business.addQuestions(formData).subscribe(
          (data) => {
            this.closeModal();
            this.question.emit({ question: data, isEdit: false, error: false });
          },
          (error) => {
            this.question.emit({ question: '', isEdit: true, error });
          }
        );
      }
    }
  }

  closeModal() {
    this.dialog.close();
  }

  get f() {
    return this.addQuestionForm.controls;
  }
}
