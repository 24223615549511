import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() isHide: boolean;
  imageUrl = environment.imageUrl;
  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (change.isHide) {
      this.isHide = change.isHide.currentValue;
    }
  }

  ngOnInit() {}
}
