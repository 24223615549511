import { Component, OnInit } from '@angular/core';

import { LoaderService } from './loader.service';

@Component({
  selector: 'app-qclub-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  constructor(public loaderService: LoaderService) {}

  ngOnInit() {}

  get loaderEnabled() {
    return this.loaderService.loaderEnabled;
  }
}
