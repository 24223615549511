import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'projects/admin/src/app/core/guards/admin.guard';

import { ManageReferralCouponsComponent } from './components/manage/manage-referral-coupons/manage-referral-coupons.component';
import { ReferralCouponComponent } from './components/referral-coupon/referral-coupon.component';

const routes: Routes = [
  {
    path: '',
    component: ReferralCouponComponent,
    children: [
      {
        path: 'manage-referral-coupons',
        component: ManageReferralCouponsComponent,
        canActivate: [AdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReferralCouponsRoutingModule {}
