import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';

import { QclubService } from '../../../../services/qclub.service';
@Component({
  selector: 'app-change-qclub-manager',
  templateUrl: './change-qclub-manager.component.html',
  styleUrls: ['./change-qclub-manager.component.css'],
})
export class ChangeQclubManagerComponent implements OnInit {
  @Output() qclubManagerChange: EventEmitter<any> = new EventEmitter();
  @Input() data;
  countryId: number;

  qclubManagerChangeForm: FormGroup;
  qclubManagerDetails = [];
  managerList;
  roleId;

  constructor(
    private toast: ToastService,
    private qclubService: QclubService,
    private dialog: DialogService,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.qclubManagerChangeForm = this.fb.group({
      managerId: ['', Validators.required],
    });
    if (this.data.countryId) {
      this.countryId = this.data.countryId;
      this.getQclubManagerByCountryId(this.countryId, this.roleId);
    }
  }

  getQclubManagerByCountryId(countryId, roleId) {
    this.qclubService.getQclubManagerList(countryId, roleId).subscribe(
      (data) => {
        this.managerList = data.result;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }

  submitForm() {
    for (const controller in this.qclubManagerChangeForm.controls) {
      if (this.qclubManagerChangeForm.controls.hasOwnProperty(controller)) {
        this.qclubManagerChangeForm.get(controller).markAsTouched();
      }
    }

    if (this.qclubManagerChangeForm.valid) {
      const date = this.dateFormate(this.data.Qclub.launchDate);
      const param = {
        launchDate: date,
        maxSlots: this.data.Qclub.maxSlots,
        radius: this.data.Qclub.radius,
        managerId: this.qclubManagerChangeForm.value.managerId,
      };

      this.qclubService
        .editQclubDetails(this.data.Qclub.districtId, this.data.Qclub.id, param)
        .subscribe(
          (data) => {
            this.toast.show('Qclub Manager updated successfully', {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            this.closeModal();
            this.qclubManagerChange.emit({ data: this.data, error: false });
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
    }
  }

  dateFormate(dateVal) {
    const dateObj = new Date(dateVal);
    return `${dateObj.getMonth() < 10 ? '0' : ''}${dateObj.getMonth() + 1}/${
      dateObj.getDate() < 10 ? '0' : ''
    }${dateObj.getDate()}/${dateObj.getFullYear()}`;
  }

  closeModal() {
    this.dialog.close();
  }

  /**
   * check the from
   */
  get f() {
    return this.qclubManagerChangeForm.controls;
  }

  /**
   * if any error on qclub Manager
   */
  get invalidQclubManager() {
    return (
      this.qclubManagerChangeForm.get('managerId').touched &&
      this.qclubManagerChangeForm.get('managerId').errors &&
      this.qclubManagerChangeForm.get('managerId').errors.required
    );
  }
}
