import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoleService, UserService } from 'projects/admin/src/app/core/services';
import { Roles } from 'projects/admin/src/app/shared/constants/roles.enum';
import { SharedService } from 'projects/admin/src/app/shared/services/shared.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { environment } from 'projects/admin/src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  imageUrl = environment.imageUrl;
  loginForm: FormGroup;
  object;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private userService: UserService,
    private sharedService: SharedService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9][-a-zA-Z0-9._]+@([- a-z0-9]+[.])+[a-z]{2,5}$'
          ),
        ],
      ],
      password: ['', [Validators.required]],
      loginType: ['admin'],
    });
  }

  /**
   * @description method called on login
   */
  login() {
    // check form group is valid
    if (this.loginForm.invalid) {
      this.sharedService.markFormGroupTouched(this.loginForm);
      return false;
    }
    this.userService.login(this.loginForm.value).subscribe(
      (result) => {
        // get an api call to store loggedin user permissions and roles
        this.getUserInfo(result.userId);
      },
      (error) => {
        // this.toast.showError(error.message);
      }
    );
  }

  /**
   * @description make an api call to get user roles and permissions
   * @param userId loggedIn user id
   */
  getUserInfo(userId) {
    this.userService.getLoggedInUserDetails(userId).subscribe(
      (data) => {
        this.navigateUser(data);
      },
      (error) => {}
    );
  }

  /**
   * @description navigate user according to the permissions and role
   * @param userData user data
   */
  navigateUser(userData) {
    const userRole = userData.userRoles[0].role;
    if (userRole.name === Roles.SUPER_USER || userRole.name === Roles.ADMIN) {
      this.router.navigateByUrl('admin/business/new-requests');
    } else if (userRole.name === Roles.Q_CLUB_MANAGER) {
      const url = this.roleService.areaRedirect();
      this.router.navigateByUrl(url);
    } else if (userRole.name === Roles.BUSINESS_ACQUISITION) {
      this.router.navigateByUrl('admin/business-acquisition/acquisition-list');
    }
  }

  get f() {
    return this.loginForm.controls;
  }
}
