import { environment } from 'projects/admin/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { ApiEndPoints } from 'projects/admin/src/app/core/constants/api-endpoints.constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';

@Component({
  selector: 'app-view-feature-product',
  templateUrl: './view-feature-product.component.html',
  styleUrls: ['./view-feature-product.component.css'],
})
export class ViewFeatureProductComponent implements OnInit {
  @Input() product;
  apiEndPoints = ApiEndPoints;
  qDreamStatus = OTHER_CONST.qnewsStatus;
  filePath = '';
  isImage = false;
  drawHour = OTHER_CONST.qDreamDrawHours;
  qtvStatus = OTHER_CONST.qtvStatus;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.product.asset) {
      const imageAsset: any = this.product.asset;
      if (imageAsset.type === 'IMAGE') {
        this.isImage = true;
        this.filePath = `${environment.S3ImageURL}${imageAsset.path}${imageAsset.name}_250x250.png`;
      }else{
        this.filePath = `${environment.S3ImageURL}${imageAsset.path}${imageAsset.name}.${imageAsset.extension}`;
      }
    }
  }

  /**
   * @description close the modal
   */
  close() {
    this.activeModal.dismiss();
  }
}
