import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ICONS_CONST from 'projects/admin/src/app/shared/constants/icons';
import DISPLAY_MESSAGE from 'projects/admin/src/app/shared/constants/message';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { SwalAlertService } from 'projects/admin/src/app/shared/services/swal-alert.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { MemberService } from '../../services/member.service';

@Component({
  selector: 'app-q-members',
  templateUrl: './q-members.component.html',
  styleUrls: ['./q-members.component.css'],
})
export class QMembersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchTerm', { static: true }) searchByText: ElementRef;
  filterForm: FormGroup;
  activeTab = 'clubMembers';
  activePage = 1;
  totalRecordsCount = 0;
  qClubsList: any = [];
  qclubfilterParam = '';
  memberRecords: any = [];
  memberStatuses = OTHER_CONST.membershipStatus;
  otherConstants = OTHER_CONST;
  sortFields = OTHER_CONST.qNewsSortFields;
  statusConst = OTHER_CONST.commonStatus;
  searchSubscription = new Subscription();
  memberSort = OTHER_CONST.sortOrder.desc;
  disableNext = false;
  memberStatus = [];
  filterFields = {
    qClubId: '',
    membershipStatus: '',
  };

  tabConfig = {
    clubMembers: {
      active: true,
      text: 'Club Members',
      page: 1,
      type: null,
    },
    remoteMembers: {
      active: false,
      text: 'Remote Members',
      page: 1,
      type: 'remote',
    },
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private memberService: MemberService,
    private fb: FormBuilder,
    private swalAlert: SwalAlertService,
    private toastService: ToastService,
    private sorting: SortingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group(this.filterFields);
    this.setActiveTabFromParam();
    // get qclubs from resolver data
    this.qClubsList = this.activeRoute.snapshot.data.qclubsData.result;
    this.tabChange(this.activeTab);
  }

  /**
   * @description filter status according to club member and remote members
   */

  filterStatuses() {
    this.memberStatus = [];
    Object.keys(OTHER_CONST.membershipStatus).map((item) => {
      if (OTHER_CONST.membershipStatus[item].type === 'remote') {
        if (this.tabConfig[this.activeTab].type === 'remote') {
          this.memberStatus.push(OTHER_CONST.membershipStatus[item]);
        }
      } else {
        if (this.tabConfig[this.activeTab].type !== 'remote') {
          this.memberStatus.push(OTHER_CONST.membershipStatus[item]);
        }
      }
    });
  }

  /**
   * @description get q members list
   */
  getQMembers(callByPage?) {
    const params = this.getParams();
    this.memberService.getMembersListing(params).subscribe((res) => {
      if (res.result.length === 0 && callByPage) {
        // set disable next manually when there is no records to prevent next page click
        // this is required because the total count are not coming from backend services to pre make pagination
        this.disableNext = true;
        this.activePage = --this.activePage; // set previous page again
      } else {
        this.memberRecords = res.result;
        this.disableNext = false;
      }
    });
  }

  /**
   * @description tab change event call
   * @param key active tab key
   */
  tabChange(key) {
    this.router.navigate([], {
      queryParams: { selectedTab: key },
      relativeTo: this.activeRoute,
      replaceUrl: true,
    });
    this.activeTab = key;
    this.filterForm.get('qClubId').enable();
    if (this.activeTab === 'remoteMembers') {
      this.filterForm.get('qClubId').disable();
    }
    this.resetFilter();
    this.activePage = this.tabConfig[this.activeTab].page;
    this.filterStatuses();
    this.getQMembers();
  }

  /**
   * @description return params
   */
  getParams() {
    return {
      page: this.activePage,
      searchTerm: this.searchByText.nativeElement
        ? this.searchByText.nativeElement.value
        : '',
      order: 'desc',
      type: this.tabConfig[this.activeTab].type,
      ...this.filterForm.value,
    };
  }

  ngAfterViewInit() {
    this.listenSearching();
  }

  /**
   * @description page change event called
   * @param event page change
   */
  pageChange(event) {
    this.activePage = event;
    this.tabConfig[this.activeTab].page = event;
    this.getQMembers(true);
  }

  /** reset filter */
  resetFilter() {
    this.filterForm.patchValue(this.filterFields);
    this.getQMembers();
  }

  /**
   * @description filetr records by qclub
   */
  filterByQclub() {
    this.activePage = 1;
    this.getQMembers();
  }

  /**
   * @description Observable subscribe for search term and listen search on keyup
   */
  listenSearching() {
    const term$ = fromEvent<any>(this.searchByText.nativeElement, 'keyup').pipe(
      map((event) => event.target.value),
      debounceTime(500),
      distinctUntilChanged()
    );

    // subscribe the above observable
    this.searchSubscription = term$.subscribe((criterion) => {
      this.activePage = 1;
      this.getQMembers();
    });
  }

  /**
   * @description active/inactive qmember
   * @param record record to modify
   */
  async changeStatus(record) {
    const displayMsg = `${DISPLAY_MESSAGE.statusChange} to ${
      record.uStatus === 'DEACTIVATED'
        ? this.statusConst.active.text
        : this.statusConst.inactive.text
    }
    <p>(${record.uniqueKey})</p>`;
    const confirmConfig: any = {
      containerClass: 'swal-confirm-container',
      content: displayMsg,
      confirmBtnText: `${ICONS_CONST.thumbsUp} OK`,
      confirmButtonAriaLabel: 'OK',
      cancelBtnText: `${ICONS_CONST.thumbsDown} Cancel`,
      cancelButtonAriaLabel: 'Cancel',
      confirmBtnColor: '#28a745',
      cancelBtnColor: '#dc3545',
      confirmBtnValue: true,
      cancelBtnValue: false,
      showCancel: true,
    };

    const isResult = await this.swalAlert.chooseOptions(confirmConfig);
    if (isResult) {
      const changeTo =
        record.uStatus === 'DEACTIVATED'
          ? this.statusConst.active.status
          : this.statusConst.inactive.status;
      this.memberService
        .activeDeactiveMembers(record.uId, { status: changeTo })
        .subscribe((res) => {
          record.uStatus = changeTo;
          this.toastService.showSuccess('Status Changed Successfully !');
        });
    }
  }

  /**
   * @description sort records
   * @param field sort by field
   */
  sortRecords(field, byDate?) {
    this.memberSort =
      this.memberSort === OTHER_CONST.sortOrder.desc
        ? OTHER_CONST.sortOrder.asc
        : OTHER_CONST.sortOrder.desc;
    this.memberRecords = byDate
      ? this.sorting.sortByDate(this.memberRecords, field, this.memberSort)
      : this.sorting.normalSort(this.memberRecords, field, this.memberSort);
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }
  /**
   * @description set tab from query param
   */
  setActiveTabFromParam() {
    const selectedTabFromParam = this.activeRoute.snapshot.queryParams
      .selectedTab;
    if (Object.keys(this.tabConfig).includes(selectedTabFromParam)) {
      this.activeTab = selectedTabFromParam;
    }
  }
}
