import { Injectable } from '@angular/core';
import { ApiEndPoints } from 'projects/admin/src/app/core/constants';
import { ApiService } from 'projects/admin/src/app/core/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GameMetadataService {
  constructor(private apiService: ApiService) {}
  resolve() {
    const apiPath = `${ApiEndPoints.gamesMetaData}`;
    return this.apiService.get(apiPath).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
