import { environment } from 'projects/admin/src/environments/environment';
export enum ApiEndPoints {
  login = 'users/login?include=user',
  getAllUser = 'users?access_token=',
  'users' = 'users',
  signout = 'users/logout',
  businessProfiles = 'business_profiles',
  approvedPedingBusines = 'users/',
  sendEmailVerification = 'admins/resend-verification/',
  businessProfilesListing = '/businesses/listing',
  countries = 'countries',
  country_area = 'country_areas',
  provinces = 'provinces',
  adminCountry = 'admins/countries/',
  adminArea = 'admins/countryAreas/',
  adminDistrict = 'admins/districts/',
  adminProvince = 'admins/provinces/',
  getBusinessDetails = 'admins/businesses/',
  questions = 'signup_questions/',
  addAdminUser = 'admins/add-admin',
  adminUserListing = 'admins/users',
  adminUserDetails = 'users',
  getRoles = 'admins/roles',
  admin = 'admins/',
  checkQclubName = '/check-club-name?searchTerm=',
  addQclub = '/add',
  updateQclub = '/update',
  qclubs = '/qClubs/',
  qclubListing = '/qClubs',
  qclubManagersListing = '/managers',
  adminCountries = 'admins/countries/',
  meta = 'publics/meta-data',
  qClubDetails = 'admins/qclubs/',
  qClubBusinessDetails = 'admins/qclub/', // discussed with amar for change the url param
  managerQclub = 'admins/managers/',
  acquisitionBusinessListing = '/acquisition/businesses',
  businessQPonListing = '/qPonListing',
  adminQpons = 'admins/qpons/',
  adminQponsListing = 'admins/qpons',
  adminQponsApproveReject = '/approve',
  awsFilePath = 'https://d3iptk7nqtz7oi.cloudfront.net',
  getQclubList = 'admins/qclubs',
  referralCoupons = 'admins/referrals/',
  referralCouponsCreate = 'admins/referrals/create',
  adminForgotPassword = '/users/request-password-reset',
  adminResetPassword = '/users/reset-password',
  nfcDetails = 'admins/nfc',
  createSpinWheelType = 'spin-wheels/meta-data',
  submitSpinWheel = 'spin-wheels',
  spinWheelsListing = '/spin-wheels',
  spinWheel = 'spin-wheel',
  gameDraw = 'admins/draws',
  gamesMetaData = 'admins/games/meta-data',
  wacAMoleLevel = 'admins/wacamole-level',
  wacAMolePosition = 'admins/wacamole-position-prize',
  premium = 'premium',
  sponsor = 'sponsor',
}
