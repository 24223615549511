import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { RoleService } from 'projects/admin/src/app/core/services/role.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NoPermissionGuard implements CanActivate {
  constructor(private roleService: RoleService, private router: Router) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const areaPermission = this.roleService.getAreaPermission();
    if (areaPermission && areaPermission.length) {
      return true;
    } else {
      this.router.navigate(['/admin/no-permission']);
      return false;
    }
  }
}
