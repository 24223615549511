/// <reference types="@types/googlemaps" />
import { environment } from "projects/admin/src/environments/environment";
import { MapsAPILoader } from "@agm/core";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import messages from "@core-module/constants/successMessages.json";
import {
  NgbCalendar,
  NgbDatepicker,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "projects/admin/src/app/shared/services/toast.service";
import { parse } from "querystring";
import { of } from "rxjs";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";

import { QclubService } from "../../../services/qclub.service";
@Component({
  selector: "app-add-qclub",
  templateUrl: "./add-qclub.component.html",
  styleUrls: ["./add-qclub.component.css"],
})
export class AddQclubComponent implements OnInit {
  imageUrl = environment.imageUrl;
  addClubForm: FormGroup;
  mapRadius = 0;
  @ViewChild("qclubSearchInputName", { static: true })
  qclubSearchInputName: ElementRef;
  @ViewChild("search", { static: true }) public searchElementRef: ElementRef;
  formData: any;
  apiResponse: any;
  isSearching = false;
  isNameError = false;
  districtId: number;
  locationIdArray;
  errorMessage;
  zoom = 10;
  lat = 49.288824;
  lng = -123.11112;
  geoCoder;
  navigatorId;
  radius;
  roleId;
  manager;
  searchAddress;
  modelSelected: NgbDateStruct;
  date: { year: number; month: number };
  minDate;
  @ViewChild("d", { static: true }) d: NgbDatepicker;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private qclubService: QclubService,
    private calendar: NgbCalendar
  ) {}

  ngOnInit() {
    this.minDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };
    this.getRadius();
    this.addClubForm = this.fb.group({
      name: ["", Validators.required],
      address: ["", Validators.required],
      radius: ["", Validators.required],
      managerId: ["", Validators.required],
      launchDate: ["", Validators.required],
      maxSlots: ["", Validators.required],
      maxMember: ["", [Validators.required, Validators.max(70000)]],
    });

    /**
     * Get the data from url
     */
    this.route.params.subscribe((data) => {
      if (data !== null) {
        this.districtId = data.districtId;
        this.locationIdArray = data;
      }
    });

    // get the q club manager for currect loation
    this.getQclubManagerList(this.locationIdArray.countryId, this.roleId);

    /**
     * Check Qclub name on keyup
     */
    fromEvent(this.qclubSearchInputName.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          if (event.target.value === null) {
            this.isNameError = false;
          }
          return event.target.value;
        }),
        // if character length greater then 3
        filter((res) => res.length > 3),
        // Time in milliseconds between key events
        debounceTime(600),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((qclubName: string) => {
        if (qclubName) {
          this.isSearching = true;
          this.qclubService
            .checkQclubName(this.districtId, qclubName)
            .subscribe(
              (data) => {
                if (data.result) {
                  this.isNameError = false;
                } else {
                  this.errorMessage = messages.qclubNameCheckError;
                  this.isNameError = true;
                }
              },
              (error) => {
                this.toast.show(messages.errorMessage, {
                  classname: "bg-danger text-light",
                  delay: 5000,
                });
              }
            );
        } else {
          return of([]);
        }
      });

    /**
     * load Places Autocomplet
     */
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.setComponentRestrictions({ country: ["CA"] });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.lat = parseFloat(place.geometry.location.lat().toFixed(6));
          this.lng = parseFloat(place.geometry.location.lng().toFixed(6));
          this.zoom = 12;
          this.getAddress(this.lat, this.lng);
        });
      });
    });
  }

  /**
   * Get and set Current Location Coordinates from navigator on load
   */
  setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 10;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  /**
   * Get the addredd based on lat long
   * @param latitude latitude
   * @param longitude longitude
   */
  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 10;
            const address = results[0].formatted_address;
            this.addClubForm.get("address").setValue(address);
          } else {
            this.toast.show(messages.noResult, {
              classname: "bg-danger text-light",
              delay: 5000,
            });
          }
        } else {
          this.toast.show(messages.geofailed + status, {
            classname: "bg-danger text-light",
            delay: 5000,
          });
        }
      }
    );
  }

  /**
   *
   * @param event event
   */
  placeMarker(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lat;
  }

  /**
   *
   * @param radius radius
   */
  setRadius(radius) {
    this.mapRadius = parseFloat(radius) * 1000;
  }

  /**
   * Get All Radius List Form DB
   */
  getRadius() {
    this.qclubService.getMeta().subscribe(
      (data) => {
        this.radius = data.result.radius;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: "bg-danger text-light",
          delay: 5000,
        });
      }
    );
  }

  /**
   * Radius sort pipe
   */
  returnZero() {
    return 0;
  }

  /**
   *
   * @param lat lat
   * @param lng lng
   */
  markerDragEnd(lat, lng) {
    this.lat = parseFloat(lat.toFixed(6));
    this.lng = parseFloat(lng.toFixed(6));
    this.getAddress(this.lat, this.lng);
  }

  /**
   *
   */
  submitQclubForm() {
    for (const controller in this.addClubForm.controls) {
      if (this.addClubForm.controls.hasOwnProperty(controller)) {
        this.addClubForm.get(controller).markAsTouched();
      }
    }

    // check if qclub name already exist the prevent from submit
    if (this.isNameError) {
      return;
    }

    if (this.addClubForm.valid) {
      this.addClubForm.value.latitude = this.lat;
      this.addClubForm.value.longitude = this.lng;
      this.addClubForm.value.launchDate = this.dateFormate(
        this.addClubForm.value.launchDate
      );

      this.qclubService
        .addQclubDetails(this.districtId, this.addClubForm.value)
        .subscribe(
          (data) => {
            this.defaultResetLatLagSet();
            this.redirectToQclub();
            this.toast.show(messages.qclubAdd, {
              classname: "bg-success text-light",
              delay: 5000,
            });
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: "bg-danger text-light",
              delay: 5000,
            });
          }
        );
    }
  }

  dateFormate(date) {
    return `${date.month < 10 ? "0" : ""}${date.month}/${
      date.day < 10 ? "0" : ""
    }${date.day}/${date.year}`;
  }

  /**
   *
   * @param countryId country id
   * @param roleId role id
   */
  getQclubManagerList(countryId, roleId) {
    this.qclubService.getQclubManagerList(countryId, roleId).subscribe(
      (data) => {
        this.manager = data.result;
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: "bg-danger text-light",
          delay: 5000,
        });
      }
    );
  }

  /**
   * default Reset Lat Lag after qclub save
   */
  defaultResetLatLagSet() {
    if ("geolocation" in navigator) {
      this.navigatorId = navigator.geolocation.watchPosition((position) => {});
      navigator.geolocation.clearWatch(this.navigatorId);
      this.lat = 49.2888248;
      this.lng = -123.1111209;
      this.zoom = 10;
      this.getAddress(this.lat, this.lng);
    }
  }

  /**
   * Redirect the user to qclub listing after successfull adding the qclub
   */
  redirectToQclub() {
    this.router.navigateByUrl(
      `admin/locations/${this.locationIdArray.countryId}/areas/${this.locationIdArray.areaId}/provinces/${this.locationIdArray.provinceId}/area-districts/${this.locationIdArray.districtId}/q-clubs`
    );
  }

  /**
   * check the from
   */
  get f() {
    return this.addClubForm.controls;
  }

  /**
   * if any error on radius field
   */
  get invalidRadius() {
    return (
      this.addClubForm.get("radius").touched &&
      this.addClubForm.get("radius").errors &&
      this.addClubForm.get("radius").errors.required
    );
  }

  /**
   * if any error on qclub Manager
   */
  get invalidQclubManager() {
    return (
      this.addClubForm.get("managerId").touched &&
      this.addClubForm.get("managerId").errors &&
      this.addClubForm.get("managerId").errors.required
    );
  }

  backClicked() {
    this.router.navigateByUrl(
      `admin/locations/${this.locationIdArray.countryId}/areas/${this.locationIdArray.areaId}/provinces/${this.locationIdArray.provinceId}/area-districts/${this.locationIdArray.districtId}/q-clubs`
    );
  }
  // check for only number entered
  checkForMaxNumber(event) {
    const charCode = event.query ? event.query : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }
}
