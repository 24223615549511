import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'projects/admin/src/environments/environment';
import { Subscription } from 'rxjs';

// import { TokenService } from '../../../../../../core';
import {
  Roles,
  RoleService,
  TokenService,
  User,
  UserService,
} from '../../../../../../core';
import { ApiService } from '../../../../../shared/services/api.service';
@Component({
  selector: 'app-qlocation-breadcrumb',
  templateUrl: './qlocation-breadcrumb.component.html',
  styleUrls: ['./qlocation-breadcrumb.component.css'],
})
export class QLocationBreadcrumbComponent implements OnInit {
  imageUrl = environment.imageUrl;
  @Input() BreadcrumbArray;
  @Input() areaId;
  @Input() countryId;
  @Input() districtId;
  @Input() provinceId;
  @Input() qclubId;
  isAdminAuthorized = true;
  isQclubManagerAuthorized = true;
  data: any[] = [];
  subscription: Subscription;
  breadcrumbArray;
  getStorageValue;
  loggedInUser: User;
  adminRole;
  qclubManagerRole;
  redirectUrl;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private tokenService: TokenService,
    private roleService: RoleService,
    private userService: UserService
  ) {}
  currentRole;
  ngOnInit() {
    this.adminRole = this.roleService.hasSuperAdminRole(); // check role here
    this.qclubManagerRole = this.roleService.hasQClubManagerRole(); // check role here
    this.redirectUrl = this.roleService.areaRedirect(); // get qclub manager redirect url
    this.getStorageValue = JSON.parse(localStorage.getItem('breadcrumb'));
    if (this.getStorageValue) {
      if (this.getStorageValue.length !== 0) {
        this.breadcrumbArray = this.getStorageValue;
      }
    }
  }

  returnZero() {
    return 0;
  }

  removeIndex(index) {
    this.getStorageValue = JSON.parse(localStorage.getItem('breadcrumb'));
    this.getStorageValue.splice(
      index + 1,
      this.getStorageValue.length - (index + 1)
    );
    localStorage.setItem('breadcrumb', JSON.stringify(this.getStorageValue));
    this.breadcrumbArray = this.getStorageValue;
  }
}
