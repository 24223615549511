import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalAlertService {
  constructor() {}

  /**
   * @description warning alert
   */
  warningAlert(config: any) {
    return Swal.fire({
      title: config.title,
      icon: config.icon,
      html: config.html,
      width: '30%',
    });
  }

  /**
   * @description destroy instance or close alert
   */
  closeAlert() {
    Swal.close();
  }

  /**
   * @description choose one of the option by cliscking any of the button or cancel to close
   */
  chooseOptions(config: any) {
    return new Promise<any>((resolve, reject) => {
      Swal.fire({
        title: config.title,
        icon: config.icon,
        html: config.content,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: config.confirmBtnText,
        confirmButtonAriaLabel: 'option1',
        cancelButtonText: config.cancelBtnText,
        cancelButtonAriaLabel: 'option2',
        confirmButtonColor: config.confirmBtnColor,
        cancelButtonColor: config.cancelBtnColor,
        input: config.input,
        inputValue: config.inputValue,
        customClass: {
          container: config.containerClass || '',
        },
        // Method added for Input Changes validation
        onOpen: () => {
          const input = Swal.getInput();
          if (input) {
            input.oninput = (event) => {
              const regex = /^[a-zA-Z0-9\s]*$/;
              // perform validation here
              if (input.value.length >= 16) {
                input.value = input.value.slice(0, -1);
              } else {
                if (regex.test(input.value)) {
                  return true;
                } else {
                  input.value = input.value.slice(0, -1);
                  return false;
                }
              }
            };
          }
        },
      })
        .then((result) => {
          if (result.value) {
            result.value === Boolean
              ? resolve(config.confirmBtnValue)
              : resolve(result);
          } else {
            const dismiss: any = result.dismiss;
            if (dismiss === 'cancel') {
              resolve(config.cancelBtnValue);
            } else {
              resolve(null);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
