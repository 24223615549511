import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ConfirmModalComponent } from '../shared/components/modals/confirm-modal/confirm-modal.component';
// import { SharedModule } from '../shared/shared.module';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { ViewQPonDetailsComponent } from '../q-pons/components/shared/view-qpon-details/view-qpon-details.component';
import { QPonsModule } from '../q-pons/q-pons.module';

import { AddQclubComponent } from './components/clublocation-comp/add-qclub/add-qclub.component';
import { BusinessQPonsListComponent } from './components/clublocation-comp/business-qpon-list/business-qpon-list.component';
import { EditQclubComponent } from './components/clublocation-comp/edit-qclub/edit-qclub.component';
import { AreaDistrictsComponent } from './components/clublocation-comp/list-comp/area-districts/area-districts.component';
import { CountryAreaComponent } from './components/clublocation-comp/list-comp/country-area/country-area.component';
import { ProvinceComponent } from './components/clublocation-comp/list-comp/province/province.component';
import { QClubsComponent } from './components/clublocation-comp/list-comp/q-clubs/q-clubs.component';
import { QclubLocationComponent } from './components/clublocation-comp/list-comp/qclub-location/qclub-location.component';
import { AddAreadistrictComponent } from './components/clublocation-comp/modals/add-areadistrict/add-areadistrict.component';
import { AddCountryAreaComponent } from './components/clublocation-comp/modals/add-country-area/add-country-area.component';
import { AddCountryComponent } from './components/clublocation-comp/modals/add-country/add-country.component';
import { AddProvinceComponent } from './components/clublocation-comp/modals/add-province/add-province.component';
import { ChangeQclubManagerComponent } from './components/clublocation-comp/qclub-detail/change-qclub-manager/change-qclub-manager.component';
import { QclubDetailComponent } from './components/clublocation-comp/qclub-detail/qclub-detail.component';
import { DivisionQmembersComponent } from './components/clublocation-comp/qclub-division/division-qmembers/division-qmembers.component';
import { QBusinessComponent } from './components/clublocation-comp/qclub-division/q-business/q-business.component';
import { QLocationBreadcrumbComponent } from './components/clublocation-comp/shared/qlocation-breadcrumb/qlocation-breadcrumb.component';
import { QlocationSearchfilterComponent } from './components/clublocation-comp/shared/qlocation-searchfilter/qlocation-searchfilter.component';
import { TableDataComponent } from './components/clublocation-comp/shared/table-data/table-data.component';
import { LocationComponent } from './components/location/location.component';
import { LocationRoutingModule } from './location-routing.module';

@NgModule({
  declarations: [
    QclubLocationComponent,
    CountryAreaComponent,
    AddCountryComponent,
    AddProvinceComponent,
    AddAreadistrictComponent,
    ProvinceComponent,
    AreaDistrictsComponent,
    QClubsComponent,
    QclubDetailComponent,
    AddQclubComponent,
    QBusinessComponent,
    DivisionQmembersComponent,
    QlocationSearchfilterComponent,
    AddCountryAreaComponent,
    TableDataComponent,
    LocationComponent,
    ChangeQclubManagerComponent,
    EditQclubComponent,
    QLocationBreadcrumbComponent,
    BusinessQPonsListComponent,
    ViewQPonDetailsComponent,
    // ConfirmModalComponent,
  ],
  imports: [
    CommonModule,
    LocationRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    QPonsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAel1czo9RcjpRvbpXITOyP7apAwmc9uxo',
      libraries: ['places'],
    }),
    NgbModule,
  ],
  exports: [
    ChangeQclubManagerComponent,
    AddCountryComponent,
    AddProvinceComponent,
    AddAreadistrictComponent,
    AddCountryAreaComponent,
    ViewQPonDetailsComponent,
    //  ConfirmModalComponent,
  ],
  bootstrap: [LocationComponent],
})
export class LocationModule {}
