import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'projects/admin/src/app/core/services/api.service';
import { TokenService } from 'projects/admin/src/app/core/services/token.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { ApiEndPoints } from '../constants';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService {
  // Behaviour subject to store the current loggedIn user data
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info

    if (this.tokenService.getToken()) {
      this.isAuthenticatedSubject.next(true);
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuthTokens(user) {
    // Save JWT sent from server in localstorage
    this.tokenService.saveToken(user.id);
    this.tokenService.saveUserId(user.userId);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.tokenService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next(null);
  }

  login(params: any): Observable<any> {
    return this.apiService.post(ApiEndPoints.login, params).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.setAuthTokens(user);
        return user;
      })
    );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * logout current user
   */
  logout() {
    const token = this.tokenService.getToken();
    if (token) {
      // Logout user from server
      this.apiService
        .post(`${ApiEndPoints.signout}?access_token=${token}`)
        .subscribe(
          (result) => {
            this.purgeAuth();
            this.router.navigateByUrl('/login');
          },
          (error) => {}
        );
    }
  }

  unAuthorizedUser() {
    this.purgeAuth();
    this.router.navigate(['/'], { replaceUrl: true });
  }

  unAuthorizedWithoutMessage() {
    this.purgeAuth();
    this.router.navigate(['/']);
    window.location.reload(); // reload the app here
  }

  getAllUsersDetails(): Observable<any> {
    const token = this.tokenService.getToken();
    return this.apiService.get(ApiEndPoints.getAllUser + token).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * @description method to get loggedIn user data every time
   * @param userId loggedIn user Id
   */

  getLoggedInUserDetails(userId: number): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.adminUserDetails}/${userId}`)
      .pipe(
        map((data) => {
          console.log(data);
          return this.setUserDetails(data);
        })
      );
  }

  /**
   * @description set user data to behaviour subject and the local storage
   * @param userData user details
   */
  setUserDetails(userData: User) {
    // set data to behavour subject
    this.currentUserSubject.next(userData);
    // set date to local storage keys
    this.tokenService.saveUser(userData);
    this.tokenService.setUserName(userData.name);
    this.tokenService.setUserType(userData.userType);
    if (userData.userRoles.length > 0){
      this.tokenService.setUserRole(userData.userRoles[0].role.name);
    }
    if (userData.profile != null) {
      this.tokenService.setUserCountry(userData.profile.countryId);
      this.tokenService.setUserUniqueKey(userData.profile.uniqueKey);
    }
    return userData;
  }

  forgotPassword(formData: any): Observable<any> {
    return this.apiService
      .post(ApiEndPoints.adminForgotPassword, formData)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  resetPassword(formData: any, token): Observable<any> {
    this.apiService.setHttpHeaders(token);
    return this.apiService.post(ApiEndPoints.adminResetPassword, formData).pipe(
      map((data) => {
        return data;
      })
    );
  }

  resetHttpHeaders() {
    this.apiService.resetHttpHeaders();
  }
}
