import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiEndPoints, ApiService } from '../../../core';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  apiUrl = environment.apiUrl;
  config = JSON.parse(JSON.stringify(require('../../../config.json')));

  limit: number = this.config.pageLimit;
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getManagerQclubDetails(managerId): Observable<any> {
    return this.apiService
      .get(`${ApiEndPoints.managerQclub}${managerId}/club-details`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
