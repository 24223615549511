import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { ManageReferralCouponsComponent } from './components/manage/manage-referral-coupons/manage-referral-coupons.component';
import { ReferralCouponComponent } from './components/referral-coupon/referral-coupon.component';
import { ReferralFiltersComponent } from './components/shared/referral-filters/referral-filters.component';
import { ReferralCouponsRoutingModule } from './referral-coupons-routing.module';
@NgModule({
  declarations: [
    ReferralCouponComponent,
    ManageReferralCouponsComponent,
    ReferralFiltersComponent,
  ],
  imports: [
    CommonModule,
    ReferralCouponsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
  ],
  exports: [],
})
export class ReferralCouponsModule {}
