import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { RoleService } from 'projects/admin/src/app/core/services/role.service';
import { Observable } from 'rxjs';

@Injectable()
export class BusinessAcquisitionGuard implements CanActivate {
  constructor(private roleService: RoleService) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // returns the boolean based on permission (Business Acquisition Manager)
    const hasBusinessAcquisitionPermission = this.roleService.hasBusinessAcquisitionRole();
    return hasBusinessAcquisitionPermission;
  }
}
