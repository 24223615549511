import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.remove();
    this.toasts.push({ textOrTpl, ...options });
  }

  remove() {
    this.toasts = [];
    // this.toasts = this.toasts.filter(t => t !== toast);
  }

  /**
   * @description show toast on error
   * @param textOrTpl textOrTpl
   * @param options toast options
   */
  showError(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show(textOrTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  /**
   * @description show toast on success
   * @param textOrTpl textOrTpl
   * @param options toast options
   */
  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show(textOrTpl, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  /**
   * @description show toast on while process
   * @param textOrTpl textOrTpl
   * @param options toast options
   */
  showProgress(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show(textOrTpl, {
      classname: 'bg-primary text-light',
      delay: 60000 /** 60 seconds */,
    });
  }
}
