import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'projects/admin/src/app/core/guards/admin.guard';
import { AdminWithClubManagerGuard } from 'projects/admin/src/app/shared/guard/admin-clubmanager.guard';
import { NoPermissionGuard } from 'projects/admin/src/app/shared/guard/no-permission.guard';

import { QponsListComponent } from './components/manage-qpons/qpons-list/qpons-list.component';
import { QponsComponent } from './components/qpons/qpons.component';

const routes: Routes = [
  {
    path: '',
    component: QponsComponent,
    children: [
      {
        path: 'local',
        component: QponsListComponent,
        canActivate: [AdminWithClubManagerGuard, NoPermissionGuard],
      },
      {
        path: 'premium',
        component: QponsListComponent,
        canActivate: [AdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QPonsRoutingModule {}
