import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { AdminUserComponent } from './components/admin-user/admin-user.component';
import { AddAdminuserComponent } from './components/manage/add-adminuser/add-adminuser.component';
import { ManageUsersComponent } from './components/manage/manage-users/manage-users.component';
import { EditUserComponent } from './components/manage/modals/edit-user/edit-user.component';
import { PendingUsersComponent } from './components/manage/pending-users/pending-users.component';
import { UserDetailsComponent } from './components/manage/user-details/user-details.component';
import { UserFiltersComponent } from './components/shared/user-filters/user-filters.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  declarations: [
    AdminUserComponent,
    ManageUsersComponent,
    AddAdminuserComponent,
    PendingUsersComponent,
    UserDetailsComponent,
    EditUserComponent,
    UserFiltersComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
  ],
  exports: [EditUserComponent],
})
export class UsersModule {}
