import { environment } from 'projects/admin/src/environments/environment';
import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import messages from '@core-module/constants/successMessages.json';
import { CommonService } from 'projects/admin/src/app/core/index';
import { ConfirmModalComponent } from 'projects/admin/src/app/shared/components/modals/confirm-modal/confirm-modal.component';
import OTHER_CONST from 'projects/admin/src/app/shared/constants/other.const';
import { ApiService } from 'projects/admin/src/app/shared/services/api.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';
import { SortingService } from 'projects/admin/src/app/shared/services/sorting.service';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';

import { ReferralCouponService } from '../../../services/referral-coupon.service';
@Component({
  selector: 'app-manage-referral-coupons',
  templateUrl: './manage-referral-coupons.component.html',
  styleUrls: ['./manage-referral-coupons.component.css'],
})
export class ManageReferralCouponsComponent implements OnInit {
  imageUrl = environment.imageUrl;
  filterCollapsed = true;
  referralCouponsList = [];
  currentPage = 1;
  disableNext = false;
  totalRecordsCount = 0;
  searchText = '';
  searchCouponsForm: FormGroup;
  sortType = '';
  filters: {} = null;
  sortOrder = OTHER_CONST.sortOrder;
  storySort;
  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private referralCouponService: ReferralCouponService,
    private sorting: SortingService,
    private apiService: ApiService,
    private dialog: DialogService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.getAllReferralCoupons(this.currentPage, this.searchText, this.filters);
    this.searchCouponsForm = this.fb.group({
      searchTerm: [''],
    });
  }

  /**
   * Get All the Referral Coupons
   * @param page current page number
   * @param searchText search text
   */
  getAllReferralCoupons(page, searchText, filters) {
    this.referralCouponService
      .getReferralCouponListing(page, searchText, filters)
      .subscribe(
        (data) => {
          this.setDataResponse(data);
          /* if (data && data.result.length) {
            this.referralCouponsList = data.result;
            this.sortType = '';
            this.sorting.setData(this.referralCouponsList);
            this.commonService.pageArray.next(this.referralCouponsList);
            this.apiService.currentPage.next(this.currentPage);
          } else {
            if (this.currentPage === 0) {
              if (this.searchCouponsForm.value) {
                this.referralCouponsList = [];
              }
            } else {
              this.currentPage = this.currentPage - 1;
              this.apiService.currentPage.next(this.currentPage);
            }
            this.commonService.pageArray.next(this.referralCouponsList);
          }*/
        },
        (error) => {
          this.toast.show(error.error.message, {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      );
  }

  /**
   *  Set data on view
   * @param data data
   */
  setDataResponse(data) {
    if (data.result.length === 0 && this.currentPage > 1) {
      // set disable next manually when there is no records to prevent next page click
      // this is required because the total count are not coming from backend services to pre make pagination
      this.disableNext = true;
      this.currentPage = --this.currentPage; // set previous page again
    } else {
      this.referralCouponsList = data.result;
      this.sortType = '';
      this.sorting.setData(this.referralCouponsList);
      this.disableNext = false;
    }
  }

  /**
   * jump to next and back page
   * @param $event next page number
   */
  clickedPage($event) {
    this.currentPage = $event;
    this.getAllReferralCoupons(
      this.currentPage,
      this.searchCouponsForm.get('searchTerm').value,
      this.filters
    );
  }

  /**
   * Serach Record based on the seach text
   */
  submitForm() {
    this.currentPage = 1;
    this.getAllReferralCoupons(
      this.currentPage,
      this.searchCouponsForm.get('searchTerm').value,
      this.filters
    );
  }

  /**
   * generate Unique Referral Coupons Code
   */
  generateReferralCoupons() {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to Generate Referral Coupons?',
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data.status === true) {
        this.currentPage = 1;
        this.referralCouponService.createReferralCouponCode().subscribe(
          () => {
            this.toast.show(messages.referralCouponAdd, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            this.getAllReferralCoupons(
              this.currentPage,
              this.searchCouponsForm.get('searchTerm').value,
              this.filters
            );
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
      }
    });
  }

  /**
   * Delete Referral Code
   * @param couponId referral Coupons Id
   */
  deleteReferralCoupons(couponId) {
    this.dialog.dataModal(ConfirmModalComponent, {
      message: 'Are you sure you want to delete this Referral Coupons?',
      id: couponId,
    });
    this.dialog.modalReference.componentInstance.status.subscribe((data) => {
      if (data.status === true) {
        this.referralCouponService.deleteReferralCoupons(couponId).subscribe(
          () => {
            this.toast.show(messages.referralCouponDeleted, {
              classname: 'bg-success text-light',
              delay: 5000,
            });
            this.getAllReferralCoupons(
              this.currentPage,
              this.searchCouponsForm.get('searchTerm').value,
              this.filters
            );
          },
          (error) => {
            this.toast.show(error.error.message, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
          }
        );
      }
    });
  }

  /**
   * Filter data based on the conditions
   * @param value filtered value
   */
  filterData(value: any) {
    this.currentPage = 1;
    if (value.status) {
      value.status = value.status;
      this.filters = value;
      this.getAllReferralCoupons(
        this.currentPage,
        this.searchCouponsForm.get('searchTerm').value,
        this.filters
      );
    } else {
      this.filterCollapsed = true;
      value.status = null;
      this.filters = value;
      this.getAllReferralCoupons(
        this.currentPage,
        this.searchText,
        this.filters
      );
    }
  }

  /**
   * @description sort records
   * @param field sort by field
   */

  sortRecords(field) {
    this.storySort =
      this.storySort === this.sortOrder.desc
        ? this.sortOrder.asc
        : this.sortOrder.desc;
    this.referralCouponsList = this.sorting.normalSort(
      this.referralCouponsList,
      field,
      this.storySort
    );
  }
}
