import { environment } from 'projects/admin/src/environments/environment';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'projects/admin/src/app/core/index';
import { ToastService } from 'projects/admin/src/app/shared/services/toast.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';

import { BusinessService } from '../../../../business/services/business.service';
import { QclubService } from '../../../services/qclub.service';

@Component({
  selector: 'app-business-qpon-list',
  templateUrl: './business-qpon-list.component.html',
  styleUrls: ['./business-qpon-list.component.css'],
})
export class BusinessQPonsListComponent implements OnInit {
  qmembers = false;
  imageUrl = environment.imageUrl;
  constructor(
    private location: Location,
    private dialog: DialogService,
    private toast: ToastService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private qclubService: QclubService,
    private businessService: BusinessService,
    private fb: FormBuilder
  ) {}

  userRole;
  userRoleActivate = false;
  qPonListing = [];
  businessDetails;
  searchQponForm: FormGroup;
  businessId;
  currentTab = 'active';
  businessName;
  businessUniqueKey;
  qClubId;

  ngOnInit() {
    this.businessId = parseInt(
      this.route.snapshot.paramMap.get('businessId'),
      10
    );
    this.userRole = this.tokenService.getUserRole();
    this.getBusinessQponsList(this.businessId);
  }

  /**
   * get the business and related qpons list
   * @param businessId business id
   */
  getBusinessQponsList(businessId) {
    this.businessService.getBusinessDetails(businessId).subscribe(
      (data) => {
        if (data && data.result) {
          this.businessDetails = data.result;
          this.businessName = this.businessDetails.businessName;
          this.businessUniqueKey = this.businessDetails.uniqueKey;
          this.qClubId = this.businessDetails.qClubId;
        }
      },
      (error) => {
        this.toast.show(error.error.message, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    );
  }
}
