import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-acquisition',
  templateUrl: './business-acquisition.component.html',
  styleUrls: ['./business-acquisition.component.css'],
})
export class BusinessAcquisitionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
