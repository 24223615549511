import { Injectable } from '@angular/core';
import { Area, Roles, User } from 'projects/admin/src/app/core/models';
import { UserService } from 'projects/admin/src/app/core/services/user.service';
import { url } from 'inspector';

import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  userData: User;
  redirectUrl;
  constructor(
    private tokenService: TokenService,
    private userService: UserService
  ) {
    // subscribe for the current user data
    this.userService.currentUser.subscribe((data) => {
      this.userData = data;
    });
  }

  /**
   * @description returns self created user's basic role
   */
  getUserRole() {
    const user = this.userData;
    if (user && user.userRoles) {
      if (this.hasSuperAdminRole()) {
        return Roles.SUPER_USER;
      }

      if (this.hasAdminRole()) {
        return Roles.ADMIN;
      }

      if (this.hasQClubManagerRole()) {
        return Roles.Q_CLUB_MANAGER;
      }

      if (this.hasBusinessAcquisitionRole()) {
        return Roles.BUSINESS_ACQUISITION;
      }
    }
    return null;
  }

  /**
   * @description return user type like: ADMIN/BUSINESS/MEMBER
   */
  getUserType() {
    return this.userData.userType;
  }

  /**
   * @description  method return the boolen for the superadmin role
   */
  hasSuperAdminRole(): boolean {
    const user = this.userData;
    if (user && user.userRoles) {
      const userRole = user.userRoles.find(
        (x) => x.role.name === Roles.ADMIN || x.role.name === Roles.SUPER_USER
      );
      if (userRole) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description check for admin role and this method returning boolean
   */
  hasAdminRole() {
    const user = this.userData;
    if (user && user.userRoles) {
      const userRole = user.userRoles.find((x) => x.role.name === Roles.ADMIN);
      if (userRole) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description method returning boolean for the loggedin user has qlucb manager permission
   */
  hasQClubManagerRole() {
    const user = this.userData;
    if (user && user.userRoles) {
      const userRole = user.userRoles.find(
        (x) => x.role.name === Roles.Q_CLUB_MANAGER
      );
      if (userRole) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description check for Business Acquisition Role and this method returning boolean
   */
  hasBusinessAcquisitionRole() {
    // const user = this.tokenService.getUser();
    const user = this.userData;
    if (user && user.userRoles) {
      const userRole = user.userRoles.find(
        (x) => x.role.name === Roles.BUSINESS_ACQUISITION
      );
      if (userRole) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description check for user Role and this method returning user role list tored in subject
   *
   */
  getActualRoles() {
    const user = this.userData;
    if (user && user.userRoles) {
      return user.userRoles;
    }
    return null;
  }

  /**
   * @description Create and return qclub manager url based on the Permission he has
   * we assuing that one qclub manager has only one permission
   */
  areaRedirect() {
    if (this.userData.userPermissions.length) {
      const areaPermission = this.userData.userPermissions[0];
      if (areaPermission.area === Area.COUNTRY) {
        this.redirectUrl = '/admin/locations/';
      } else if (areaPermission.area === Area.COUNTRY_AREA) {
        this.redirectUrl =
          '/admin/locations/' +
          areaPermission.areaAssigned.countryId +
          '/areas';
      } else if (areaPermission.area === Area.PROVINCE) {
        this.redirectUrl =
          '/admin/locations/' +
          areaPermission.areaAssigned.countryId +
          '/areas/' +
          areaPermission.areaAssigned.countryAreaId +
          '/provinces';
      } else if (areaPermission.area === Area.DISTRICT) {
        this.redirectUrl =
          '/admin/locations/' +
          areaPermission.areaAssigned.countryId +
          '/areas/' +
          areaPermission.areaAssigned.countryAreaId +
          '/provinces/' +
          areaPermission.areaAssigned.provinceId +
          '/area-districts';
      } else if (areaPermission.area === Area.Q_CLUB) {
        this.redirectUrl =
          '/admin/locations/' +
          areaPermission.areaAssigned.countryId +
          '/areas/' +
          areaPermission.areaAssigned.countryAreaId +
          '/provinces/' +
          areaPermission.areaAssigned.provinceId +
          '/area-districts/' +
          areaPermission.areaAssigned.districtId +
          '/q-clubs';
      } else {
        this.redirectUrl = '/admin/manager/manager-details;';
      }
      return this.redirectUrl;
    } else {
      this.redirectUrl = '/admin/no-permission';
      return this.redirectUrl;
    }
  }

  /**
   * @description return permission of current  user
   */
  getAreaPermission() {
    return this.userData.userPermissions;
  }
}
