import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionComponent } from 'projects/admin/src/app/shared/components/question/question.component';
import { AdminWithClubManagerGuard } from 'projects/admin/src/app/shared/guard/admin-clubmanager.guard';
import { QclubsResolver } from 'projects/admin/src/app/shared/resolvers/qclubs.resolver';

import { MemberComponent } from './components/member/member.component';
import { QMembersComponent } from './components/q-members/q-members.component';
import { QmemberDetailComponent } from './components/qmember-detail/qmember-detail.component';

const routes: Routes = [
  {
    path: '',
    component: MemberComponent,
    children: [
      {
        path: '',
        redirectTo: 'member-questions',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: QMembersComponent,
        canActivate: [AdminWithClubManagerGuard],
        resolve: { qclubsData: QclubsResolver },
      },
      {
        path: 'detail/:id',
        component: QmemberDetailComponent,
        canActivate: [AdminWithClubManagerGuard],
      },
      {
        path: 'member-questions',
        component: QuestionComponent,
        canActivate: [AdminWithClubManagerGuard],
        data: { data: 'MEMBER' },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemberRoutingModule {}

export const memberComponents = [
  QMembersComponent,
  MemberComponent,
  QmemberDetailComponent,
];
