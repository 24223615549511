import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogComponentRef: ComponentRef<any>;
  closeResult: string;
  modalReference: any;
  options: {};

  constructor(private modalService: NgbModal) {}

  // calling when data needed to be sent
  dataModal(component, data, centered?) {
    const windowClass = 'fade addLocationFieldModal';

    this.options = {
      backdrop: true,
      keyboard: false,
      windowClass,
      centered
    };
    this.modalReference = this.modalService.open(component, this.options);
    this.modalReference.componentInstance.data = data;
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  // called when need to open simple modal with no data transfer
  open(component) {
    const windowClass = 'fade addLocationFieldModal';

    this.options = {
      backdrop: true,
      keyboard: false,
      windowClass,
    };

    this.modalReference = this.modalService.open(component, this.options);

    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      console.log('ESC');
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  close() {
    this.modalReference.close();
  }
}
