import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'projects/admin/src/app/shared/shared.module';

import { memberComponents, MemberRoutingModule } from './member.routing.module';

@NgModule({
  declarations: [memberComponents],
  imports: [
    CommonModule,
    MemberRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCDhjF3LNn2qqYUivCkiyYD8lQMAzihz7I',
    }),
  ],
  exports: [],
})
export class MemberModule {}
