import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessService } from 'projects/admin/src/app/shared/services/business.service';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';

import { LocationService } from '../../../../services/location.service';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.css'],
})
export class AddCountryComponent implements OnInit {
  @Output() country: EventEmitter<any> = new EventEmitter();
  @Input() data;
  countryId: number;
  submitValue = 'Add';

  addCountryForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private business: BusinessService,
    private location: LocationService
  ) {}
  ngOnInit() {
    this.addCountryForm = this.fb.group({
      name: ['', Validators.required],
      currency: ['', [Validators.required]],
      shortName: [''],
    });
    if (this.data.isEdit) {
      this.getCountryDetails(this.data.id);
      this.submitValue = 'Edit';
    }
  }

  // get details to edit
  getCountryDetails(id) {
    this.location.getCountryDetail(id).subscribe((data) => {
      this.fillForm(data);
    });
  }

  // fillForm details
  fillForm(data) {
    this.addCountryForm.get('name').setValue(data.name);
    this.addCountryForm.get('currency').setValue(data.currency);
  }
  submitForm() {
    for (const controller in this.addCountryForm.controls) {
      if (this.addCountryForm.controls.hasOwnProperty(controller)) {
        this.addCountryForm.get(controller).markAsTouched();
      }
    }

    if (this.addCountryForm.valid) {
      this.addCountryForm
        .get('shortName')
        .setValue(this.addCountryForm.get('name').value.substring(0, 2));
      if (this.data.isEdit) {
        this.location
          .editCountry(this.data.id, this.addCountryForm.value)
          .subscribe((data) => {
            this.closeModal();
            this.country.emit({
              name: this.addCountryForm.get('name').value,
              isEdit: true,
              error: false,
            });
          });
      } else {
        this.location
          .addCountry(this.addCountryForm.value)
          .subscribe((data) => {
            this.closeModal();
            this.country.emit({
              name: this.addCountryForm.get('name').value,
              isEdit: false,
              error: false,
            });
          });
      }
    }
  }

  closeModal() {
    this.dialog.close();
  }

  get f() {
    return this.addCountryForm.controls;
  }

  get invalidCurrency() {
    return (
      this.addCountryForm.get('currency').touched &&
      this.addCountryForm.get('currency').errors &&
      this.addCountryForm.get('currency').errors.required
    );
  }
}
