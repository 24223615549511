import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.css'],
})
export class TableDataComponent implements OnInit {
  @Input() datas;
  @Output() entryClicked = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  goTo(id) {
    this.entryClicked.emit(id);
  }
}
