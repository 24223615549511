import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-footer-save-button',
  templateUrl: './footer-save-button.component.html',
  styleUrls: ['./footer-save-button.component.css'],
})
export class FooterSaveButtonComponent implements OnInit, OnChanges {
  @Input() isFormValid = true;
  @Input() isNFCEditing;
  @Output() emitActionToForm = new EventEmitter();
  constructor() {}
  public isEditing: any = 0;

  ngOnChanges(change: SimpleChanges) {
    this.isFormValid = change.isFormValid.currentValue;
    typeof this.isNFCEditing === 'string'
      ? (this.isEditing = this.isNFCEditing)
      : (this.isEditing = 0);
  }

  ngOnInit() {}

  /**
   * @description action for the cancel and submit
   * @param actionType type
   */
  emitAction(actionType) {
    this.emitActionToForm.emit(actionType);
  }
}
