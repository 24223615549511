import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'projects/admin/src/app/shared/services/dialog.service';

import { LocationService } from '../../../../services/location.service';

@Component({
  selector: 'app-add-country-area',
  templateUrl: './add-country-area.component.html',
  styleUrls: ['./add-country-area.component.css'],
})
export class AddCountryAreaComponent implements OnInit {
  @Input() data;
  addCountryAreaForm: FormGroup;
  countryId;
  submitValue = 'Add';
  @Output() countryarea: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private dialog: DialogService,
    private location: LocationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.countryId = this.data.id;
    this.addCountryAreaForm = this.fb.group({
      name: ['', Validators.required],
    });
    if (this.data.isEdit) {
      this.getAreaDetails(this.data);
      this.submitValue = 'Edit';
    }
  }

  // get area details to edit
  getAreaDetails(data) {
    this.location
      .getCountryAreaDetail(data.id, data.areaId)
      .subscribe((resultData) => {
        this.addCountryAreaForm.get('name').setValue(resultData.name);
      });
  }

  addCountryArea() {
    for (const controller in this.addCountryAreaForm.controls) {
      if (this.addCountryAreaForm.controls.hasOwnProperty(controller)) {
        this.addCountryAreaForm.get(controller).markAsTouched();
      }
    }

    if (this.addCountryAreaForm.valid) {
      if (this.data.isEdit) {
        this.location
          .editCountryArea(
            this.countryId,
            this.data.areaId,
            this.addCountryAreaForm.value
          )
          .subscribe(
            (data) => {
              this.closeModal();
              this.countryarea.emit({
                name: this.addCountryAreaForm.get('name').value,
                isEdit: true,
                error: false,
              });
            },
            (error) => {
              this.countryarea.emit({
                error: true,
                message: error.error.error.message,
              });
            }
          );
      } else {
        this.location
          .addCountryArea(this.countryId, this.addCountryAreaForm.value)
          .subscribe(
            (data) => {
              this.closeModal();
              this.countryarea.emit({
                name: this.addCountryAreaForm.get('name').value,
                isEdit: false,
                error: false,
              });
            },
            (error) => {
              this.countryarea.emit({
                error: true,
                message: error.error.error.message,
              });
            }
          );
      }
    }
  }

  closeModal() {
    this.dialog.close();
  }
  get f() {
    return this.addCountryAreaForm.controls;
  }
}
